import { createStore } from 'vuex'

export default createStore({
  state: {
    username:'',
    token:'',
    current:'首页',
   
  },
  mutations: {
    setTOKEN(state,payload){
      this.state.token = payload
    },
    setname(state,payload){
      // console.log(state,payload)
      this.state.username = payload
    },
    // setcurrent(state,payload){
    //   // console.log(state,payload)
    //   this.state.current = payload
    // },
    setkeyword(state,payload){
      // console.log(state,payload)
      this.state.keyword = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
