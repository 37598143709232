<template>
  <div class="center_left_echarts">
    <div class="center_left_title">
      <span>专利类型占比图</span>
      <div class="center_left_title_btn">
        <span
          :class="[type1 == 'apply_num' ? 'active' : '']"
          @click="changeType('apply_num')"
          >申请量</span
        >
        <span
          :class="[type1 == 'publish_num' ? 'active' : '']"
          @click="changeType('publish_num')"
          >公布量</span
        >
        <span
          :class="[type1 == 'authorize_num' ? 'active' : '']"
          @click="changeType('authorize_num')"
          >授权量</span
        >
        <a-select
          ref="select"
          v-model:value="yearValue"
          @change="handleChange"
          :options="yearOptions"
        >
        </a-select>
      </div>
    </div>
    <div class="echarts_container">
      <EchartsCon :id="'123'" :option="option1" v-if="option1"></EchartsCon>
    </div>
  </div>
  <div class="center_left_echarts">
    <div class="center_left_title">
      <span>专利授权对比图</span>
      <div class="center_left_title_btn">
        <span :class="[type2 == 1 ? 'active' : '']" @click="changeAuth(1)"
          >专利总量</span
        >
        <span :class="[type2 == 2 ? 'active' : '']" @click="changeAuth(2)"
          >发明专利</span
        >
        <span :class="[type2 == 3 ? 'active' : '']" @click="changeAuth(3)"
          >实用新型</span
        >
        <span :class="[type2 == 4 ? 'active' : '']" @click="changeAuth(4)"
          >外观设计</span
        >
        <a-select
          ref="select"
          v-model:value="yearValue2"
          @change="changeYear"
          :options="options1"
        >
        </a-select>
        <a-select
          ref="select"
          v-model:value="yearValue1"
          @change="changeYear"
          :options="options1"
        >
        </a-select>
      </div>
    </div>
    <div class="echarts_container">
      <EchartsCon :id="'456'" :option="option2" v-if="option2"></EchartsCon>
    </div>
  </div>
</template>
<script>
import EchartsCon from "@/components/EchartsCon.vue";
import { patentType, patentAuthorized } from "@/utils/screen.js";
import { message } from "ant-design-vue";

export default {
  name: "patentType",
  data() {
    return {
      yearOptions: [
        {
          value: 1,
          label: "今年",
        },
        {
          value: 2,
          label: "近两年",
        },
        {
          value: 3,
          label: "近三年",
        },
        {
          value: 4,
          label: "近四年",
        },
        {
          value: 5,
          label: "近五年",
        },
        {
          value: 6,
          label: "近六年",
        },
        {
          value: 7,
          label: "近七年",
        },
        {
          value: 8,
          label: "近八年",
        },
        {
          value: 9,
          label: "近九年",
        },
        {
          value: 10,
          label: "近十年",
        },
      ],
      yearValue: 3,
      option1: null,
      option2: null,
      type1: "apply_num",
      type2: 1,
      options1: [],
      yearValue1: null,
      yearValue2: null,
    };
  },
  components: {
    EchartsCon,
  },
  methods: {
    init() {
      //近十年年份数据
      let date = new Date();
      let years = Array.from(
        { length: 10 },
        (v, i) => date.getFullYear() - 10 + i + 1
      ).reverse();
      years.forEach((item) => {
        this.options1.push({
          label: item,
          value: item,
        });
      });
      this.getTypeData();
      this.getCompareData();
    },
    //获取专利类型数据
    getTypeData() {
      let data = [];
      patentType({ type: this.type1, year: this.yearValue }).then((res) => {
        if (res.code == 0 && res.data.length > 0) {
          res.data.forEach((item) => {
            data.push({
              name: item.name,
              value: item.num,
            });
          });
          this.getOption(data);
        } else if (res.code == 0 && res.data.length == 0) {
          data = [];
          this.getOption(data);
        } else {
          message.error(res.msg);
        }
      });
    },
    //修改时间
    handleChange() {
      this.getTypeData();
    },
    //修改专利授权时间
    changeYear() {
      let xdata = [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        ydata1 = [],
        ydata2 = [];
      if (this.yearValue1 > this.yearValue2) {
        [this.yearValue1, this.yearValue2] = [this.yearValue2, this.yearValue1];
        patentAuthorized({
          start_year: this.yearValue1,
          end_year: this.yearValue2,
          type: this.type2,
        }).then((res) => {
          res.data.start_year.forEach((item) => {
            ydata1.push(item.value);
          });
          res.data.end_year.forEach((item) => {
            ydata2.push(item.value);
          });
          this.getCompareOption(xdata, ydata1, ydata2);
        });
      } else if (this.yearValue1 == this.yearValue2) {
        patentAuthorized({
          start_year: this.yearValue1,
          end_year: this.yearValue2,
          type: this.type2,
        }).then((res) => {
          res.data.start_year.forEach((item) => {
            ydata1.push(item.value);
          });
          this.getCompareOption(xdata, ydata1, []);
        });
      } else {
        patentAuthorized({
          start_year: this.yearValue1,
          end_year: this.yearValue2,
          type: this.type2,
        }).then((res) => {
          res.data.start_year.forEach((item) => {
            ydata1.push(item.value);
          });
          res.data.end_year.forEach((item) => {
            ydata2.push(item.value);
          });
          this.getCompareOption(xdata, ydata1, ydata2);
        });
      }
    },
    //获取专利授权对比
    getCompareData() {
      let xdata = [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        ydata1 = [],
        ydata2 = [];
      this.yearValue1 = this.options1[1].value;
      this.yearValue2 = this.options1[0].value;
      patentAuthorized({
        start_year: this.yearValue1,
        end_year: this.yearValue2,
        type: this.type2,
      }).then((res) => {
        res.data.start_year.forEach((item) => {
          ydata1.push(item.value);
        });
        res.data.end_year.forEach((item) => {
          ydata2.push(item.value);
        });
        this.getCompareOption(xdata, ydata1, ydata2);
      });
    },
    getOption(data) {
      const colorList = ["#1E78FF", "#E94C5B", "#2EC59A"];
      this.option1 = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "center",
          left: "10%",
          orient: "vertical",
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 8,

          textStyle: {
            color: "#fff",
            fontFamily: "jiangcheng",
          },
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["30%", "70%"],
            label: {
              show: true,
              position: "inside",
              color: "#fff",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              normal: {
                color: (params) => {
                  return colorList[params.dataIndex];
                },
              },
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 10,
            },
            // silent: true,
            data: data,
          },
        ],
      };
    },
    //专利授权对比图option
    getCompareOption(xdata, ydata1, ydata2) {
      this.option2 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            textStyle: {
              color: "#fff",
            },
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(45, 67, 119, 0.8)", //设置背景颜色
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "1%",
          top: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            color: "rgba(255, 255, 255, .8)",
            interval: 0,
            rotate: 30,
            margin: 15,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(45, 67, 119, 0.8)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            color: "#B5C5D4",
            fontSize: 14,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(45, 67, 119, 0.8)",
            },
          },
          min: 0,
          axisLabel: {
            show: true,
            color: "#B5C5D4",
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              // width: 0.5,
              color: "rgba(45, 67, 119, 1)",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: ydata1,
            name: this.yearValue1,
            type: "bar",
            barWidth: "8px",
            itemStyle: {
              normal: {
                color: "rgba(255, 100, 102, 1)",
                borderRadius: [5, 5, 0, 0],
              },
            },
            label: {
              show: false,
              position: "top",
              fontSize: 12,
              color: "#F5F5F5",
              offset: [0, -5],
              formatter: "{c}",
            },
          },
          {
            data: ydata2,
            name: this.yearValue2,
            type: "bar",
            barWidth: "8px",
            itemStyle: {
              normal: {
                color: "rgba(68, 174, 248, 1)",
                borderRadius: [5, 5, 0, 0],
              },
            },
            label: {
              show: false,
              position: "top",
              fontSize: 12,
              color: "#F5F5F5",
              offset: [0, -5],
              formatter: "{c}",
            },
          },
        ],
      };
    },
    //切换专利类型
    changeType(type) {
      this.type1 = type;
      this.getTypeData();
    },
    //切换专利授权类型
    changeAuth(type) {
      this.type2 = type;
      this.changeYear();
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    // this.getOption();
    // this.getCompareOption()
  },
};
</script>
<style lang="less" scoped>
.center_left_echarts {
  padding: 0 15px;
  height: 45%;
  margin-bottom: 25px;
  .center_left_title {
    background: url("../../../static/screen/title-short.png") no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    height: 39px;
    width: 100%;
    font-family: "jiangcheng";
    font-weight: normal;
    font-style: italic;
    color: #f6f9fe;
    text-shadow: 0px 2px 8px rgba(5, 28, 55, 0.42);
    padding-left: 35px;
    padding-top: 6px;
    .center_left_title_btn {
      font-size: 14px;
      font-family: "jiangcheng";
      font-weight: normal;
      color: #bbcdef;
      opacity: 0.8;
      span {
        padding: 5px 8px 5px 0;
        cursor: pointer;
      }
      .active {
        color: #fff;
        text-shadow: 0px 2px 8px #f6f9fe;
      }
      :deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
        background-color: #2d58b8;
        color: #f6f9fe;
        font-family: Arial, sans-serif;
      }
      :deep(.ant-select-dropdown) {
        background-color: #1052df !important;
      }
      :deep(.ant-select) {
        width: 90px !important;
        float: right;
      }
      :deep(
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item
        ) {
        padding-right: 10px;
      }
    }
  }
  .echarts_container {
    // height: 120px;
    margin-top: 40px;
    height: 70%;
  }
}
</style>
