<template>
  <div class="personal_con">
    <!-- 面包屑导航 -->
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item>用户设置</a-breadcrumb-item>
      <a-breadcrumb-item>安全设置</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- 安全设置-->
    <div class="personal_set">
      <h2 class="title">安全设置</h2>
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 5 }"
        autocomplete="off"
        ref="form"
        :rules="formRules"
      >
        <a-form-item label="手机号" name="phone">
          <a-input v-model:value="formState.phone" placeholder="请输入手机号" />
        </a-form-item>
        <a-form-item label="验证码" name="code">
          <a-input v-model:value="formState.code" placeholder="请输入验证码">
            <template #suffix>
              <span v-show="!isSend" class="code" @click="sendCode"
                >获取验证码</span
              >
              <span v-show="isSend" class="code">
                {{ RemainingTime }}
                秒后重新获取
              </span>
            </template>
          </a-input>
        </a-form-item>

        <a-form-item label="新密码" name="newpwd">
          <a-input-password
            v-model:value="formState.newpwd"
            placeholder="请输入新密码"
          />
        </a-form-item>
        <a-form-item label="确认密码" name="renewpwd">
          <a-input-password
            v-model:value="formState.renewpwd"
            placeholder="请再次输入新密码"
          />
        </a-form-item>
        <div class="save_btn">
          <a-button type="primary" html-type="submit" @click="reset">
            保存
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { AES_Encrypt, AES_Decrypt } from "@/utils/aes_util.js";
import { message } from "ant-design-vue";
import { changePassword, sendSms } from "@/utils/loginAPI";
export default {
  name: "securitySetting",
  data() {
    return {
      isSend: "",
      RemainingTime: 0,
      formState: {
        phone: "",
        code: "",
        newpwd: "",
        renewpwd: "",
      },
      formRules: {
        phone: [
          { required: true, message: "请输入手机号！" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机号格式不正确！",
            validateTrigger: "onBlur",
          },
        ],
        renewpwd: [
          { required: true, message: "请输入密码！" },
          {
            validator: this.validateConfirmPassword,
            message: "两次密码不匹配",
          },
        ],
        code: [{ required: true, message: "请输入验证码！" }],
        newpwd: [{ required: true, message: "请输入密码！" }],
      },
    };
  },
  methods: {
    //验证两次密码
    validateConfirmPassword(rule, value) {
      return new Promise((resolve, reject) => {
        if (value && value !== this.formState.newpwd) { 
          reject(new Error());
        } else {
          resolve();
        }
      });
    },
    //发送验证码
    sendCode() {
      if (this.formState.phone) {
        sendSms({ phone: this.formState.phone }).then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.isSend = true;
            const TIME_COUNT = 60;
            message.success(res.msg);
            //获取验证码倒计时
            if (!this.timer) {
              this.RemainingTime = TIME_COUNT;
              this.isSend = true;
              this.timer = setInterval(() => {
                if (
                  this.RemainingTime > 0 &&
                  this.RemainingTime <= TIME_COUNT
                ) {
                  this.RemainingTime--;
                } else {
                  this.isSend = false;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            message.error(res.msg);
          }
        });
      } else {
        message.error("请先输入手机号");
      }
    },

    //reset
    reset() {
      this.$refs.form
        .validate()
        .then((values) => {
          let param = AES_Encrypt(
            JSON.stringify({
              phone: this.formState.phone,
              code: this.formState.code,
              password: this.formState.newpwd,
              re_password: this.formState.renewpwd,
            })
          );
          changePassword({ param }).then((res) => {
            // console.log(res)
            if (res.code == 0) {
              message.success(res.msg)
              // this.resetShow = true;
            } else {
              message.error(res.msg);
            }
          });
        })
        .catch((err) => {
          // console.log(err);
          return false;
          // 处理验证失败的情况
        });
    },
  },
};
</script>

<style lang="less" scoped>
.personal_con {
  background-color: #ebebeb;
  padding-left: 30px;
  width: calc(100% - 80px);
  min-height: 800px;
  flex: 1;
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .personal_set {
    width: 100%;
    height: calc(100% - 80px);
    background-color: #fff;
    padding: 40px 40px;
    .title {
      color: #1c1c28;
      font-size: 18px;
      font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
      font-weight: bold;
      margin-left: 4%;
      line-height: 28px;
      margin-bottom: 2%;
    }
    .code {
      color: #3e7bfa;
      cursor: pointer;
    }
  }
  .save_btn {
    padding-left: 150px;
    padding-top: 20px;
  }
}
</style>
