<template>
  <div class="center_top_imgs">
    <div class="top_img1 text">
      <div>专利数量</div>
      <div>
        <span>{{ patent_num }}</span
        >项
      </div>
    </div>
    <div class="top_img2 text">
      <div>发明数量</div>
      <div>
        <span>{{ inventor_num }}</span
        >项
      </div>
    </div>
    <div class="top_img3 text">
      <div>专利转让量</div>
      <div>
        <span>{{ transfer_num }}</span
        >项
      </div>
    </div>
    <div class="top_img4 text">
      <div>合作申请量</div>
      <div><span>{{ application_num }}</span>项</div>
    </div>
  </div>
  <div class="map_title_box">
    <div class="map_title">
      <img src="@/static/screen/bg-zlzhldqyt.png" alt="" />
      <span class="text_title">
        <span :class="[maptab == 1 ? 'active' : '']" @click="changeMap(1)">
          专利转化落地区域图</span
        >
        <!-- &nbsp; &nbsp; &nbsp;| &nbsp; &nbsp; &nbsp;
        <span :class="[maptab == 2 ? 'active' : '']" @click="changeMap(2)">
          项目合作方区域图
        </span> -->
      </span>
    </div>
    <!-- <div class="map_title">
    
      <span class="text_title"> 项目合作方区域图 </span>
    </div> -->
  </div>

  <div class="echarts_container">
    <EchartsCon :id="'area'" :option="option1" v-if="option1"></EchartsCon>
  </div>
</template>
<script>
import * as echarts from "echarts";
import EchartsCon from "@/components/EchartsCon.vue";
import { patentTransform, patentHead, projectPartner } from "@/utils/screen.js";
import axios from "axios";
export default {
  name: "patentMap",
  data() {
    return {
      option1: null,
      inventor_num: null,
      patent_num: null,
      transfer_num: null,
      application_num:null,
      maptab: 1,
    };
  },
  components: {
    EchartsCon,
  },
  methods: {
    init() {
      this.getMap();
      this.getTextData();
    },
    getMap() {
      let dataNum = [];
      let dataPosition = [];
      let dataScatter = [];
      patentTransform().then((res) => {
        res.data.forEach((item) => {
          dataNum.push({
            name: item.name,
            value: item.value,
          });
          if (item.name != "太原市") {
            dataPosition.push([
              [112.531079, 37.863924],
              [item.longitube, item.latitebe],
            ]);
          }
          dataScatter.push({
            name: item.name,
            value: [item.longitube, item.latitebe],
          });
        });

        this.getMapOption(dataNum, dataPosition, dataScatter);
      });
    },
    //获取文字数据
    getTextData() {
      patentHead().then((res) => {
        if (res.code == 0) {
          this.inventor_num = res.data.inventor_num;
          this.patent_num = res.data.patent_num;
          this.application_num = res.data.application_num;
          this.transfer_num = res.data.transfer_num;
        }
      });
    },
    //获取地图
    getMapOption(dataNum, dataPosition, dataScatter) {
      axios.get("/map_json/shanxi.json").then((res) => {
        echarts.registerMap("shanxi", res.data);

        this.option1 = {
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              if (params.seriesType == "map") {
                return params.data.name + ":" + params.data.value;
              }
            },
          },
          geo: [
            {
              map: "shanxi",
              aspectScale: 1,
              zoom: 0.65,
              layoutCenter: ["50%", "50%"],
              layoutSize: "150%",
              show: true,
              roam: false,
              label: {
                emphasis: {
                  show: false,
                },
              },

              itemStyle: {
                normal: {
                  borderWidth: 1,
                  borderColor: "#3B5077",
                  areaColor: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.8,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                      },
                    ],
                  },
                },
                emphasis: {
                  areaColor: "#8cd3ef",
                  borderWidth: 0,
                },
              },
              emphasis: {
                //地图高亮
                itemStyle: {
                  color: "#93EBF8",
                },
              },
            },
          ],
          series: [
            {
              // name: "山西",
              type: "map",
              map: "shanxi", // 自定义扩展图表类型
              aspectScale: 1,
              zoom: 0.65, // 缩放
              showLegendSymbol: true,
              // silent: true,
              select: {
                disabled: true,
              },
              label: {
                normal: {
                  show: true,
                  textStyle: { color: "#fff", fontSize: "120%" },
                },
              },

              itemStyle: {
                normal: {
                  areaColor: "rgba(20,60,100,0.5)",

                  borderColor: "#fff",
                  borderWidth: 0.2,
                },
                emphasis: {
                  areaColor: "#8cd3ef",
                  color: "#fff",
                },
              },

              layoutCenter: ["50%", "50%"],
              layoutSize: "150%",
              markPoint: {
                symbol: "none",
              },
              data: dataNum,
            },
            {
              type: "lines",
              zlevel: 2,
              label: {
                show: false,
              },
              effect: {
                show: true,
                period: 4, //箭头指向速度，值越小速度越快
                trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: "arrow", //箭头图标
                symbolSize: 6, //图标大小
              },
              lineStyle: {
                normal: {
                  color: "#FBDEDE",
                  width: 2, //尾迹线条宽度
                  opacity: 1, //尾迹线条透明度
                  curveness: 0.3, //尾迹线条曲直度
                  shadowColor: "rgba(255, 200, 113, 0.2)",
                  shadowBlur: 10,
                },
              },
              data: dataPosition,
            },
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              symbolSize: 10,
              rippleEffect: {
                // 坐标点动画
                period: 3,
                scale: 5,
                brushType: "fill",
              },
              label: {
                normal: {
                  show: true,
                  position: "right",
                  formatter: "{b}",
                  color: "#f28813",
                  fontWeight: "bold",
                  fontSize: 14,
                },
              },
              data: dataScatter,
              symbolSize: function (val) {
                return 8;
              },
              showEffectOn: "render",
              rippleEffect: {
                brushType: "stroke",
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: "{b}",
                  position: "left",
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  color: "#FBDEDE",
                  shadowBlur: 15,
                  shadowColor: "#FBDEDE",
                },
              },
              zlevel: 1,
            },
          ],
        };
      });
    },
    //获取项目和作地图
    getProjectMap(dataNum) {
      axios.get("/map_json/shanxi.json").then((res) => {
        echarts.registerMap("shanxi", res.data);

        this.option1 = {
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              if (params.seriesType == "map") {
                return params.data.name + ":" + params.data.value;
              }
            },
          },
          visualMap: {
            show: false,
            left: "left",
            top: "bottom",
            text: ["高", "低"],
            calculable: true,
            inRange: {
              color: ["rgba(20,60,100,0.5)", "rgba(20,60,150,0.5)", "#2961AD"],
              // ['绿色', '#黄色', '#粉色', '#肉色', '#紫色']
            },
          },
          geo: [
            {
              map: "shanxi",
              aspectScale: 1,
              zoom: 0.65,
              layoutCenter: ["50%", "50%"],
              layoutSize: "150%",
              show: true,
              roam: false,
              label: {
                emphasis: {
                  show: false,
                },
              },

              itemStyle: {
                normal: {
                  borderWidth: 1,
                  borderColor: "#3B5077",
                  areaColor: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.8,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                      },
                    ],
                  },
                },
                emphasis: {
                  areaColor: "#8cd3ef",
                  borderWidth: 0,
                },
              },
              emphasis: {
                //地图高亮
                itemStyle: {
                  color: "#93EBF8",
                },
              },
            },
          ],
          series: [
            {
              name: "山西省数据",
              type: "map",
              map: "shanxi", // 自定义扩展图表类型
              aspectScale: 1,
              zoom: 0.65, // 缩放
              showLegendSymbol: true,
              label: {
                normal: {
                  show: true,
                  textStyle: { color: "#fff", fontSize: "120%" },
                },
              },
              select: {
                disabled: true,
              },
              itemStyle: {
                normal: {
                  areaColor: "rgba(20,60,100,0.5)",

                  borderColor: "#fff",
                  borderWidth: 0.2,
                },
                emphasis: {
                  areaColor: "#8cd3ef",
                  color: "#fff",
                },
              },

              layoutCenter: ["50%", "50%"],
              layoutSize: "150%",
              markPoint: {
                symbol: "none",
              },
              data: dataNum,
            },
          ],
        };
      });
    },
    getProjectData() {
      let dataNum = [];

      projectPartner().then((res) => {
        res.data.forEach((item) => {
          dataNum.push({
            name: item.name,
            value: item.value,
          });
        });

        this.getProjectMap(dataNum);
      });
    },
    //切换地图
    changeMap(tab) {
      this.maptab = tab;
      if (tab == 1) {
        this.getMap();
      } else {
        this.getProjectData();
      }
    },
    //获取项目合作图
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// .map_title_box {
//   display: flex;
// }
.map_title {
  position: relative;
  // display: inline-block;
  // width: 300px;

  // color: #fff;
  .text_title {
    position: absolute;
    left: 60px;
    top: 5px;
    cursor: pointer;
    font-family: "jiangcheng";
    font-weight: normal;
    font-size: 20px;
    color: #bbb;
    text-shadow: 0px 2px 8px rgba(5, 28, 55, 0.42);

    background: linear-gradient(
      0deg,
      rgba(14, 197, 236, 0.9) 0%,
      rgba(49, 190, 255, 0.5) 0%,
      rgba(239, 252, 254, 0.06) 58.7646484375%
    );
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .active {
    color: #fff;
  }
}
.echarts_container {
  height: 70%;
  // margin-top: 20px;
}
.center_top_imgs {
  height: 120px;
  width: 100%;
  display: flex;
  margin-top: -20px;

  .text {
    padding-left: 76px;
    padding-top: 35px;
    div {
      font-size: 18px;
      font-family: "jiangcheng";
      font-weight: normal;
      font-style: italic;
      color: #ffffff;
      line-height: 32px;
      text-shadow: 1px 2px 0px rgba(17, 20, 22, 0.22);
    }
  }
  .top_img1 {
    width: 25%;
    height: 100%;
    background: url("~@/static/screen/icon-zlsl.png") no-repeat;
    background-size: 100% 100%;
  }
  .top_img2 {
    width: 25%;
    height: 100%;
    background: url("~@/static/screen/icon-fmsl.png") no-repeat;
    background-size: 100% 100%;
  }
  .top_img3 {
    width: 25%;
    height: 100%;
    background: url("~@/static/screen/icon-zlzrl.png") no-repeat;
    background-size: 100% 100%;
  }
  .top_img4 {
    width: 25%;
    height: 100%;
    background: url("~@/static/screen/icon-hzsql.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
