<template>
  <div class="details">
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item><router-link :to="'/home'">首页</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="'/patent'">专利</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>专利详情</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="back_btn" @click="backbtn">返回</div>
    <div class="details_title">
      <div class="patent_num">{{ zlcode ? zlcode : details.gkh }}</div>
      <div class="patent_title">{{ title ? title : details.title }}</div>
    </div>
    <div class="btn_group">
      <span style="cursor: pointer" @click="openModal"><img src="@/static/patent/icon-dc.png" alt="" />导出</span>

      <span style="cursor: pointer" @click="followFun" v-if="!details.follow"
        ><img src="@/static/patent/icon-gz.png" alt="" />关注</span
      >
      <span style="cursor: pointer" @click="cancelFun" v-else
        ><img src="@/static/patent/icon-gz-select.png" alt="" />已关注</span
      >
    </div>
    <div class="patent_con">
      <div class="patent_con_left">
        <!-- 技术摘要 -->
        <!-- <div class="patent_con1" id="technicalSummary">
          <h2 class="title">技术摘要</h2>
          <p class="desc">
            本技术解决的问题：如果是单船起吊，则起重船上一定要具备主吊钩和副吊钩，且钩头方位、各个钩的起重能力需满足要求，否则就只有采用两条起重船才能实现翻身，因此要增加船舶设备的投入，施工成本高。
          </p>
        </div> -->
        <!-- 摘要 -->
        <div class="patent_con1" id="summary">
          <h2 class="title">摘要</h2>
          <p class="desc" v-html="details.zy"></p>
        </div>
        <!-- 技术领域 -->
        <div class="patent_con1" id="techAera">
          <h2 class="title">技术领域</h2>
          <EchartsCloud :id="'tech'" :dataArr="techArr" v-if="techArr.length > 0"></EchartsCloud>
          <a-empty v-else />
        </div>
        <!-- 应用领域 -->
        <!-- <div class="patent_con1" id="appArea">
          <h2 class="title">应用领域</h2>
          <EchartsCloud
            :id="'applicationArea'"
            :dataArr="techArr"
          ></EchartsCloud>
        </div> -->
        <!-- 主要录项 -->
        <div class="patent_con1" id="mainentries">
          <h2 class="title">主著录项</h2>
          <a-descriptions bordered :column="2" :labelStyle="{ background: '#F2F9FC' }">
            <a-descriptions-item style="width: 160px" label="申请号">
              {{ details.sqh }}
            </a-descriptions-item>
            <a-descriptions-item style="width: 160px" label="优先权号">{{ details.yxqh }} </a-descriptions-item>
            <a-descriptions-item label="申请日">{{ details.sqrq }}</a-descriptions-item>
            <a-descriptions-item label="优先权日">{{ details.zlyxqr }}</a-descriptions-item>
            <a-descriptions-item label="公开号">{{ details.gkh }}</a-descriptions-item>
            <a-descriptions-item label="授权日">{{ details.sqr }}</a-descriptions-item>
            <a-descriptions-item label="公开日">{{ details.gkrq }}</a-descriptions-item>
            <a-descriptions-item label="失效日">{{ details.sxr }}</a-descriptions-item>
            <a-descriptions-item label="专利类型">{{ details.zl_type }}</a-descriptions-item>
            <a-descriptions-item label="专利维持期">{{ details.zlwqq }}</a-descriptions-item>
            <a-descriptions-item label="应用领域">{{ details.app_area }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <!-- 相关人 -->
        <div class="patent_con1" id="relatedper">
          <h2 class="title">相关人</h2>
          <a-descriptions bordered :column="2" :labelStyle="{ background: '#F2F9FC' }">
            <a-descriptions-item style="width: 160px" label="申请人(原始)">
              {{ details.sqrys }}
            </a-descriptions-item>
            <a-descriptions-item style="width: 160px" label="专利权人(当前)">
              {{ details.zlqrjgs }}
            </a-descriptions-item>
            <a-descriptions-item label="申请人">{{ details.zlr }}</a-descriptions-item>
            <a-descriptions-item label="专利权人">{{ details.zlqr }}</a-descriptions-item>
            <a-descriptions-item label="申请人(工商)">{{ details.sqrgs }}</a-descriptions-item>
            <a-descriptions-item label="专利权人(机构树)">{{ details.zlqrjgs }}</a-descriptions-item>
            <a-descriptions-item label="申请人(机构树)">{{ details.sqrjgs }}</a-descriptions-item>
            <a-descriptions-item label="代理人">{{ details.dlr }}</a-descriptions-item>
            <a-descriptions-item label="发明人">{{ details.fmr }}</a-descriptions-item>
            <a-descriptions-item label="代理机构">{{ details.dljg }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <!-- 地址 -->
        <div class="patent_con1" id="address">
          <h2 class="title">地址</h2>
          <a-descriptions bordered :column="2" :labelStyle="{ background: '#F2F9FC' }">
            <a-descriptions-item style="width: 160px" label="申请人国">{{ details.sqrg }}</a-descriptions-item>
            <a-descriptions-item style="width: 160px" label="公开国">{{ details.gkg }}</a-descriptions-item>
            <a-descriptions-item label="申请人地址">{{ details.sqr_address }}</a-descriptions-item>
            <a-descriptions-item label="专利权人地址">{{ details.zlqr_address }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <!-- 统计信息 -->
        <div class="patent_con1" id="statistic">
          <h2 class="title">统计信息</h2>
          <a-descriptions bordered :column="2" :labelStyle="{ background: '#F2F9FC' }">
            <a-descriptions-item style="width: 160px" label="引用数量">{{ details.yy_num }}</a-descriptions-item>
            <a-descriptions-item style="width: 160px" label="权利要求数">{{ details.qlyq_num }}</a-descriptions-item>
            <a-descriptions-item label="被引用数量">{{ details.byy_num }}</a-descriptions-item>
            <a-descriptions-item label="同族数量">{{ details.tzzl_num }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <!-- 分类号 -->
        <div class="patent_con1" id="catenum">
          <h2 class="title">分类号</h2>
          <a-descriptions bordered :column="2" :labelStyle="{ background: '#F2F9FC' }">
            <a-descriptions-item style="width: 160px" label="IPC分类号">{{ details.ipcflh }}</a-descriptions-item>
            <a-descriptions-item style="width: 160px" label="战略新兴产业分类号">{{
              details.zlxxxcy
            }}</a-descriptions-item>
            <a-descriptions-item label="国民经济分类号">{{ details.gmjjfl }}</a-descriptions-item>
            <a-descriptions-item label="双十产业集群分类号">{{ details.sscyjqfl }}</a-descriptions-item>
          </a-descriptions>
        </div>
      </div>
      <div class="company_con_anchor">
        <a-anchor @click="handleAnchorClick">
          <!-- <a-anchor-link href="#technicalSummary" title="AI技术摘要" /> -->
          <a-anchor-link href="#summary" title="摘要" />
          <a-anchor-link href="#techAera" title="技术领域" />
          <!-- <a-anchor-link href="#appAera" title="应用领域" /> -->
          <a-anchor-link href="#mainentries" title="主著录项" />
          <a-anchor-link href="#relatedper" title="相关人" />
          <a-anchor-link href="#address" title="地址" />
          <a-anchor-link href="#statistic" title="统计信息" />
          <a-anchor-link href="#catenum" title="分类号" />
        </a-anchor>

        <div id="components-back-top-demo-custom">
          <a-back-top>
            <div class="ant-back-top-inner">
              <img src="@/static/common/icon-top.png" alt="" />
              回到顶部
            </div>
          </a-back-top>
        </div>
      </div>
      <div class="patent_con_right">
        <div class="patent_con1">
          <h2 class="title">智能推荐</h2>
          <a-select
            v-model:value="value1"
            :options="optionsType"
            mode="multiple"
            placeholder="请选择"
            style="width: 300px"
          ></a-select
          ><a-button type="primary" @click="searchRemend">搜索</a-button>
          <div class="result">
            推荐
            <span style="color: #4079ff; font-weight: bold">{{ count }}</span
            >条相关结果
          </div>
          <div class="card" v-for="item in list" :key="item">
            <div class="card_title" @click="openCompany(item.id, item.company_name)">
              <div v-html="item.company_name"></div>
            </div>
            <a-tag class="tags" color="#56CA95">{{ item.status }}</a-tag>
            <a-tag class="tags" color="#6096E6" v-if="item.quoted_company == 1">{{ "上市挂牌企业" }}</a-tag>
            <a-tag class="tags" color="#6096E6" v-if="item.high_new == 1">{{ "高新技术企业" }}</a-tag>
            <a-tag class="tags" color="#6096E6" v-if="item.zjtg_sjxjr == 1">{{ "专精特新小巨人" }}</a-tag>
            <a-tag class="tags" color="#6096E6" v-if="item.zjtg == 1">{{ "专精特新中小企业" }}</a-tag>
            <a-divider />
            <div class="card_desc">
              <a-descriptions layout="vertical">
                <a-descriptions-item label="法定代表人">{{ item.legal }}</a-descriptions-item>
                <a-descriptions-item label="注册时间">{{
                  item.build_date.substring(0, item.build_date.indexOf("T"))
                }}</a-descriptions-item>
                <a-descriptions-item label="注册资本">{{ item.capital }}</a-descriptions-item>
                <a-descriptions-item label="国民经济行业">
                  {{ item.company_industry_parent ? item.company_industry_parent + ">" : ""
                  }}{{ item.company_industry }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="注册地址">{{ item.address }}</a-descriptions-item>
                <template v-if="item.patents.length != 0">
                  <a-descriptions-item :span="3" label="专利信息" v-if="item.patents.length > 3">
                    <div v-html="item.patents[0]"></div>
                    <div v-html="item.patents[1]"></div>
                    <div v-html="item.patents[2]"></div>
                  </a-descriptions-item>

                  <a-descriptions-item :span="3" label="专利信息" v-else-if="item.patents.length == 0">
                  </a-descriptions-item>

                  <a-descriptions-item :span="3" label="专利信息" v-else>
                    <template v-for="(patent, index) in item.patents" :key="index">
                      <div v-html="patent"></div>
                    </template>
                  </a-descriptions-item>
                </template>
                <template v-if="item.business_scope">
                  <a-descriptions-item :span="3" label="经营范围">
                    <div v-html="item.business_scope"></div>
                  </a-descriptions-item>
                </template>
              </a-descriptions>
            </div>
          </div>

          <a-pagination
            v-model:current="currentPage"
            @change="onChange"
            :total="count"
            v-model:pageSize="pageSize"
            :showSizeChanger="false"
          />
        </div>
      </div>
    </div>

    <a-modal
      v-model:visible="visible"
      title="导出"
      @ok="daochu(details)"
      cancelText="取消"
      okText="确定"
      @onCancel="closeModal"
    >
      <p>报告格式&nbsp;&nbsp;&nbsp;PDF</p>
      <a-form :model="formState" ref="form">
        <a-form-item
          :name="'email'"
          label="发送给"
          :rules="[
            { required: true, message: '请输入邮箱' },
            { type: 'email', message: '邮箱不合法' },
          ]"
        >
          <a-input v-model:value="formState.email" placeholder="请输入邮箱地址" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
  <Loading v-if="loading"></Loading>
</template>
<script>
import Loading from "../../components/LoadAnimation.vue";
import * as echarts from "echarts";
import "echarts-wordcloud";
import { DownOutlined } from "@ant-design/icons-vue";
import { patentDetail, exportword, Recommend } from "../../utils/indexApi";
import { userFollow, cancelFollow } from "../../utils/userAPI";
import EchartsCloud from "../../components/EchartsCloud.vue";
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import { message } from "ant-design-vue";
function base64DataURLToArrayBuffer(dataURL) {
  const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return false;
  }
  const stringBase64 = dataURL.replace(base64Regex, "");
  let binaryString;
  if (typeof window !== "undefined") {
    binaryString = window.atob(stringBase64);
  } else {
    binaryString = new Buffer(stringBase64, "base64").toString("binary");
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}
export default {
  name: "patentDetails",
  data() {
    return {
      loading: false,
      formState: {
        email: "",
      },
      id: "",
      pageSize: 3,
      currentPage: 1,
      visible: false,
      email: "",
      value: undefined,
      count: 0,
      zlcode: "",
      title: "",
      follow: true,
      value1: [],
      details: {},
      optionsType: [
        { label: "上市挂牌企业", value: "上市挂牌企业" },
        { label: "高新技术企业", value: "高新技术企业" },
        { label: "专精特新小巨人", value: "专精特新小巨人" },
        { label: "专精特新中小企业", value: "专精特新中小企业" },
      ],
      techArr: [],
      list: [],
    };
  },
  components: {
    EchartsCloud,
    // DownOutlined,
    Loading,
  },
  methods: {
    //取消关注
    async cancelFun() {
      let cancelRes = await cancelFollow({
        f_type: "patent",
        ent_or_pat_id: this.id,
      });
      if (cancelRes.code == 0) {
        this.$router.go(0);
        message.success(cancelRes.msg);
      } else {
        message.error(cancelRes.msg);
      }
    },
    //关注接口
    async followFun() {
      let Follow = await userFollow({
        f_type: "patent",
        ent_or_pat_id: this.id,
        name: this.details.gkh,
      });
      if (Follow.code == 0) {
        this.$router.go(0);
        message.success(Follow.msg);
      } else {
        message.error(Follow.msg);
      }
    },

    handleAnchorClick(e) {
      e.preventDefault();
    },
    openModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    init(id) {
      patentDetail({ id: id }).then((res) => {
        if (res.code == 0) {
          this.details = res.data;
          Object.entries(this.details).map((item) => {
            if (item[1] == null || item[1] === "") {
              item[1] = "-";
            }
            this.details[item[0]] = item[1];
          });
          let word_cloud = res.data.word_cloud;
          this.techArr = [];
          for (let key in word_cloud) {
            key = key.replaceAll("<Sub>0</Sub>", "₀");
            key = key.replaceAll("<Sub>1</Sub>", "₁");
            key = key.replaceAll("<Sub>2</Sub>", "₂");
            key = key.replaceAll("<Sub>3</Sub>", "₃");
            key = key.replaceAll("<Sub>4</Sub>", "₄");
            key = key.replaceAll("<Sub>5</Sub>", "₅");
            key = key.replaceAll("<Sub>6</Sub>", "₆");
            key = key.replaceAll("<Sub>7</Sub>", "₇");
            key = key.replaceAll("<Sub>8</Sub>", "₈");
            key = key.replaceAll("<Sub>9</Sub>", "₉");
            this.techArr.push({
              name: key,
              value: word_cloud[key],
            });
          }
        } else {
          message.error(res.msg);
        }
      });
      this.getRecommend();
    },
    //打开企业页面
    openCompany(id, company_name) {
      this.$router.push({
        path: "/companyDetails",
        query: {
          id: id,
          name: company_name,
        },
      });
    },
    //智能推荐翻页
    onChange(page) {
      window.scrollTo(0, 0);
      this.currentPage = page;
      this.getRecommend();
    },
    getRecommend() {
      Recommend({
        id: this.id,
        page: this.currentPage,
        size: 3,
        qualifications: this.value1,
      }).then((res) => {
        if (res.code == 0) {
          this.list = res.data.list;
          this.count = res.data.total;
        } else {
          message.error(res.msg);
        }
      });
    },
    //返回上一页
    backbtn() {
      this.$router.go(-1);
    },
    //搜索筛选智能推荐
    searchRemend() {
      this.getRecommend();
    },
    //导出word
    daochu(details) {
      this.$refs.form
        .validate("email")
        .then(() => {
          // 邮箱号码格式正确，可以发送
          var that = this;
          that.visible = false;
          that.loading = true;
          let date = new Date();
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          var day = date.getDate();
          month = month > 9 ? month : "0" + month;
          day = day < 10 ? "0" + day : day;
          var today = year + "年" + month + "月" + day + "日";
          var chartDom1 = document.getElementById("tech");

          let myChart1 = null;

          myChart1 = echarts.init(chartDom1);
          //技术领域云图
          let color = [
            "#FF4040",
            "#FFA500",
            "#3CB371",
            "#8470FF",
            "#FA8072",
            "#EEA9B8",
            "#9F79EE",
            "#87CEFF",
            "#DA70D6",
            "#00B2EE",
            "#f9e88b",
          ];
          myChart1.setOption({
            series: [
              {
                type: "wordCloud",
                gridSize: 10,
                sizeRange: [14, 60],
                rotationRange: [0, 0],
                //随机生成字体颜色
                // maskImage: maskImage,
                textStyle: {
                  color: function (params) {
                    var index = params.dataIndex;
                    return color[index % color.length];
                  },
                },
                left: "center",
                top: "center",
                right: null,
                bottom: null,
                width: "200%",
                height: "200%",
                //数据
                data: that.techArr,
              },
            ],
          });

          //智能推荐表格请求前10个
          let listRecommend = [];
          Recommend({
            id: that.id,
            page: that.currentPage,
            size: 10,
            qualifications: [],
          }).then((res) => {
            if (res.code == 0) {
              listRecommend = res.data.list;
            } else {
              message.error(res.msg);
            }
          });

          setTimeout(() => {
            // 加setTimeout是为了让echarts渲染完成后生成图片
            JSZipUtils.getBinaryContent("patent.docx", function (error, content) {
              // 抛出异常
              if (error) {
                throw error;
              }
              const opts = {
                centered: false,
                fileType: "docx",
              };
              opts.getImage = function (tagValue) {
                if (tagValue.size && tagValue.data) {
                  return base64DataURLToArrayBuffer(tagValue.data);
                }
                return base64DataURLToArrayBuffer(tagValue);
              };
              opts.getSize = function (_, tagValue) {
                if (tagValue.size && tagValue.data) {
                  return tagValue.size;
                }
                return [600, 200];
              };
              // 创建一个JSZip实例，内容为模板的内容
              const zip = new PizZip(content);
              // 创建并加载docxtemplater实例对象
              const doc = new docxtemplater().loadZip(zip);

              doc.setOptions({
                nullGetter: function () {
                  //设置空值 undefined 为''
                  return "";
                },
              });
              // 设置图片模块
              doc.attachModule(new ImageModule(opts));

              listRecommend.forEach((item, index) => {
                item.index = index + 1;
                item.qualif = [];
                if (item.high_new == 1) {
                  item.qualif.push("高新技术企业");
                }
                if (item.zjtg == 1) {
                  item.qualif.push("专精特新企业");
                }
                if (item.zjtg_sjxjr == 1) {
                  item.qualif.push("专精特新小巨人企业");
                }
                if (item.quoted_company == 1) {
                  item.qualif.push("上市企业");
                }
                if (item.patents.length > 3) {
                  item.patents.splice(2, item.patents.length - 3);
                }
                if (item.business_scope) {
                  item.business_scope_clone = [...item.business_scope];

                  item.business_scope_clone.forEach((it, index) => {
                    item.business_scope_clone[index] = it.replace(/<[^<>]+>/g, "");
                  });
                }

                item.patents_clone = [...item.patents];
                item.patents_clone.forEach((it, index) => {
                  item.patents_clone[index] = it.replace(/<[^<>]+>/g, "");
                });
                item.company_name_clone = item.company_name;
                item.company_name_clone = item.company_name_clone.replace(/<[^<>]+>/g, "");
              });

              // 设置模板变量的值
              doc.setData({
                ...details,
                today,
                listRecommend,
                // business_scope,
                // patent,
                image1: myChart1.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
              });
              try {
                // 用模板变量的值替换所有模板变量
                doc.render();
              } catch (error) {
                console.error("导出报表失败");
                throw error;
              }
              // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
              let out = doc.getZip().generate({
                type: "blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              //formData文件流
              that.blobToBase64(out, that.formState.email, that.details.gkh, that.id);
              // 将目标文件对象保存为目标类型的文件，并命名
              // saveAs(out, `专利成果转移转化智能推荐报告.docx`);
            });
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          // return false;
          // 处理验证失败的情况
        });
    },
    blobToBase64(blob, email, name, id) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          exportword({
            report_data: e.target.result,
            email: email,
            name: name,
            f_type: "patent",
            ent_or_pat_id: id,
          }).then((res) => {
            if (res.code == 0) {
              this.loading = false;
              message.success(res.msg);
            } else {
              message.error(res.msg);
            }
          });
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error("文件流异常"));
        };
      });
    },
  },
  created() {
    // this.$store.commit("setcurrent", "专利");
    localStorage.setItem("current", "专利");
    this.id = this.$route.query.id;
    this.zlcode = this.$route.query.zlcode;
    this.title = this.$route.query.title;

    //初始化专利详情
    this.init(this.id);
  },
};
</script>
<style lang="less" scoped>
.PDF {
  display: inline-block;
  width: 60px;
  text-align: center;
  line-height: 30px;
  height: 30px;
  border: 1px solid #ccc;
}
.details {
  padding: 0 30px;
  width: calc(100% - 80px);
  background-color: #ebebeb;
  position: relative;

  .back_btn {
    width: 76px;
    height: 32px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    color: #3e7bfa;
    text-align: center;
    line-height: 32px;
    position: absolute;
    right: 80px;
    top: 10px;
    cursor: pointer;
  }
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .details_title {
    background-color: #0062c4;
    width: 100%;
    height: 176px;
    .patent_num {
      font-size: 25px;
      padding: 20px 0 15px 20px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #c7d8ec;
    }
    .patent_title {
      font-size: 25px;
      padding: 0 0 20px 20px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #fff;
    }
  }
  .btn_group {
    position: absolute;
    top: 80px;
    right: 60px;
    span {
      display: inline-block;
      width: 100px;
      height: 32px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      color: #fff;
      line-height: 32px;
      text-align: center;
      margin-right: 15px;

      img {
        width: 17px;
        vertical-align: text-bottom;
        margin-right: 5px;
      }
    }
  }
  .patent_con {
    clear: both;
    display: flex;
    position: relative;
    justify-content: space-between;
    background-color: #f3f3f6;
    .patent_con_left {
      .patent_con1 {
        width: 949px;
        // height: 175px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        padding: 30px 30px;
        margin: 15px 0;
        .title {
          color: #1c1c28;
          font-size: 18px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 28px;
        }
        .desc {
          font-size: 12px;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          color: #1c1c28;
          line-height: 28px;
        }
      }
    }
    .company_con_anchor {
      margin-top: 15px;

      font-weight: bold;
      color: #1c1c28;
      :deep(.ant-anchor-link-title) {
        color: #1c1c28;
        margin-bottom: 10px;
      }
      :deep(.ant-anchor-link-active > .ant-anchor-link-title) {
        color: #1890ff;
      }
      :deep(.ant-anchor-ink::before) {
        width: 8px;
        background-color: #f5f5f5;
      }

      :deep(.ant-anchor-wrapper) {
        background-color: #fff;
        padding-left: 10px;
        color: #1c1c28;
        height: 350px;
        width: 150px;
        margin-left: 10px;
        padding: 30px 15px 0;
      }
      #components-back-top-demo-custom .ant-back-top {
        bottom: 50%;
        right: calc(100vw - 1150px);
        // right: 780px;
        top: 350px;
      }
      #components-back-top-demo-custom .ant-back-top-inner {
        margin-top: 20px;
        width: 150px;
        height: 60px;
        background-color: #fff;
        text-align: center;
        line-height: 60px;
        border-radius: 8px;
      }
    }

    .patent_con_right {
      margin-top: 15px;
      width: 610px;
      height: 680px;
      background-color: #fff;
      .patent_con1 {
        // width: 949px;
        // height: 175px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        padding: 20px 20px;
        margin: 15px 0;
        .title {
          color: #1c1c28;
          font-size: 18px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;

          line-height: 28px;
        }
        .result {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
        }
        .card {
          background: #f6f7fb;
          border-radius: 10px 10px 10px 10px;
          opacity: 0.8;
          padding: 24px;
          margin-bottom: 15px;
          :deep(.ant-tag-has-color) {
            padding: 5px;
            border-radius: 6px;
          }
          .card_title {
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            color: #333333;
            font-size: 20px;
          }
          .tags {
            margin: 5px;
          }
          :deep(b) {
            color: rgba(191, 36, 40, 1);
          }
          .card_desc {
            :deep(.ant-descriptions-item-label) {
              color: #666;
              font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
              font-weight: 400;
            }
            :deep(.ant-descriptions-item-content) {
              font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
              font-weight: bold;
              color: #1c1c28;
            }
            :deep(.ant-descriptions-row > th) {
              padding-bottom: 4px;
            }
            // b{
            //   color:#1890ff;
            // }
          }
        }
      }
    }
  }
}
:deep(.ant-pagination) {
  text-align: center;
}
:deep(.ant-descriptions-item-container .ant-descriptions-item-content) {
  display: block;
}
</style>
import { entries } from "core-js/core/array";
