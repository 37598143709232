import { get, post, put, Delete } from './request'
export const patentType = p => post('/api/dataScreen/patentType', p);
export const patentAuthorized = p => post('/api/dataScreen/patentAuthorized', p);
//专利转化落地区域图
export const patentTransform = p => get('/api/dataScreen/patentTransform', {});
//专利发展趋势图
export const patentDevelop = p => post('/api/dataScreen/patentDevelop', p);
//专利受让省份排名
export const patentSSProvince = p => post('/api/dataScreen/patentSSProvince', p);
//大屏标题文字
export const patentHead = p => get('/api/dataScreen/patentHead', {});
//专利发明人排名
export const patentInventor = p => post('/api/dataScreen/patentInventor', p);
//产业排名
export const patentIndustryTopN = p => get('/api/dataScreen/patentIndustryTopN', {});
//项目合作方
export const projectPartner = p => get('/api/dataScreen/projectPartner', {});






