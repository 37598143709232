<template>
  <div ref="overview" style="background: #f3f3f3">
    <div class="company_con">
      <div class="company_con_left">
        <!-- 研发规模 -->
        <div class="company_con1" id="researchDevelopment">
          <h2 class="title">研发规模</h2>
          <a-descriptions bordered :column="2" :labelStyle="{ background: '#F2F9FC' }">
            <a-descriptions-item label="专利申请数量">{{
              developmentOverview.patent_application_num
            }}</a-descriptions-item>
            <a-descriptions-item label="非外观专利申请数量">{{
              developmentOverview.not_appearance_num
            }}</a-descriptions-item>
            <a-descriptions-item label="商标数量">{{ developmentOverview.company_trademark_num }}</a-descriptions-item>
            <a-descriptions-item label="作品著作权登记总数">{{
              developmentOverview.company_work_copyright_num
            }}</a-descriptions-item>
            <a-descriptions-item label="软件著作权登记总数">{{
              developmentOverview.company_software_num
            }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <!-- 研发可持续性 -->
        <div class="company_con1" id="ResearchSustainable">
          <h2 class="title">研发可持续性</h2>
          <p v-if="deveSustainData.patent_application_num">
            企业的总专利申请量为<span class="color">{{ deveSustainData.patent_application_num }}</span
            >件，最早专利申请是<span class="color"> {{ deveSustainData.earliest_patent_application_year }} </span
            >年，企业在<span class="color">{{ deveSustainData.most_patent_application_year }}</span
            >年的专利申请量最多，为<span class="color">{{ deveSustainData.most_patent_application_num }}</span
            >件且专利授权率为<span class="color">{{ deveSustainData.most_patent_authorization_rate }}</span
            >。
          </p>
          <p v-else>
            企业的总专利申请量为<span class="color">{{ deveSustainData.patent_application_num }}</span
            >件
          </p>
          <a-radio-group v-model:value="tabchange" style="margin: 8px" v-if="deveSustainData.patent_application_num">
            <a-radio-button value="1">专利申请年份趋势</a-radio-button>
            <a-radio-button value="2">历年专利申请及授权</a-radio-button>
          </a-radio-group>
          <EchartsCon
            v-if="tabchange == 1 && deveSustainData.patent_application_num"
            :id="'applyTrend'"
            :option="option1"
            :style="'height:300px'"
          ></EchartsCon>
          <EchartsCon
            v-if="tabchange == 2 && deveSustainData.patent_application_num"
            :id="'patentAuthorize'"
            :option="option2"
            :style="'height:300px;width:100%;'"
          ></EchartsCon>
        </div>
        <!-- 专利结构分析 -->
        <div class="company_con1" id="PatentAnalysis">
          <h2 class="title">专利结构分析</h2>
          <p>
            专利结构可从专利类型以及专利基本法律状态两个方面进行分析。从专利类型来看，一般在评估专利技术质量时，普遍认为发明>实用新型>外观。因此，关注发明专利的占比高低，可以帮助了解企业历史研发的技术质量水平。从专利基本法律状态来看，关注失效专利的占比，可以帮助了解企业当前持有技术的质量水平。该企业的专利数量为<span
              class="color"
              >{{ devstructureData.patent_application_num }}</span
            >件，其中发明专利<span class="color">{{ devstructureData.invent_patent_num }}</span
            >件，占比<span class="color">{{ devstructureData.invent_patent_percent }}</span
            >；有效专利<span class="color">{{ devstructureData.valid_patent_num }}</span
            >件，占比<span class="color">{{ devstructureData.valid_patent_percent }}</span
            >。
          </p>
          <div class="ecahrsThree">
            <EchartsCon
              :style="'width:33%;'"
              :id="'echartsone'"
              :option="option3"
              v-if="devstructureData.patent_application_num"
            ></EchartsCon>
            <EchartsCon
              :style="'width:33%;'"
              :id="'echartstwo'"
              :option="option4"
              v-if="devstructureData.patent_application_num"
            ></EchartsCon>
            <EchartsCon
              :style="'width:33%;'"
              :id="'echartsthree'"
              :option="option5"
              v-if="devstructureData.patent_application_num"
            ></EchartsCon>
          </div>
        </div>
        <!-- 技术领域布局 -->
        <div class="company_con1" id="TechnicalLayout">
          <h2 class="title">技术领域布局</h2>
          <p>
            特定周期内，某个主题关键词出现的次数越多，则企业在该主题领域的技术布局越多。通过算法分析，该企业近期主要专注在
            <span class="color" v-for="(item, index) in o_word_cloud" :key="item">
              {{ index == o_word_cloud.length - 1 ? item : item + "、" }} </span
            >等技术领域
          </p>
          <EchartsCloud :id="'TechnicalField'" :dataArr="techArr" v-if="techArr.length > 0"></EchartsCloud>
        </div>
      </div>
      <div class="company_con_anchor">
        <a-anchor @click="handleAnchorClick">
          <a-anchor-link style="font-size: 20px; color: #1c1c28; font-weight: bold" title="研发概要" />
          <a-anchor-link href="#researchDevelopment" title="研发规模" />
          <a-anchor-link href="#ResearchSustainable" title="研发可持续性" />
          <a-anchor-link href="#PatentAnalysis" title="专利结构分析" />
          <a-anchor-link href="#TechnicalLayout" title="技术领域布局" />
        </a-anchor>

        <div id="components-back-top-demo-custom">
          <a-back-top>
            <div class="ant-back-top-inner">
              <img src="@/static/common/icon-top.png" alt="" />
              回到顶部
            </div>
          </a-back-top>
          <!-- <div class="back">回到顶部</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EchartsCon from "../../components/EchartsCon";
import EchartsCloud from "../../components/EchartsCloud";
export default {
  name: "overview",
  components: {
    EchartsCon,
    EchartsCloud,
  },
  props: {
    developmentOverview: Object,
    deveSustain: Object,
    structureData: Object,
  },
  data() {
    return {
      tabchange: "1",
      deveSustainData: null,
      devstructureData: null,
    };
  },

  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      this.getTrend();
      this.getAuthorize();
      this.getPatentStructure();
      this.getTechnicalField();
    },
    //获取专利申请年份趋势
    getTrend() {
      let xdata = [],
        ydata1 = [],
        ydata2 = [],
        ydata3 = [],
        maxdata = [];
      this.deveSustainData.invent.forEach((item) => {
        xdata.push(item[0]);
        ydata1.push(item[1]);
      });
      this.deveSustainData.appearance.forEach((item) => {
        ydata2.push(item[1]);
      });
      this.deveSustainData.utility.forEach((item) => {
        ydata3.push(item[1]);
      });
      this.option1 = {
        legend: {
          icon: "circle",
          orient: "horizontal",
          itemGap: 40,
          itemWidth: 14,
          itemHeight: 14,
          textStyle: {
            fontSize: 14,

            fontWeight: 400,
            padding: [4, 0, 0, 0],
          },
        },
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "10%",
          left: "3%",
          right: "0",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            // color: "white",
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          //   去网格线
          splitLine: {
            show: false,
          },
          type: "value",
          // name: "",

          // 标题名称颜色
          nameTextStyle: {
            color: "white",
            fontSize: 12,
            fontWeight: 800,
          },
          axisLine: {
            // show: false ,// 去除轴线
            lineStyle: {
              lineStyle: {
                color: "white",
              },
            },
          },
          axisLabel: {
            formatter: "{value}",
            color: "white", // 文本颜色
          },
        },
        series: [
          {
            name: "发明专利",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              color: "white",
              formatter: function (parmas) {
                return parmas.value == 0 ? "" : parmas.value;
              },
            },
            barWidth: 20,
            data: ydata1,
          },
          {
            name: "外观专利",
            type: "bar",
            stack: "total",
            barWidth: 20,
            label: {
              show: true,
              color: "white",
              formatter: function (parmas) {
                return parmas.value == 0 ? "" : parmas.value;
              },
            },
            data: ydata2,
          },
          {
            name: "实用新型",
            type: "bar",
            stack: "total",
            barWidth: 20,
            label: {
              show: true,
              color: "white",
              formatter: function (parmas) {
                return parmas.value == 0 ? "" : parmas.value;
              },
            },
            data: ydata3,
          },
        ],
      };
    },
    //获取历年专利申请及授权
    getAuthorize() {
      let xdata = [],
        ydata1 = [],
        ydata2 = [],
        ydata3 = [];
      this.deveSustainData.old_patent_authorization.forEach((item) => {
        xdata.push(item[0]);
        ydata1.push(item[1]);
      });
      this.deveSustainData.old_patent_application.forEach((item) => {
        ydata2.push(item[1]);
      });
      ydata3 = ydata1.map((elem, index) => ((elem / ydata2[index]) * 100).toFixed(2));

      this.option2 = {
        //下面就是上图的配置项，关键部分有注释
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            var html = params[0].name + "<br>";
            // params[i].marker：对应数据的圆点样式
            for (var i = 0; i < params.length - 1; i++) {
              html += params[i].marker + params[i].seriesName + "：" + params[i].value + "<br>";
            }
            //最后一个学生占比数据 添加%
            html += params[i].marker + params[i].seriesName + "：" + params[i].value + "%" + "<br>";
            return html;
          },
        },

        //图表选择栏
        legend: {
          icon: "circle",
          top: 20,
          // right:0,
          // bottom: 0,
          itemHeight: 10, //修改icon图形大小
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            // boundaryGap: [0, 0.01],
            name: "专利数量",
            axisLine: {
              show: false,
            },
            min: 0,
            max: Math.max(...ydata2) + 1,
            minInterval: 1,
            splitLine: {
              show: false,
            },
          },
          {
            type: "value",
            // boundaryGap: [0, 0.01],
            name: "授权占比",
            axisLine: {
              show: false,
            },
            min: 0,
            max: 100,
            axisLabel: {
              formatter: "{value}%",
            },
            splitLine: {
              show: false,
            },
          },
        ],

        series: [
          {
            //里层的柱子
            name: "授权",
            type: "pictorialBar", //象形柱状图
            barWidth: 20, //柱子的宽度
            data: ydata1, //柱子的数据
            symbol: "rect", //柱子的形状
            symbolRepeat: true, //是否重复
            // symbolOffset: [-40, 0], //柱子的位置
            symbolBoundingData: 1, //图形的个数
            z: 12, //柱子的层级
            label: {
              show: false,
              position: "top",
              // color: "#fff",
              formatter: "{c}",
            },
          },
          {
            //外层的柱子
            name: "申请",
            type: "pictorialBar",
            barWidth: 20,
            //symbolSize: [40, 18], //调整截面形状
            // symbolOffset: [-40, 0],
            symbol: "rect",
            symbolRepeat: true,
            symbolBoundingData: 1,
            itemStyle: {
              color: "",
            },
            data: ydata2,
            label: {
              show: true,
              position: "top",
              // color: "#fff",
              formatter: "{c}",
            },
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: ydata3,
            name: "授权占比",
            itemStyle: {},
          },
        ],
      };
    },
    //获取专利结构分析
    getPatentStructure() {
      this.option3 = {
        //你的代码
        title: {
          text: "专利类型分布图",
          left: "25%",
          bottom: "5%",
        },
        //color: colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "专利类型分布图",
            type: "pie",
            radius: ["20%", "40%"],
            center: ["50%", "50%"],
            label: {
              formatter: "{b}：\n{d}%", // 用来换行
            },
            data: this.devstructureData.patent_type,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.option4 = {
        //你的代码
        title: {
          text: "专利状态分布图",
          left: "25%",
          bottom: "5%",
        },
        //color: colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "专利状态分布图",
            type: "pie",
            radius: ["20%", "40%"],
            center: ["50%", "50%"],
            label: {
              formatter: "{b}：\n{d}%", // 用来换行
            },
            data: this.devstructureData.patent_status,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.option5 = {
        //你的代码
        title: {
          text: "有效专利分布图",
          left: "25%",
          bottom: "5%",
        },
        //color: colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "有效专利分布图",
            type: "pie",
            radius: ["20%", "40%"],
            center: ["50%", "50%"],
            label: {
              formatter: "{b}：\n{d}%", // 用来换行
            },
            data: this.devstructureData.valid_patent_type,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    //获取技术领域布局
    getTechnicalField() {},
    handleAnchorClick(e, link) {
      e.preventDefault();
    },
  },
  created() {
    this.deveSustainData = this.$props.deveSustain;
    this.devstructureData = this.$props.structureData;
    this.o_word_cloud = this.$props.developmentOverview.o_word_cloud;
    let word_cloud = this.$props.developmentOverview.word_cloud;
    this.techArr = [];
    for (let key in word_cloud) {
      this.techArr.push({
        name: key,
        value: word_cloud[key],
      });
    }
  },
};
</script>

<style lang="less" scoped>
.company_con {
  clear: both;
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: #f3f3f6;
  width: 1240px;

  .company_con_left {
    .company_con1 {
      // width: 1400px;
      // height: 175px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 40px 30px;
      margin: 15px 0;
      .title {
        color: #1c1c28;
        font-size: 18px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;

        line-height: 28px;
      }
      .desc {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #1c1c28;
        line-height: 28px;
      }
      .ecahrsThree {
        display: flex;
        width: 100%;
        height: 300px;
        justify-content: space-between;
      }
    }
    :deep(.ant-table-thead > tr > th) {
      background-color: #f2f9fc;
    }
  }
  .color {
    color: #53a7f0;
  }
  .company_con_anchor {
    margin-top: 15px;
    font-weight: bold;
    color: #1c1c28;
    :deep(.ant-anchor-link-title) {
      color: #1c1c28;
      margin-bottom: 15px;
    }
    :deep(.ant-anchor-link-active > .ant-anchor-link-title) {
      color: #1890ff;
    }
    :deep(.ant-anchor-ink::before) {
      width: 8px;
      background-color: #f5f5f5;
    }

    :deep(.ant-anchor-wrapper) {
      background-color: #fff;
      padding-left: 10px;
      color: #1c1c28;
      height: 350px;
      margin-left: 10px;
      padding: 30px 15px 0;
    }
    #components-back-top-demo-custom .ant-back-top {
      // bottom: 50%;
      // right: 18.5%;
      bottom: 55%;
      right: 150px;
    }
    #components-back-top-demo-custom .ant-back-top-inner {
      margin-top: 20px;
      width: 150px;
      height: 60px;
      background-color: #fff;
      text-align: center;
      line-height: 60px;
      border-radius: 8px;
    }
  }
  .back {
    display: none;
    margin-top: 20px;
    width: 150px;
    height: 60px;
    background-color: #fff;
    text-align: center;
    line-height: 60px;
  }
}
</style>
