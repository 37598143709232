<template>
  <div class="sider">
    <div
      :class="[current == item.name ? 'sider_item active' : 'sider_item ']"
      v-for="item in siderArr"
      :key="item.name"
      @click="changepage(item.name)"
    >
      <img
        :src="current == item.name ? item.img_select : item.img"
        alt=""
        class="sider_item_img"
      />
      <div
        :class="[
          current == item.name ? ' sider_item_name active ' : 'sider_item_name',
        ]"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SiderBar",
  props: {
    current: {
      type: String,
      default:'首页'
    },
  },
  data() {
    return {
      siderArr: [
        {
          name: "首页",
          img: require("@/static/home/index/icon-nav01.png"),
          img_select: require("@/static/home/index/icon-nav01-select.png"),
        },
        {
          name: "专利",
          img: require("@/static/home/index/icon-nav02.png"),
          img_select: require("@/static/home/index/icon-nav02-select.png"),
        },
        {
          name: "企业",
          img: require("@/static/home/index/icon-nav03.png"),
          img_select: require("@/static/home/index/icon-nav03-select.png"),
        },
        {
          name: "历史记录",
          img: require("@/static/home/index/icon-nav04.png"),
          img_select: require("@/static/home/index/icon-nav04-select.png"),
        },
        {
          name: "我的关注",
          img: require("@/static/home/index/icon-nav05.png"),
          img_select: require("@/static/home/index/icon-nav05-select.png"),
        },
      ],
    };
  },
  methods: {
    changepage(name) {
      // this.current = name;
      this.$emit("changepage", name);
    },
  },
};
</script>
<style lang="less" scoped>
.sider {
  // height: calc(100% - 70px);
  background-color: #002d70;
  width: 80px;
  .sider_item {
    height: 100px;
    text-align: center;
    padding: 20px 10px 20px 0;
    .sider_item_img {
      width: 27px;
    }
    .sider_item_name {
      margin-top: 10px;
      color: #848bad;
      font-size: 14px;
      text-align: center;
    }
    .active {
      color: #fff;
    }
  }
  .active {
    background-color: #124795;
    color: #fff;
  }
}
</style>
