<template>
  <div class="details">
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item><router-link :to="'/home'">首页</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="'/company'">企业</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>公司详情</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="back_btn" @click="backbtn">返回</div>
    <div class="details_title">
      <div class="company_num">
        <span>{{ baseinfo.company_name }}</span>
        <a-tag color="#6096E6" v-if="baseinfo.quoted_company == '1'">{{ "上市企业" }}</a-tag>
        <a-tag color="#6096E6" v-if="baseinfo.high_new == '1'">{{ "高新技术企业" }}</a-tag>
        <a-tag color="#6096E6" v-if="baseinfo.zjtg_sjxjr == '1'">{{ "专精特新小巨人" }}</a-tag>
        <a-tag color="#6096E6" v-if="baseinfo.zjtg == '1'">{{ "专精特新中小企业" }}</a-tag>
      </div>
      <div class="company_basic">
        <div>注册地址：{{ baseinfo.address }}</div>
        <div>邮箱：{{ baseinfo.email }}</div>
        <div>联系电话：{{ baseinfo.telephone }}</div>
        <div>官网：{{ baseinfo.web_site }}</div>
      </div>
    </div>
    <div class="btn_group">
      <span style="cursor: pointer" @click="openModal"><img src="@/static/patent/icon-dc.png" alt="" />导出</span>
      <span style="cursor: pointer" @click="followFun" v-if="!baseinfo.follow"
        ><img src="@/static/patent/icon-gz.png" alt="" />关注</span
      >
      <span style="cursor: pointer" @click="cancelFun" v-else
        ><img src="@/static/patent/icon-gz-select.png" alt="" />已关注</span
      >
    </div>
    <a-tabs v-model:activeKey="activeKey" style="background: #fff">
      <a-tab-pane key="企业概况" tab="企业概况">
        <companyProfile :baseinfo="baseinfo"></companyProfile>
      </a-tab-pane>
      <a-tab-pane key="研发概要" tab="研发概要">
        <overview
          :developmentOverview="developmentOverview"
          :deveSustain="deveSustain"
          :structureData="structureData"
        ></overview>
      </a-tab-pane>
      <a-tab-pane key="技术合作" tab="技术合作">
        <cooperation :jointData="jointData" :patentPurchaseData="patentPurchaseData"></cooperation>
      </a-tab-pane>
      <a-tab-pane key="科创能力评价" tab="科创能力评价">
        <technology :technologyData="technologyData" :company_name="baseinfo.company_name"></technology>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model:visible="visible"
      title="导出"
      @ok="daochu()"
      cancelText="取消"
      okText="确定"
      @onCancel="closeModal"
    >
      <p>报告格式&nbsp;&nbsp;&nbsp;<span class="PDF">PDF</span></p>
      <a-form :model="formState" ref="form">
        <a-form-item
          :name="'email'"
          label="发送给"
          :rules="[
            { required: true, message: '请输入邮箱' },
            { type: 'email', message: '邮箱不合法' },
          ]"
        >
          <a-input v-model:value="formState.email" placeholder="请输入邮箱地址" />
        </a-form-item>
      </a-form>
    </a-modal>
    <div style="width: 70%; visibility: hidden; overflow: hidden; height: 20px">
      <!-- <div style="width: 70%"> -->
      <EchartsCon :id="'applyTrend1'" :option="option1" :style="'height:300px'"></EchartsCon>
      <EchartsCon :id="'patentAuthorize1'" :option="option2" :style="'height:300px;width:100%;'"></EchartsCon>
      <EchartsCon :id="'echartsone1'" :option="option3" :style="'height:300px;width:100%;'"></EchartsCon>
      <EchartsCon :id="'echartstwo1'" :option="option4" :style="'height:300px;width:100%;'"></EchartsCon>
      <EchartsCon :id="'echartsthree1'" :option="option5" :style="'height:300px;width:100%;'"></EchartsCon>
      <EchartsCloud
        :id="'TechnicalField1'"
        :dataArr="techArr1"
        :style="'height:300px;width:100%;'"
        v-if="techArr1.length > 0"
      ></EchartsCloud>
      <EchartsCon :option="option7" :id="'apply'" :style="'height:300px;width:100%;'"></EchartsCon>
      <EchartsCon :option="option8" :id="'purchase'" :style="'height:300px;width:100%;'"></EchartsCon>
      <EchartsCon :option="option9" :id="'radar'" :style="'height:300px;width:100%;'"></EchartsCon>
      <EchartsCon :option="option10" :id="'yibiao'" :style="'height:300px;width:100%;'"></EchartsCon>
    </div>
  </div>
  <Loading v-if="loading"></Loading>
</template>
<script>
import Loading from "../../components/LoadAnimation.vue";
import * as echarts from "echarts";
import EchartsCon from "../../components/EchartsCon";
import EchartsCloud from "../../components/EchartsCloud";
import companyProfile from "./companyProfile.vue";
import {
  enterpriseDetail,
  exportword,
  researchDevelop,
  sustainability,
  structure,
  jointApplication,
  patentPurchase,
  scientificEvaluation,
} from "../../utils/indexApi.js";
import overview from "./overview.vue";
import { message } from "ant-design-vue";
import { userFollow, cancelFollow } from "../../utils/userAPI";
import cooperation from "./cooperation.vue";
import technology from "./technology.vue";
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
function base64DataURLToArrayBuffer(dataURL) {
  const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return false;
  }
  const stringBase64 = dataURL.replace(base64Regex, "");
  let binaryString;
  if (typeof window !== "undefined") {
    binaryString = window.atob(stringBase64);
  } else {
    binaryString = new Buffer(stringBase64, "base64").toString("binary");
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}
export default {
  name: "companyDetails",
  data() {
    return {
      loading: false,
      id: "",
      option1: {},
      option2: {},
      option3: {},
      option4: {},
      option5: {},
      option6: {},
      option7: {},
      option8: {},
      option9: {},
      option10: {},
      formState: {
        email: "",
      },
      visible: false,
      max_value_purchase: 0,
      max_value_apply: 0,
      max_list_apply: [],
      email: "",
      value: undefined,
      activeKey: "企业概况",
      developmentOverview: null,
      deveSustain: null,
      count: 0,
      follow: true,
      techArr1: [],
      baseinfo: {},
      structureData: {},
      jointData: {},
      patentPurchaseData: {},
      technologyData: {},
    };
  },
  components: {
    companyProfile,
    cooperation,
    overview,
    technology,
    EchartsCon,
    EchartsCloud,
    Loading,
  },
  methods: {
    //取消关注
    async cancelFun() {
      let cancelRes = await cancelFollow({
        f_type: "enterprise",
        ent_or_pat_id: this.id,
      });
      if (cancelRes.code == 0) {
        this.$router.go(0);
        message.success(cancelRes.msg);
      } else {
        message.error(cancelRes.msg);
      }
    },
    //关注接口
    async followFun() {
      let Follow = await userFollow({
        f_type: "enterprise",
        ent_or_pat_id: this.id,
        name: this.baseinfo.company_name,
      });
      if (Follow.code == 0) {
        this.$router.go(0);
        message.success(Follow.msg);
      } else {
        message.error(Follow.msg);
      }
    },
    openModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    //返回上一页
    backbtn() {
      // this.$router.go(-1);
      this.$router.back();
    },
    //页面初始化
    init(id) {
      enterpriseDetail({ id: id }).then((res) => {
        if (res.code == 0) {
          this.baseinfo = res.data;
          this.baseinfo.share_holders.forEach((item, index) => {
            item.index = index + 1;
          });
          this.baseinfo.company_marjor.forEach((item, index) => {
            item.index = index + 1;
          });
          this.baseinfo.company_invest.forEach((item, index) => {
            item.index = index + 1;
          });
          this.baseinfo.company_finance.forEach((item, index) => {
            item.index = index + 1;
          });
        } else {
          message.error(res.msg);
        }
      });
      researchDevelop({ id: id }).then((res) => {
        if (res.code == 0) {
          this.developmentOverview = res.data;
          let word_cloud = res.data.word_cloud;
          this.techArr1 = [];
          for (let key in word_cloud) {
            this.techArr1.push({
              name: key,
              value: word_cloud[key],
            });
          }
        } else {
          message.error(res.msg);
        }
      });
      //研发可持续性
      sustainability({ id: id }).then((res) => {
        if (res.code == 0) {
          this.deveSustain = res.data;
          this.deveSustain.most_patent_authorization_rate =
            (this.deveSustain.most_patent_authorization_rate * 100).toFixed(2) + "%";
          this.getTrend();
          this.getAuthorize();
        } else {
          message.error(res.msg);
        }
      });
      //专利结构
      structure({ id: id }).then((res) => {
        if (res.code == 0) {
          this.structureData = res.data;
          //专利结构占比的发明专利占比
          this.structureData.invent_patent_percent = (this.structureData.invent_patent_percent * 100).toFixed(2) + "%";
          //专利结构占比的有效专利占比
          this.structureData.valid_patent_percent = (this.structureData.valid_patent_percent * 100).toFixed(2) + "%";
          this.getPatentStructure();
        } else {
          message.error(res.msg);
        }
      });
      //联合申请
      jointApplication({ id: id }).then((res) => {
        if (res.code == 0) {
          this.jointData = res.data;
          this.max_list_apply = this.jointData.max_list;
          this.max_value_apply = this.jointData.max_list.length > 0 ? this.jointData.max_list[0].max_value : 0;
          this.getOptions1();
        } else {
          message.error(res.msg);
        }
      });
      //专利购买
      patentPurchase({ id: id }).then((res) => {
        if (res.code == 0) {
          this.patentPurchaseData = res.data;
          this.max_value_purchase =
            this.patentPurchaseData.max_list.length > 0 ? this.patentPurchaseData.max_list[0].max_value : 0;
          this.getOptions2();
        } else {
          message.error(res.msg);
        }
      });
      //科创能力分析
      scientificEvaluation({ id: id }).then((res) => {
        if (res.code == 0) {
          this.technologyData = res.data;
          this.option9 = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              show: true,
              bottom: 10,
            },
            radar: [
              {
                indicator: [
                  { name: "公司竞争力", max: 30 },
                  { name: "技术质量", max: 30 },
                  { name: "技术体量", max: 40 },
                ],
                center: ["50%", "50%"],

                radius: 120,
                triggerEvent: true,
              },
              {
                indicator: [
                  { name: "公司竞争力", max: 30 },
                  { name: "技术质量", max: 30 },
                  { name: "技术体量", max: 40 },
                ],
                center: ["50%", "50%"],

                radius: 120,
              },
            ],
            series: [
              {
                type: "radar",
                radarIndex: 0,
                symbol: "none",
                data: [
                  {
                    value: [20, 10, 25],
                    value: [
                      this.technologyData.company_competitiveness_avg,
                      this.technologyData.technical_quality_avg,
                      this.technologyData.technical_volume_avg,
                    ],
                    name: this.technologyData.company_industry + "行业企业均值",
                    areaStyle: {
                      color: "#F7B46A",
                    },
                    lineStyle: {
                      color: "#F7B46A",
                    },
                    itemStyle: {
                      color: "#F7B46A",
                    },
                  },
                ],
              },
              {
                type: "radar",
                radarIndex: 0,
                symbol: "none",
                data: [
                  {
                    // value: [10, 15, 15],
                    value: [
                      this.technologyData.company_competitiveness,
                      this.technologyData.technical_quality,
                      this.technologyData.technical_volume,
                    ],
                    name: this.baseinfo.company_name,

                    areaStyle: {
                      color: "#5A95F7",
                    },
                    lineStyle: {
                      color: "#5A95F7",
                    },
                    itemStyle: {
                      color: "#5A95F7",
                    },
                  },
                ],
              },
            ],
          };
          this.option10 = {
            tooltip: {
              formatter: "{a} <br/>{b} : {c}",
            },
            series: [
              {
                name: "总分",
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 100,
                radius: "160",
                center: ["50%", "60%"],
                type: "gauge",
                progress: {
                  show: true,
                },
                detail: {
                  valueAnimation: true,
                  formatter: "{value}",
                },
                data: [
                  {
                    // value:50,
                    value: this.technologyData.total_score,
                    name: "科创能力总分值",
                  },
                ],
              },
            ],
          };
        } else {
          message.error(res.msg);
        }
      });
    },
    //获取专利申请年份趋势
    getTrend() {
      let xdata = [],
        ydata1 = [],
        ydata2 = [],
        ydata3 = [],
        maxdata = [];
      this.deveSustain.invent.forEach((item) => {
        xdata.push(item[0]);
        ydata1.push(item[1]);
      });
      this.deveSustain.appearance.forEach((item) => {
        ydata2.push(item[1]);
      });
      this.deveSustain.utility.forEach((item) => {
        ydata3.push(item[1]);
      });
      maxdata = ydata1.map((elem, index) => elem + ydata2[index]);
      maxdata = maxdata.map((elem, index) => elem + ydata3[index]);
      this.option1 = {
        legend: {
          icon: "circle",
          orient: "horizontal",
          itemGap: 40,
          itemWidth: 14,
          itemHeight: 14,
          textStyle: {
            fontSize: 14,

            fontWeight: 400,
            padding: [4, 0, 0, 0],
          },
        },
        grid: {
          top: "8%",
          left: "3%",
          right: "0",
          bottom: "10%",
          containLabel: false,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            // color: "white",
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          //   去网格线
          splitLine: {
            show: false,
          },
          type: "value",
          name: "kW.h",
          // 标题名称颜色
          nameTextStyle: {
            color: "white",
            fontSize: 12,
            fontWeight: 800,
          },
          axisLine: {
            // show: false ,// 去除轴线
            lineStyle: {
              lineStyle: {
                color: "white",
              },
            },
          },
          axisLabel: {
            formatter: "{value}",
            color: "white", // 文本颜色
          },
        },
        series: [
          {
            name: "发明专利",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              color: "white",
              formatter: function (parmas) {
                return parmas.value == 0 ? "" : parmas.value;
              },
            },
            barWidth: 20,
            data: ydata1,
          },
          {
            name: "外观专利",
            type: "bar",
            stack: "total",
            barWidth: 20,
            label: {
              show: true,
              color: "white",
              formatter: function (parmas) {
                return parmas.value == 0 ? "" : parmas.value;
              },
            },
            data: ydata2,
          },
          {
            name: "实用新型",
            type: "bar",
            stack: "total",
            barWidth: 20,
            label: {
              show: true,
              color: "white",
              formatter: function (parmas) {
                return parmas.value == 0 ? "" : parmas.value;
              },
            },
            data: ydata3,
          },
        ],
        // dataZoom: {
        //   show: true,
        //   start: 0,
        //   end: 5,
        // },
      };
    },
    //获取历年专利申请及授权
    getAuthorize() {
      let xdata = [],
        ydata1 = [],
        ydata2 = [],
        ydata3 = [];
      this.deveSustain.old_patent_authorization.forEach((item) => {
        xdata.push(item[0]);
        ydata1.push(item[1]);
      });
      this.deveSustain.old_patent_application.forEach((item) => {
        ydata2.push(item[1]);
      });
      ydata3 = ydata1.map((elem, index) => ((elem / ydata2[index]) * 100).toFixed(2));
      this.option2 = {
        //下面就是上图的配置项，关键部分有注释
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            var html = params[0].name + "<br>";
            // params[i].marker：对应数据的圆点样式
            for (var i = 0; i < params.length - 1; i++) {
              html += params[i].marker + params[i].seriesName + "：" + params[i].value + "<br>";
            }
            //最后一个学生占比数据 添加%
            html += params[i].marker + params[i].seriesName + "：" + params[i].value + "%" + "<br>";
            return html;
          },
        },

        //图表选择栏
        legend: {
          icon: "circle",
          top: 20,
          // right:0,
          // bottom: 0,
          itemHeight: 10, //修改icon图形大小
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            // boundaryGap: [0, 0.01],
            name: "专利数量",
            min: 0,
            max: Math.max(...ydata2) + 1,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          {
            type: "value",
            // boundaryGap: [0, 0.01],
            name: "授权占比",
            min: 0,
            max: 100,
            axisLine: {
              show: false,
            },
            axisLabel: {
              formatter: "{value}%",
            },
            splitLine: {
              show: false,
            },
          },
        ],

        series: [
          {
            //里层的柱子
            name: "授权",
            type: "pictorialBar", //象形柱状图
            barWidth: 20, //柱子的宽度
            data: ydata1, //柱子的数据
            symbol: "rect", //柱子的形状
            symbolRepeat: true, //是否重复
            // symbolOffset: [-40, 0], //柱子的位置
            symbolBoundingData: 1, //图形的个数
            z: 12, //柱子的层级
            label: {
              show: true,
              position: "top",
              // color: "#fff",
              formatter: "{c}",
            },
          },
          {
            //外层的柱子
            name: "申请",
            type: "pictorialBar",
            barWidth: 20,
            //symbolSize: [40, 18], //调整截面形状
            // symbolOffset: [-40, 0],
            symbol: "rect",
            symbolRepeat: true,
            symbolBoundingData: 1,
            itemStyle: {
              color: "",
            },
            data: ydata2,
            label: {
              show: true,
              position: "top",
              // color: "#fff",
              formatter: "{c}",
            },
          },
          {
            type: "line",
            yAxisIndex: 1,
            data: ydata3,
            name: "授权占比",
            itemStyle: {},
          },
        ],
      };
    },

    //获取专利结构分析
    getPatentStructure() {
      this.option3 = {
        //你的代码
        title: {
          text: "专利类型分布图",
          left: "43%",
          bottom: "5%",
        },
        //color: colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "专利类型分布图",
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "40%"],
            label: {
              formatter: "{b}：\n{d}%", // 用来换行
            },
            data: this.structureData.patent_type,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.option4 = {
        //你的代码
        title: {
          text: "专利状态分布图",
          left: "43%",
          bottom: "5%",
        },
        //color: colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "专利状态分布图",
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "40%"],
            label: {
              formatter: "{b}：\n{d}%", // 用来换行
            },
            data: this.structureData.patent_status,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.option5 = {
        //你的代码
        title: {
          text: "有效专利分布图",
          left: "43%",
          bottom: "5%",
        },
        //color: colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "有效专利分布图",
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "40%"],
            label: {
              formatter: "{b}：\n{d}%", // 用来换行
            },
            data: this.structureData.valid_patent_type,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    //获取联合申请数据
    getOptions1() {
      let points = [
        {
          name: this.baseinfo.company_name,
          symbolSize: 40,
          category: 0,
          value: 0,
          x: 0,
          y: 0,
          fixed: true,
        },
      ];
      let links = [];
      //添加数据
      if (this.jointData.joint_application.length != 0) {
        this.jointData.joint_application.forEach((item, index) => {
          if (index < 9) {
            points.push({
              name: item.name,
              symbolSize: 40,
              category: 1,
              value: item.value,
              x: index % 2 == 1 ? 300 * Math.ceil(index / 2) : -300 * Math.ceil(index / 2),
              y: 80 * (10 - index),
            });
            links.push({
              source: this.baseinfo.company_name,
              target: item.name,
              value: item.value,
            });
          }
        });
      }
      this.option7 = {
        legend: {
          show: true,
          data: ["搜索的企业", "其他单位"],
        },
        tooltip: {
          show: true,
        },
        series: [
          {
            type: "graph",
            layout: "none",
            force: {
              repulsion: 100,
              edgeLength: 30,
            },
            bottom: 0,
            roam: false,
            label: {
              show: true,
              formatter: function (params) {
                if (params.value) {
                  return params.name + "(" + params.value + ")"; // 显示节点的数值
                } else {
                  return params.name;
                }
              },
            },
            data: points,
            links: links,
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 5,
                curveness: 0,
              },
            },
            categories: [{ name: "搜索的企业" }, { name: "其他单位" }],
          },
        ],
      };
    },
    //获取专利购买数据
    getOptions2() {
      const dataList = this.patentPurchaseData.patent_purchase;
      this.option8 = {
        tooltip: {
          show: false,
        },
        grid: {
          top: "5%",
          left: "3%",
          right: "2%",
          bottom: "1%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#333",
          },
          splitLine: {
            show: true,
            lineStyle: {},
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "#333",
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: dataList.map((r) => r.name),
        },
        series: [
          {
            name: "地市资源排行",
            type: "bar",
            //  symbol: "path://M1 1,L140 1,L140 15,L1 15,Z",
            //  symbolKeepAspect: false,
            //  stack: "triangle",
            barWidth: 16,
            label: {
              show: true,
              position: "right",
              // color: "#3EFFE8",
              fontSize: 12,
              formatter: "{c}",
            },
            data: dataList,
          },
        ],
      };
    },
    //导出overview
    daochu() {
      this.$refs.form
        .validate("email")
        .then(() => {
          this.visible = false;
          this.loading = true;
          var that = this;
          let share_holders = that.baseinfo.share_holders;
          //对模板中股东信息进行修改
          that.baseinfo.share_holders.forEach((item, index) => {
            item.percent = (item.percent * 100).toFixed(2) + "%";
            item.amount = Number(item.amount) + "万元";
          });
          //主要人员
          let company_marjor = that.baseinfo.company_marjor;
          //对外投资
          let company_invest = that.baseinfo.company_invest;
          //融资信息
          let company_finance = that.baseinfo.company_finance;
          let max_value_purchase = that.max_value_purchase;
          let max_list_apply = that.max_list_apply;
          let max_value_apply = that.max_value_apply;
          let date = new Date();
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          var day = date.getDate();
          month = month > 9 ? month : "0" + month;
          day = day < 10 ? "0" + day : day;
          var today = year + "年" + month + "月" + day + "日";

          var chartDom1 = document.getElementById("applyTrend1");
          var chartDom2 = document.getElementById("patentAuthorize1");
          var chartDom3 = document.getElementById("echartsone1");
          var chartDom4 = document.getElementById("echartstwo1");
          var chartDom5 = document.getElementById("echartsthree1");
          var chartDom6 = document.getElementById("TechnicalField1");
          var chartDom7 = document.getElementById("apply");
          var chartDom8 = document.getElementById("purchase");
          var chartDom9 = document.getElementById("radar");
          var chartDom10 = document.getElementById("yibiao");
          let myChart1 = null;
          let myChart2 = null;
          let myChart3 = null;
          let myChart4 = null;
          let myChart5 = null;
          let myChart6 = null;
          let myChart7 = null;
          let myChart8 = null;
          let myChart9 = null;
          let myChart10 = null;
          myChart1 = echarts.init(chartDom1);
          myChart2 = echarts.init(chartDom2);
          myChart3 = echarts.init(chartDom3);
          myChart4 = echarts.init(chartDom4);
          myChart5 = echarts.init(chartDom5);
          myChart6 = echarts.init(chartDom6);
          myChart7 = echarts.init(chartDom7);
          myChart8 = echarts.init(chartDom8);
          myChart9 = echarts.init(chartDom9);
          myChart10 = echarts.init(chartDom10);
          // myChart2 = echarts.init(chartDom2);

          // // 第一个echrts
          myChart1.setOption(this.option1);
          myChart2.setOption(this.option2);
          myChart3.setOption(this.option3);
          myChart4.setOption(this.option4);
          myChart5.setOption(this.option5);
          myChart7.setOption(this.option7);
          myChart8.setOption(this.option8);
          myChart9.setOption(this.option9);
          myChart10.setOption(this.option10);
          let color = [
            "#FF4040",
            "#FFA500",
            "#3CB371",
            "#8470FF",
            "#FA8072",
            "#EEA9B8",
            "#9F79EE",
            "#87CEFF",
            "#DA70D6",
            "#00B2EE",
            "#f9e88b",
          ];
          //技术领域云图
          myChart6.setOption({
            series: [
              {
                type: "wordCloud",
                gridSize: 10,
                sizeRange: [14, 60],
                rotationRange: [0, 0],
                //随机生成字体颜色
                // maskImage: maskImage,
                textStyle: {
                  color: function (params) {
                    var index = params.dataIndex;
                    return color[index % color.length];
                  },
                },
                left: "center",
                top: "center",
                right: null,
                bottom: null,
                width: "200%",
                height: "200%",
                //数据
                data: that.techArr1,
              },
            ],
          });

          setTimeout(() => {
            // 加setTimeout是为了让echarts渲染完成后生成图片
            JSZipUtils.getBinaryContent("company.docx", function (error, content) {
              // 抛出异常
              if (error) {
                throw error;
              }
              const opts = {
                centered: false,
                fileType: "docx",
              };
              opts.getImage = function (tagValue) {
                if (tagValue.size && tagValue.data) {
                  return base64DataURLToArrayBuffer(tagValue.data);
                }
                return base64DataURLToArrayBuffer(tagValue);
              };
              opts.getSize = function (_, tagValue) {
                if (tagValue.size && tagValue.data) {
                  return tagValue.size;
                }
                return [600, 150];
              };
              // 创建一个JSZip实例，内容为模板的内容
              const zip = new PizZip(content);
              // 创建并加载docxtemplater实例对象
              const doc = new docxtemplater().loadZip(zip);

              doc.setOptions({
                nullGetter: function () {
                  //设置空值 undefined 为''
                  return "";
                },
              });
              // 设置图片模块
              doc.attachModule(new ImageModule(opts));

              // 设置模板变量的值
              doc.setData({
                ...that.baseinfo,
                max_value_purchase,
                max_list_apply,
                max_value_apply,
                share_holders,
                company_marjor,
                company_invest,
                company_finance,
                ...that.developmentOverview,
                ...that.deveSustain,
                ...that.structureData,
                ...that.jointData,
                ...that.patentPurchaseData,
                today,
                image1: myChart1.getDataURL({
                  pixelRatio: 2, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image2: myChart2.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image3: myChart3.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image4: myChart4.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image5: myChart5.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image6: myChart6.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image7: myChart7.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image8: myChart8.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image9: myChart9.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
                image10: myChart10.getDataURL({
                  pixelRatio: 5, //导出的图片分辨率比率,默认是1
                  backgroundColor: "#fff", //图表背景色
                  excludeComponents: ["toolbox"], //忽略组件的列表
                  type: "png", //图片类型支持png和jpeg
                }),
              });
              try {
                // 用模板变量的值替换所有模板变量
                doc.render();
              } catch (error) {
                console.error("导出报表失败");
                throw error;
              }
              // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
              let out = doc.getZip().generate({
                type: "blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              //formData文件流
              that.blobToBase64(out, that.formState.email, that.baseinfo.company_name, that.id);
              // 将目标文件对象保存为目标类型的文件，并命名
              // saveAs(out, `企业科创能力报告.docx`);
            });
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          // return false;
          // 处理验证失败的情况
        });
    },
    blobToBase64(blob, email, name, id) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          exportword({
            report_data: e.target.result,
            email: email,
            name: name,
            f_type: "enterprise",
            ent_or_pat_id: id,
          }).then((res) => {
            if (res.code == 0) {
              this.loading = false;
              message.success(res.msg);
            } else {
              message.error(res.msg);
            }
          });
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error("文件流异常"));
        };
      });
    },
  },
  mounted() {},

  created() {
    this.id = this.$route.query.id;

    this.init(this.id);
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="less" scoped>
.details {
  padding: 0 30px;
  width: calc(100%);
  background-color: #ebebeb;
  position: relative;

  .back_btn {
    width: 76px;
    height: 32px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    color: #3e7bfa;
    text-align: center;
    line-height: 32px;
    position: absolute;
    right: 80px;
    top: 10px;
    cursor: pointer;
  }
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .details_title {
    background-color: #0062c4;
    width: 100%;
    height: 176px;
    .company_num {
      font-size: 25px;
      padding: 20px 0 15px 40px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #fff;
      span {
        vertical-align: -webkit-baseline-middle;
        margin-right: 20px;
      }
    }
    :deep(.ant-tag-has-color) {
      padding: 3px 5px;
      border-radius: 6px;
    }
    .company_basic {
      margin-top: 20px;
      display: flex;
      margin-left: 15px;
      div {
        width: 25%;
        height: 22px;
        font-size: 16px;
        padding-left: 10px;
        // text-align: center;
        color: #c7d8ec;
        border-right: 4px solid #c7d8ec;
      }
      div:last-child {
        border-right: transparent;
      }
    }
  }
  .btn_group {
    position: absolute;
    top: 80px;
    right: 60px;
    span {
      display: inline-block;
      width: 100px;
      height: 32px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      color: #fff;
      line-height: 32px;
      text-align: center;
      margin-right: 15px;

      img {
        width: 17px;
        vertical-align: text-bottom;
        margin-right: 5px;
      }
    }
  }
  :deep(.ant-tabs > .ant-tabs-nav) {
    padding-left: 30px;
  }
}
</style>
