<template>
  <div class="personal_con">
    <!-- 面包屑导航 -->
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item>用户设置</a-breadcrumb-item>
      <a-breadcrumb-item>个人信息</a-breadcrumb-item>
    </a-breadcrumb>
    <!--设置用户信息 -->
    <div class="personal_set">
      <h2 class="title">个人信息</h2>
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 5 }"
        autocomplete="off"
        ref="form"
      >
        <a-form-item label="头像" name="img" class="header_label">
          <img :src="img" alt="" class="header" v-if="img" />
          <img
            src="../../static/common/img-tx-big.svg"
            alt=""
            class="header"
            v-else
          />
          <!-- <img :src="require(img)" alt="" class="header" /> -->
          <a-upload
            v-model:file-list="fileList"
            name="file"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="imageUpload"
          >
            <span style="cursor: pointer; padding: 0 15px; color: #3e7bfa">
              重新上传
            </span>
          </a-upload>
        </a-form-item>
        <a-form-item label="账号" name="username">
          <a-input v-model:value="login_name" disabled />
        </a-form-item>

        <a-form-item label="姓名" name="name">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item
          label="手机号"
          :rules="[
            { require: true, message: '请输入手机号' },
            {
              pattern:
                /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
              message: '手机号格式不正确！',
              validateTrigger: 'onBlur',
            },
          ]"
          name="phone"
        >
          <a-input
            placeholder="请输入手机号"
            v-model:value="formState.phone"
            :readonly="phone"
          >
            <template #suffix>
              <span
                class="code"
                v-show="phone != '' && phone"
                @click="updatePhone"
                >修改</span
              >
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="code" label="验证码" v-show="!phone">
          <a-input
            v-model:value="formState.code"
            placeholder="请输入手机验证码"
          >
            <template #suffix>
              <span v-show="!isSend" class="code" @click="sendCode"
                >获取验证码</span
              >
              <span v-show="isSend" class="code">
                {{ RemainingTime }}
                秒后重新获取
              </span>
            </template>
          </a-input>
        </a-form-item>
        <div class="save_btn">
          <a-button type="primary" html-type="submit" @click="save">
            保存
          </a-button>
        </div>
      </a-form>
    </div>
    <!-- 修改手机号弹窗 -->
    <a-modal
      v-model:visible="visibleupdate"
      title="修改手机号"
      cancelText="取消"
      :confirm-loading="confirmLoading"
      okText="确认"
      @ok="handleOk"
    >
      <a-form :model="updateform" style="margin: 0 20%">
        <a-form-item
          label="手机号"
          :rules="[
            {
              pattern:
                /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
              message: '手机号格式不正确！',
              validateTrigger: 'onBlur',
            },
          ]"
          name="phone"
        >
          <a-input placeholder="请输入手机号" v-model:value="updateform.phone">
          </a-input>
        </a-form-item>
        <a-form-item name="code" label="验证码">
          <a-input
            v-model:value="updateform.code"
            placeholder="请输入手机验证码"
          >
            <template #suffix>
              <span v-show="!isSend" class="code" @click="sendCode"
                >获取验证码</span
              >
              <span v-show="isSend" class="code">
                {{ RemainingTime }}
                秒后重新获取
              </span>
            </template>
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { AES_Encrypt, AES_Decrypt } from "@/utils/aes_util.js";
import { message } from "ant-design-vue";
import { sendSms } from "@/utils/loginAPI";
import {
  uploadFile,
  userInfo,
  bduserInfo,
  updateuserInfo,
} from "@/utils/userAPI";

export default {
  name: "personalSetting",
  data() {
    return {
      visibleupdate: false,
      isSend: false,
      resetShow: false,
      timer: null,
      RemainingTime: 0,
      phone: "",
      fileList: [],
      headers: {
        authorization: "authorization-text",
      },
      formState: {
        name: "",
        phone: "",
        code: "",
        head_img: "",
      },
      updateform: {
        phone: "",
        code: "",
      },
      RemainingTime1: "",
      img: "",
    };
  },
  created() {
    //获取个人详细信息
    // this.formState.phone = "1";
    this.getUserInfo();
  },
  mounted() {
    // this.login_name = localStorage.getItem("login_name");
  },
  methods: {
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        return message.error("请上传正确的图片格式!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return message.error("图片大小必须小于2MB!");
      }
    },
    imageUpload(file) {
      let data = new FormData();
      data.append("head_img", file.file);
      uploadFile(data).then((res) => {
        if (res.code == 0) {
          message.success(res.msg);
          this.img = res.data.file_url;

          //  console.log( this.img)
        } else {
          message.error(res.msg);
        }
      });
    },
    //获取用户信息
    getUserInfo() {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.formState.name = res.data.name;
          this.img = res.data.head_img;
          this.login_name = res.data.account_number;
          this.formState.phone = res.data.phone;
          this.phone = res.data.phone;
          console.log(this.img);
        }
      });
    },
    //修改手机号
    handleOk() {
      if (this.updateform.phone && this.updateform.code) {
        this.visibleupdate = false;
        this.formState.phone = this.updateform.phone;
      } else {
        message.error("请输入手机号和验证码");
      }
    },
    //发送验证码
    sendCode() {
      //修改手机号弹窗发送验证码
      if (this.visibleupdate) {
        if (this.updateform.phone) {
          sendSms({ phone: this.updateform.phone }).then((res) => {
            // console.log(res)
            if (res.code == 0) {
              // this.updateform.phone = "";
              // this.updateform.code = "";
              this.isSend = true;
              const TIME_COUNT = 60;
              message.success(res.msg);
              //获取验证码倒计时
              if (!this.timer) {
                this.RemainingTime = TIME_COUNT;
                this.isSend = true;
                this.timer = setInterval(() => {
                  if (
                    this.RemainingTime > 0 &&
                    this.RemainingTime <= TIME_COUNT
                  ) {
                    this.RemainingTime--;
                  } else {
                    this.isSend = false;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else {
              message.error(res.msg);
            }
          });
        } else {
          message.error(res.msg);
        }
      } else {
        if (this.formState.phone) {
          sendSms({ phone: this.formState.phone }).then((res) => {
            if (res.code == 0) {
              this.isSend = true;
              const TIME_COUNT = 60;
              message.success(res.msg);
              //获取验证码倒计时
              if (!this.timer) {
                this.RemainingTime = TIME_COUNT;
                this.isSend = true;
                this.timer = setInterval(() => {
                  if (
                    this.RemainingTime > 0 &&
                    this.RemainingTime <= TIME_COUNT
                  ) {
                    this.RemainingTime--;
                  } else {
                    this.isSend = false;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else {
              message.error(res.msg);
            }
          });
        } else {
          message.error("请先输入手机号");
        }
      }
    },
    //保存个人信息
    save() {
      //第一次绑定手机号
      if (this.phone == "") {
        //用户没输入验证码
        if (this.formState.code == "") {
          message.error("请输入验证码");
          //用户输入了验证码
        } else {
          let params = {
            head_img: this.img,
            name: this.formState.name,
            phone: this.formState.phone,
            code: this.formState.code,
          };
          bduserInfo({ ...params }).then((res) => {
            if (res.code == 0) {
              this.$router.go(0);
              message.success(res.msg);
            } else {
              message.error(res.msg);
            }
          });
        }
      }
      //修改手机号
      else {
        updateuserInfo({
          head_img: this.img,
          name: this.formState.name,
          phone: this.formState.phone,
        }).then((res) => {
          if (res.code == 0) {
            // this.formState.phone = this.updateform.phone;
            this.$router.go(0);
            message.success(res.msg);
          } else {
            message.error(res.msg);
          }
        });
      }
      //更新手机号修改信息
      // if (this.phone != "" && this.formState.code == "") {
      // } else {
      //   //绑定手机号
      //   if (this.phone == "") {
      //     bduserInfo({ ...this.formState }).then((res) => {
      //       if (res.code == 0) {
      //         message.success(res.msg);
      //         location.reload();
      //       } else {
      //         message.error(res.msg);
      //       }
      //     });
      //   } else {
      //     message.error("请输入手机号");
      //   }
      // }
      //
    },
    //修改手机号弹窗打开
    updatePhone() {
      this.visibleupdate = true;
      // this.formState.phone = this.updateform.phone
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.ant-input) {
  height: 32px;
}
:deep(.ant-form-item-label > label) {
  height: 32px;
}
.personal_con {
  background-color: #ebebeb;
  padding-left: 30px;
  width: calc(100% - 80px);
  min-height: 800px;
  flex: 1;
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .personal_set {
    width: 100%;
    height: calc(100% - 80px);
    background-color: #fff;
    padding: 40px 40px;
    .title {
      color: #1c1c28;
      font-size: 18px;
      font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
      font-weight: bold;

      line-height: 28px;
    }
    .code {
      color: #3e7bfa;
      cursor: pointer;
    }
    .header_label {
      line-height: 120px;
    }
    .header {
      width: 120px;
      height: 120px;
    }
    .save_btn {
      padding-left: 150px;
      padding-top: 20px;
    }
  }
}
</style>
