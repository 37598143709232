<template>
  <div style="background: #f3f3f3">
    <div class="company_con">
      <div class="company_con_left">
        <!-- 工商信息 -->
        <div class="company_con1" id="businessInfo">
          <h2 class="title">工商信息</h2>
          <a-descriptions bordered :column="3" :labelStyle="{ background: '#F2F9FC' }">
            <a-descriptions-item style="width: 120px" label="法定代表人">
              <div style="width: 170px">
                {{ baseinfo.legal }}
              </div></a-descriptions-item
            >
            <a-descriptions-item style="width: 120px" label="经营状态">{{ baseinfo.status }}</a-descriptions-item>
            <a-descriptions-item style="width: 120px" label="成立日期">{{ baseinfo.build_date }}</a-descriptions-item>
            <a-descriptions-item label="工商注册号">{{ baseinfo.register_code }}</a-descriptions-item>
            <a-descriptions-item label="注册资本">{{ baseinfo.capital }}</a-descriptions-item>
            <a-descriptions-item label="实缴资本">{{ baseinfo.actual_capital }}</a-descriptions-item>
            <a-descriptions-item label="统一社会信用代码">{{ baseinfo.social_code }}</a-descriptions-item>
            <a-descriptions-item label="纳税人识别号">{{ baseinfo.tax_code }}</a-descriptions-item>
            <a-descriptions-item label="组织机构代码">{{ baseinfo.company_code }}</a-descriptions-item>
            <a-descriptions-item label="营业期限"
              >{{ baseinfo.from_time }}-{{ baseinfo.to_time ? baseinfo.to_time : "无固定期限" }}</a-descriptions-item
            >
            <a-descriptions-item label="纳税人资质">{{ baseinfo.tax_qualification }}</a-descriptions-item>
            <a-descriptions-item label="核准日期">{{ baseinfo.approval_date }}</a-descriptions-item>
            <a-descriptions-item label="企业类型">{{ baseinfo.entype }}</a-descriptions-item>
            <a-descriptions-item label="行业">{{ baseinfo.company_industry }}</a-descriptions-item>

            <a-descriptions-item label="人员规模">{{ baseinfo.staff_range }}</a-descriptions-item>
            <a-descriptions-item label="参保人数" :span="1">{{ baseinfo.bao_num }}</a-descriptions-item>
            <a-descriptions-item label="登记机关" :span="2">{{ baseinfo.register_org }}</a-descriptions-item>
            <a-descriptions-item label="曾用名" :span="1">
              <!-- {{
              baseinfo.history_name ? baseinfo.history_name : "无"
            }} -->
              <div v-for="item in baseinfo.history_name">
                {{ item }}
              </div>
            </a-descriptions-item>
            <a-descriptions-item label="英文名" :span="2">{{ baseinfo.en_name }}</a-descriptions-item>
            <a-descriptions-item label="注册地址" :span="3">{{ baseinfo.address }} </a-descriptions-item>
            <a-descriptions-item label="经营范围" :span="3">{{ baseinfo.business_scope }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <!-- 股东信息 -->
        <div class="company_con1" id="shareholder">
          <h2 class="title">股东信息</h2>

          <!-- 工商登记表格 -->
          <a-table :columns="columns_shareholder" :data-source="baseinfo.share_holders" :pagination="pagination">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex == 'amount'">
                {{ Number(text) + "万元" }}
              </template>
              <template v-if="column.dataIndex == 'percent'">
                {{ (text * 100).toFixed(2) + "%" }}
              </template>
            </template>
          </a-table>
        </div>
        <!-- 主要人员 -->
        <div class="company_con1" id="keyPerson">
          <h2 class="title">主要人员</h2>
          <a-table :columns="columns_keyperson" :data-source="baseinfo.company_marjor" :pagination="pagination">
          </a-table>
        </div>
        <!-- 对外投资 -->
        <div class="company_con1" id="investment">
          <h2 class="title">对外投资</h2>
          <a-table :columns="columns_investment" :data-source="baseinfo.company_invest" :pagination="pagination">
          </a-table>
        </div>
        <!-- 融资经历 -->

        <div class="company_con1" id="financing">
          <h2 class="title">融资经历</h2>
          <a-table :columns="columns_financing" :data-source="baseinfo.company_finance" :pagination="pagination">
          </a-table>
        </div>
      </div>
      <div class="company_con_anchor">
        <a-anchor @click="handleAnchorClick">
          <a-anchor-link style="font-size: 20px; color: #1c1c28; font-weight: bold" title="企业概况" />
          <a-anchor-link href="#businessInfo" title="工商信息" />
          <a-anchor-link href="#shareholder" title="股东信息" />
          <!-- <a-anchor-link href="#appAera" title="应用领域" /> -->
          <a-anchor-link href="#keyPerson" title="主要人员" />
          <a-anchor-link href="#investment" title="对外投资" />
          <a-anchor-link href="#financing" title="融资经历" />
        </a-anchor>

        <div id="components-back-top-demo-custom">
          <a-back-top>
            <div class="ant-back-top-inner">
              <img src="@/static/common/icon-top.png" alt="" />
              回到顶部
            </div>
          </a-back-top>
          <!-- <div class="back">回到顶部</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyProfile",
  props: {
    baseinfo: Object,
  },
  data() {
    return {
      activeKey_shareholder: "工商登记",
      columns_shareholder: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
        },
        {
          title: "股东（发起人）",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "持股比例",
          dataIndex: "percent",
          key: "percent",
        },
        {
          title: "最终受益股份",
          dataIndex: "final_benefit_shares",
          key: "final_benefit_shares",
        },
        {
          title: "认缴出资额",
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: "认缴出资日期",
          dataIndex: "capital_date",
          key: "capital_date",
        },
      ],
      data_shareholder: [],
      history_shareholder: [],
      columns_keyperson: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
        },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "职位",
          dataIndex: "post",
          key: "post",
        },
        {
          title: "持股比例",
          dataIndex: "address",
          key: "address 2",
        },
        {
          title: "最终收益股份",
          dataIndex: "address",
          key: "address 3",
        },
      ],
      columns_investment: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
        },
        {
          title: "被投资企业名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "法定代表人/执行事务合伙人",
          dataIndex: "legal",
          key: "legal",
        },
        {
          title: "成立日期",
          dataIndex: "establish_date",
          key: "establish_date",
        },
        {
          title: "投资数额",
          dataIndex: "investment_amount",
          key: "investment_amount",
        },
        {
          title: "投资比例",
          dataIndex: "investment_ratio",
          key: "investment_ratio",
        },
        {
          title: "经营状态",
          dataIndex: "status",
          key: "status",
        },
      ],
      columns_financing: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
        },
        {
          title: "披露日期",
          dataIndex: "pub_date",
          key: "pub_date",
        },
        {
          title: "交易金额",
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: "融资轮次",
          dataIndex: "round",
          key: "round",
        },
        {
          title: "投资方",
          dataIndex: "investor",
          key: "investor",
          width: 240,
        },
      ],
      pagination: {
        pageSize: 5,
        showSizeChanger: false,
      },
    };
  },
  methods: {
    handleAnchorClick(e, link) {
      // 阻止点击的默认事件修改路由
      e.preventDefault();
    },
  },
};
</script>

<style lang="less" scoped>
.company_con {
  clear: both;
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: #f3f3f6;
  width: 1240px;
  .company_con_left {
    .company_con1 {
      // width: 1400px;
      // height: 175px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 40px 30px;
      margin: 15px 0;
      .title {
        color: #1c1c28;
        font-size: 18px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;

        line-height: 28px;
      }
      .desc {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #1c1c28;
        line-height: 28px;
      }
    }
    :deep(.ant-table-thead > tr > th) {
      background-color: #f2f9fc;
    }
  }
  .company_con_anchor {
    margin-top: 15px;
    font-weight: bold;
    position: relative;
    width: 180px;
    color: #1c1c28;
    :deep(.ant-anchor-link-title) {
      color: #1c1c28;
      margin-bottom: 15px;
    }
    :deep(.ant-anchor-link-active > .ant-anchor-link-title) {
      color: #1890ff;
    }
    :deep(.ant-anchor-ink::before) {
      width: 8px;
      background-color: #f5f5f5;
    }

    :deep(.ant-anchor-wrapper) {
      background-color: #fff;
      padding-left: 10px;
      color: #1c1c28;
      height: 350px;
      margin-left: 10px;
      padding: 30px 15px 0;
    }
    #components-back-top-demo-custom {
    }
    #components-back-top-demo-custom .ant-back-top {
      bottom: 55%;
      // right: 12%;
      right: calc(100vw - 1250px);
      // right: 380px;
      // top: 350px;
    }
    #components-back-top-demo-custom .ant-back-top-inner {
      margin-top: 20px;
      width: 180px;
      height: 60px;
      background-color: #fff;
      text-align: center;
      line-height: 60px;
      border-radius: 8px;
    }
  }
  :deep(.ant-descriptions-bordered .ant-descriptions-item-label) {
    padding: 1rem 0.5rem;
  }
  :deep(.ant-descriptions-bordered .ant-descriptions-item-content) {
    padding: 1rem 0.5rem;
  }
}
</style>
