import { get, post,put } from './request'
//专利搜索接口
export const patents = p => get('/api/patent/patents?keyword='+p.keyword+'&page='+p.page+'&size='+p.size);
//专利筛选接口
export const patentselect = p => post('/api/patent/patents',p);
//专利详情页面
export const patentDetail = p => get('/api/patent/patentDetail/'+p.id);
//导出word给后端传递base64
export const exportword = p => post('/api/users/enterprisePatentExport',p);
//专利智能推荐
export const Recommend = p => post('/api/patent/intelligentRecommendation/'+p.id,p);
// 企业搜索接口
export const enterpriseList = p => get('/api/enterprise/list?keyword='+p.keyword+'&page='+p.page+'&size='+p.size);
//企业国民经济行业接口
export const industryType = p => get('/api/common/industryType');
//企业筛选器接口
export const enterpriseSelect = p => post('/api/enterprise/list',p);
//企业详情
export const enterpriseDetail = p => get('/api/enterprise/detail/'+p.id);
//企业详情-》研发概要 研发规模
export const researchDevelop = p=>get('/api/enterprise/researchDevelop/'+p.id)
//企业详情-》研发概要 研发可持续性
export const sustainability = p=>get('/api/enterprise/sustainability/'+p.id)
//企业详情-》研发概要 专利结构分析
export const structure = p=>get('/api/enterprise/structure/'+p.id)
//企业详情-技术合作  联合申请
export const jointApplication = p=>get('/api/enterprise/jointApplication/'+p.id)
//企业详情-技术合作  专利购买
export const patentPurchase = p=>get('/api/enterprise/patentPurchase/'+p.id)
//首页-企业技术合作榜单
export const technicalCooperation = p =>get('/api/enterprise/technicalCooperation',{})
//首页-企业科创能力榜单
export const scientificEvaluationTop = p =>get('/api/enterprise/scientificEvaluationTop',{})
//企业详情 科创能力评价
export const scientificEvaluation = p =>get('/api/enterprise/scientificEvaluation/'+p.id)
//企业搜索联想
export const enterpriseLenovo = p=>get('/api/enterprise/enterpriseLenovo?keyword='+p.keyword)
//专利搜索联想
export const patentLenovo = p=>get('/api/patent/patentLenovo?keyword='+p.keyword)
//导出excel
export const patentExport = p => post('/api/patent/patentExport',p);











