<template>
  <div class="history">
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item
        ><router-link :to="'/home'">首页</router-link></a-breadcrumb-item
      >
      <a-breadcrumb-item>我的关注</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- 主体 -->
    <div class="history_con">
      <a-table
        :style="'width:90%'"
        :columns="columnshistory"
        :data-source="dataHistory"
        :pagination="false"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex == 'action'">
            <a-button type="primary" danger @click="openModal(record)"
              >取消关注</a-button
            >
          </template>
          <template v-if="column.dataIndex == 'name'">
            <span @click="rowClick(record)">{{ text }}</span>
          </template>
          <template v-if="column.dataIndex == 'f_type'">
            {{ text == "patent" ? "专利" : "公司" }}
          </template>
        </template>
      </a-table>
      <br />
      <a-pagination
        @change="onChange"
        :total="total"
        v-model:current="currentPage"
        :showSizeChanger="false"
      />
    </div>
    <!-- <a-modal
      centered
      style="text-align: center"
      v-model:visible="visible"
      title="提示信息"
      @ok="removeFollow"
      cancelText="取消"
      okText="确定"
      @onCancel="closeModal"
    >
      <p>是否确定从当前分组中删除该企业？</p>
    </a-modal> -->
  </div>
</template>
<script>
import { message, Modal } from "ant-design-vue";
import { followList, cancelFollow } from "../../utils/userAPI";
export default {
  name: "follow",
  data() {
    return {
      total: 0,
      visible: false,
      currentPage: 1,
      columnshistory: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 100,
        },
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          width: 300,
        },
        {
          title: "类型",
          dataIndex: "f_type",
          key: "f_type",
          width: 200,
        },

        {
          title: "添加时间",
          dataIndex: "follow_time",
          key: "follow_time",
          width: 200,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 200,
        },
      ],
      dataHistory: [],
    };
  },
  components: {},
  methods: {
    //初始化
    init() {
      followList({
        page: this.currentPage,
        size: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.dataHistory = res.data;
          this.total = res.total;
          if (this.dataHistory.length > 0) {
            this.dataHistory.forEach((item, index) => {
              item.index = (this.currentPage - 1) * 10 + index + 1;
              item.follow_time = item.follow_time.replace(/T/g, " ");
            });
          }
        } else {
          message.error(res.msg);
        }
      });
    },
    //跳转到专利详情或者公司详情页面
    rowClick(record) {
      if (record.f_type == "patent") {
        this.$router.push({
          path: "/patentDetails",
          query: {
            id: record.ent_or_pat_id,
            // zlcode: record.gkh,
            zlcode: record.name,
          },
        });
      } else {
        this.$router.push({
          path: "/companyDetails",
          query: {
            id: record.ent_or_pat_id,
            name: record.name,
          },
        });
      }
    },
    //改变页码
    onChange() {
      this.init();
    },
    //取消关注
    async removeFollow(item) {
      this.visible = false;
      let cancelRes = await cancelFollow({
        f_type: item.f_type,
        ent_or_pat_id: item.ent_or_pat_id,
      });
      if (cancelRes.code == 0) {
       this.onChange()
        message.success(cancelRes.msg);
      } else {
        message.error(cancelRes.msg);
      }
    },
    //打开弹窗
    openModal(record) {
      let that = this;
      this.visible = true;
      Modal.confirm({
        content: "是否确定从当前分组中删除该企业？",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          that.removeFollow(record);
        },
        onCancel() {
          that.visible = false;
        },
      });
    },
    //关闭弹窗
    closeModal() {
      this.visible = false;
    },
  },
  created() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.history {
  padding: 0 30px;

  width: calc(100%);
  min-height: 900px;
  background-color: #ebebeb;
  position: relative;
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .history_con {
    background-color: #fff;
    padding: 20px;
    height: 92%;

    text-align: center;
  }
  :deep(.ant-modal-footer) {
    text-align: center !important;
  }
  :deep(.ant-table-tbody) {
    cursor: pointer;
  }
}
</style>
