<template>
  <!-- 头部搜索关键词字段 -->
  <div class="header_con">
    <a-input
      v-model:value="keyword"
      :bordered="false"
      placeholder="请输入专利标题、专利号、发明人等关键词"
      allowClear
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @pressEnter="onsearch"
    >
      <template #addonBefore>
        <a-select v-model:value="type" class="type">
          <a-select-option value="企业">查企业</a-select-option>
          <a-select-option value="专利">查专利</a-select-option>
        </a-select>
      </template>
      <template #addonAfter>
        <div @click="onsearch">
          <img style="padding-right: 5px" src="@/static/home/index/icon-search.png" alt="" />搜索
        </div>
      </template>
    </a-input>
    <!-- 联想搜索 -->
    <div class="search_result" v-show="search_result_show">
      <div class="search_result_item" v-for="(item, index) in search_result" :key="item">
        <div style="position: relative">
          <div
            v-if="patent_cate == 'title'"
            style="cursor: pointer; width: 80%"
            v-html="item.title"
            @click="lenovnoPatentDetails(item)"
          ></div>
          <div
            v-else-if="patent_cate == 'fmr'"
            style="cursor: pointer; width: 80%"
            v-html="item.gkh"
            @click="lenovnoPatentDetails(item)"
          ></div>
          <div v-else style="cursor: pointer" v-html="item.gkh" @click="lenovnoPatentDetails(item)"></div>
          <a-tag class="cate" color="blue" v-if="patent_cate == 'title'">专利名称</a-tag>
          <a-tag class="cate" color="blue" v-else-if="patent_cate == 'fmr'">发明人</a-tag>
          <a-tag class="cate" color="blue" v-else>专利号</a-tag>
        </div>
      </div>
    </div>
  </div>
  <div class="patent">
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item><router-link :to="'/home'">首页</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>专利</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="patent_con">
      <div class="patent_con_left">
        <h3>筛选器</h3>
        <a-form style="height: 78%" :layout="'vertical'" :model="formState">
          <a-form-item label="专利类型">
            <a-select v-model:value="value1" mode="multiple" placeholder="请选择" :options="optionsType"></a-select>
          </a-form-item>
          <a-form-item name="range-picker" label="申请日">
            <a-config-provider :locale="zh_CN">
              <a-range-picker
                v-model:value="formState['rangetime1']"
                :disabledDate="disabledDate"
                value-format="YYYY-MM-DD"
              />
            </a-config-provider>
          </a-form-item>
          <a-form-item name="range-picker" label="公开日">
            <a-config-provider :locale="zh_CN">
              <a-range-picker
                :disabledDate="disabledDate"
                v-model:value="formState['rangetime2']"
                value-format="YYYY-MM-DD"
              />
            </a-config-provider>
          </a-form-item>
          <a-form-item label="专利状态">
            <a-select
              v-model:value="value2"
              mode="multiple"
              :token-separators="[',']"
              placeholder="请选择"
              :options="optionsStatus"
            ></a-select>
          </a-form-item>
        </a-form>

        <a-button type="default" size="large" class="btn" @click="clearvalue">重置</a-button>&nbsp;
        <a-button type="primary" size="large" class="btn" @click="Filter">筛选</a-button>
      </div>
      <!-- 专利表格结果 -->
      <div class="patent_con_right">
        <h2>
          推荐<span>{{ total ? total : 0 }}</span
          >条相关结果
          <!-- 筛选的标签展示 -->
          <div class="filter">
            <a-tag color="blue" v-for="(item, index) in filter" :key="index">
              {{ item }}
            </a-tag>
          </div>
          <div class="btn_group">
            <span style="cursor: pointer" @click="openModal"><img src="@/static/patent/icon-dc.png" alt="" />导出</span>
          </div>
          <!-- <span style="cursor: pointer" @click="openModal"><img src="@/static/patent/icon-dc.png" alt="" />导出</span> -->
        </h2>

        <a-table
          :columns="columns"
          :data-source="patentdata"
          :pagination="false"
          :customRow="rowClick"
          v-if="!search_word"
                  >
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'name'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'gkrq'">
              {{ text.substring(0, text.indexOf("T")) }}
            </template>
          </template>
        </a-table>
        <div style="height: 50%" v-else>
          <a-empty style="margin-top: 15%" :description="'您的搜索词太宽泛，建议更换一下搜索词'" />
        </div>
        <br />
        <a-pagination
          v-model:current="currentPage"
          @change="onChange"
          v-if="!search_word"
          :total="total"
          :showSizeChanger="false"
        />
        <br />
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      title="导出"
      @ok="patentExport"
      cancelText="取消"
      okText="确定"
      @onCancel="closeModal"
    >
      <p>导出当前数据</p>
      <a-form :model="formDialog" ref="form">
        <a-form-item
          :name="'email'"
          label="发送给"
          :rules="[
            { required: true, message: '请输入邮箱' },
            { type: 'email', message: '邮箱不合法' },
          ]"
        >
          <a-input v-model:value="formDialog.email" placeholder="请输入邮箱地址" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

  <router-view></router-view>
  <Loading v-if="loading"></Loading>
</template>
<script>
import { patents, patentselect, patentLenovo, patentExport } from "../../utils/indexApi";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { message } from "ant-design-vue";
import Loading from "../../components/LoadAnimation.vue";
dayjs.locale("zh-cn");

export default {
  name: "patent",
  data() {
    return {
      timer: null,
      zh_CN,
      search_result_show: false,
      search_result: [],
      type: "",
      currentPage: 1,
      value1: [],
      value2: [],
      keyword: "",
      optionsType: [
        { label: "发明授权", value: "发明授权" },
        { label: "发明申请", value: "发明申请" },
        { label: "外观设计", value: "外观设计" },
        { label: "实用新型", value: "实用新型" },
        { label: "其他", value: "其他" },
      ],
      optionsStatus: [
        { label: "有效", value: "有效" },
        { label: "审查中", value: "审查中" },
      ],
      formState: {
        // layout: "horizontal",
        // keyword: "",
        rangetime1: [],
        rangetime2: [],
      },
      scroll: { y: 800 },
      columns: [
        {
          title: "专利号",
          dataIndex: "gkh",
          key: "gkh",
          width: 140,
        },
        {
          title: "专利标题",
          dataIndex: "title",
          key: "title",
          ellipsis: true,
          width: 240,
        },
        {
          title: "申请人",
          dataIndex: "zlr",
          key: "zlr",
          ellipsis: true,
          width: 140,
        },
        {
          title: "发明人",
          dataIndex: "fmr",
          key: "fmr",
          ellipsis: true,
          width: 180,
        },
        {
          title: "公开日",
          dataIndex: "gkrq",
          key: "gkrq",
          ellipsis: true,
        },
        {
          title: "专利类型",
          dataIndex: "zl_type",
          key: "zl_type",
        },
        {
          title: "应用领域",
          dataIndex: "app_area",
          key: "app_area",
          ellipsis: true,
        },
      ],
      patentdata: [],
      filter: [],
      total: 0,
      search_word: false,
      visible: false,
      loading: false,
      formDialog: {
        email: "",
      },
    };
  },
  components: {
    Loading,
  },
  methods: {
    //导出
    openModal() {
      this.visible = true;
    },
    patentExport(){
      var that = this;
      that.visible = false;
      that.loading = true;
      patentExport({
            keywords: this.keyword == undefined?'':this.keyword,
            email: that.formDialog.email
          }).then((res) => {
            if (res.code == 0) {
              this.loading = false;
              message.success(res.msg);
            } else {
              this.loading = false;
              message.error(res.msg);
            }
          }).catch((err) => {
            console.log(err);
            this.loading = false;
            message.error(err);
        });
    },

    disabledDate(current) {
      return current && current > dayjs().endOf("day");
    },
    //获取页面的表格
    getList(keyword) {
      let params = {
        keyword: keyword || "",
        page: this.currentPage,
        size: 10,
      };
      patents({ ...params }).then((res) => {
        if (res.code == 0) {
          this.patentdata = res.data.list;
          this.search_word = res.data.search_word;
          this.total = res.data.total;
        } else {
          message.error(res.data.msg);
        }
      });
    },
    onsearch() {
      this.currentPage = 1;
      this.search_result_show = false;
      this.keyword ? (this.filter = [this.keyword]) : (this.filter = []);
      this.getList(this.keyword);
    },
    //改变页码
    onChange() {
      this.patentdata = [];
      let params = {
        page: this.currentPage,
        size: 10,
        keyword: this.keyword ? this.keyword : null,
        zl_type: this.value1,
        sqrq_start: this.formState.rangetime1 ? this.formState.rangetime1[0] : null,
        sqrq_end: this.formState.rangetime1 ? this.formState.rangetime1[1] : null,
        gkrq_start: this.formState.rangetime2 ? this.formState.rangetime2[0] : null,
        gkrq_end: this.formState.rangetime2 ? this.formState.rangetime2[1] : null,
        zl_status: this.value2,
      };
      patentselect({ ...params }).then((res) => {
        if (res.code == 0) {
          this.patentdata = res.data.list;
          this.search_word = res.data.search_word;
          this.total = res.data.total;
        }
      });
    },
    //重置筛选
    clearvalue() {
      this.value1 = [];
      this.value2 = [];
      this.keyword = "";
      this.formState = {
        // keyword: "",
        rangetime1: "",
        rangetime2: "",
      };
      this.filter = [];
      this.Filter();
    },
    //点击筛选
    Filter() {
      //搜索结果标签
      this.filter = [];
      //专利列表
      this.patentdata = [];
      let parma = {
        page: this.currentPage,
        size: 10,
        keyword: this.keyword ? this.keyword : null,
        zl_type: this.value1,
        sqrq_start: this.formState.rangetime1 ? this.formState.rangetime1[0] : null,
        sqrq_end: this.formState.rangetime1 ? this.formState.rangetime1[1] : null,
        gkrq_start: this.formState.rangetime2 ? this.formState.rangetime2[0] : null,
        gkrq_end: this.formState.rangetime2 ? this.formState.rangetime2[1] : null,
        zl_status: this.value2,
      };
      patentselect({ ...parma }).then((res) => {
        if (res.code == 0) {
          this.patentdata = res.data.list;
          this.total = res.data.total;
          //将筛选条件渲染到页面上
          for (var key in parma) {
            if (key == "keyword") {
              parma.keyword ? this.filter.push(parma.keyword) : "";
            } else if (key == "zl_type") {
              if (this.value1.length != 0) {
                this.value1.forEach((item) => {
                  this.filter.push(item);
                });
              }
            } else if (key == "zl_status") {
              if (this.value2.length != 0) {
                this.value2.forEach((item) => {
                  this.filter.push(item);
                });
              }
            }
          }
        } else {
          this.$message(res.msg);
        }
      });
    },
    //跳转到专利详情页面
    rowClick(record) {
      return {
        onClick: () => {
          //保留历史搜索记录
          let historysearch = {
            keyword: this.keyword,
            value1: this.value1,
            rangetime1: this.formState.rangetime1,
            rangetime2: this.formState.rangetime2,
            value2: this.value2,
          };
          sessionStorage.setItem("currentpagezl", this.currentPage);
          sessionStorage.setItem("searchzl", JSON.stringify(historysearch));
          this.$router.push({
            path: "/patentDetails",
            query: {
              id: record.mysql_id,
              zlcode: record.gkh,
              title: record.title,
            },
          });
        },
      };
    },
    //联想搜索
    onInput() {
      patentLenovo({ keyword: this.keyword }).then((res) => {
        if (res.code == 0) {
          this.search_result_show = true;
          this.patent_cate = res.data.title_or_gkh;
          this.search_result = res.data.list;
          if (this.search_result.length == 0) {
            this.search_result_show = false;
          }
        } else {
          this.search_result_show = false;
        }
      });
    },
    //
    onFocus() {
      if (this.keyword) {
        patentLenovo({ keyword: this.keyword }).then((res) => {
          if (res.code == 0) {
            this.search_result_show = true;
            this.patent_cate = res.data.title_or_gkh;
            this.search_result = res.data.list;
            if (this.search_result.length == 0) {
              this.search_result_show = false;
            }
          } else {
            this.search_result_show = false;
          }
        });
      }
    },
    //onBlur
    onBlur() {
      this.timer = setTimeout(() => {
        this.search_result_show = false;
      }, 150);
    },
    //从联想结果打开专利页面
    lenovnoPatentDetails(item) {
      this.$router.push({
        path: "/patentDetails",
        query: {
          id: item.mysql_id,
        },
      });
      // this.search_result_show = false;
    },
  },
  watch: {
    type: function (newV) {
      // console.log(newV)
      if (newV == "企业") {
        this.$router.push({
          path: "/company",
        });
      }
    },
  },
  created() {
    this.type = "专利";
    this.keyword = this.$route.query.keyword;
    this.keyword ? (this.filter = [this.keyword]) : (this.filter = []);
    if (sessionStorage.getItem("searchzl") == "") {
      this.getList(this.keyword);
    }

    //历史记录
    this.currentPage = sessionStorage.getItem("currentpagezl");
    if (sessionStorage.getItem("searchzl")) {
      let searchValue = JSON.parse(sessionStorage.getItem("searchzl"));
      this.value1 = searchValue.value1;
      this.formState.rangetime1 = searchValue.rangetime1;
      this.formState.rangetime2 = searchValue.rangetime2;
      this.value2 = searchValue.value2;
      this.keyword = searchValue.keyword;
      this.Filter();
    }

    // if (window.innerHeight >= 960) {
      //   this.scroll.y = 800;
    // } else if (window.innerHeight < 960 && window.innerHeight >= 800) {
      //   this.scroll.y = 480;
    // } else {
      //   this.scroll.y = 400;
    // }
  },
  beforeMount() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.header_con {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 35%;
  //  padding: 0 10px 30px;
  width: 700px;
  height: 50px;
  margin: 10px;
  // padding: 10px;
  border: 2px solid #4079ff;
  // border-radius: 8px;
  .type {
    width: 120px;
    height: 40px;
    // margin-top: 10px;
    // line-height: 50px;
    padding: 0px 0;
    // background-color: #fff;
    border-color: transparent;
  }
  :deep(.ant-input-group .ant-input) {
    // height: 54px;
    font-size: 18px;
  }
  :deep(.ant-input) {
    border-color: transparent;
    padding-top: 5px;
  }
  :deep(.ant-input-group-addon:first-child) {
    border-color: transparent;
    background-color: transparent;
    padding-top: 5px;
  }
  :deep(.ant-input-group-addon:last-child) {
    height: 48px;
    width: 120px;
    background-color: #4079ff !important;
    color: #fff;
    font-size: 18px;
    // border-radius: 8px;
    cursor: pointer;
    border: 0;
  }
  .search_result {
    position: absolute;
    width: calc(100% - 120px);
    // height: 300px;
    top: 54px;
    left: 0;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.05);
    .search_result_item {
      // height: 45px;
      padding: 15px 5px 15px 25px;
      font-family: "SF Pro Text";
      font-size: 16px;
    }
    .search_result_item:hover {
      background-color: #f5fbff;
    }
  }
}
.patent {
  padding: 0 30px;
  width: calc(100%);
  // height: calc(100% - 65px);
  min-height: 890px;
  background-color: #ebebeb;
  position: relative;
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .patent_con {
    background-color: #f3f3f6;
    width: 100%;
    height: calc(92%);
    display: flex;
    justify-content: space-between;
    .patent_con_left {
      width: 20%;
      // height: 90%;
      padding: 25px 20px;
      background-color: #fff;
      .btn {
        width: 100px;
        margin-top: -40px;
      }
    }
    .patent_con_right {
      background-color: #fff;
      width: 78%;
      // height: 90%;
      padding: 25px 20px;
      span {
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #4079ff;
      }
      .filter {
        font-size: 15px;
        display: inline-block;
        span {
          font-size: 15px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
        }
      }
      .btn_group {
      position: absolute;
      top: 80px;
      right: 60px;
      span {
        display: inline-block;
        width: 100px;
        height: 32px;
        background: #4079ff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        color: #fff;
        line-height: 32px ;
        text-align: center;
        margin-right: 15px;
        font-size: 15px;
        font-weight: 400;

        img {
          width: 17px;
          vertical-align: text-bottom;
          margin-right: 5px;

        }
      }
    }
    }
    
  }
}
:deep(.ant-table-tbody) {
  cursor: pointer;
}
.cate {
  right: 2%;
  position: absolute;
  top: 10%;
}
:deep(b) {
  color: rgba(191, 36, 40, 1);
}


</style>
