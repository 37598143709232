<template>
  <div style="background: #f3f3f3;">
    <div class="company_con">
      <div class="company_con_left">
        <!-- 科创能力分析 -->
        <div class="company_con1" id="Ability">
          <h2 class="title">科创能力分析</h2>
          <div style="display: flex; position: relative">
            <div style="width: 50%;">
                <EchartsCon
                :style="'height:300px;width:400px'"
              :option="option1"
              :id="'echartsraodar'"
              class="echarts"
            ></EchartsCon>
            </div>
          
            <div style="width: 50%; position: relative">
              <EchartsCon :option="option2" :id="'echartsscatter'"   :style="'height:300px;width:400px'"></EchartsCon>
              <a-popover title="科创能力分析指标">
                <template #content>
                  <p>
                    <div><strong>公司竞争力：</strong></div>
                    <div>从公司资质类别、公司规模、对外投资和公司融资分析</div>
                  </p>
                  <p>
                    <div><strong>技术质量：</strong></div>
                    <div>从有效专利占专利申请总量比例、有效发明专利占有效专利比例和风险专利占全部申请专利的比例分析</div>
                  </p>
                  <p>
                    <div><strong>技术体量：</strong></div>
                    <div>从专利申请总量、有效专利总量和近3年专利申请增速分析</div>
                  </p>
                </template>

                <div class="hover">指标说明</div>
              </a-popover>
            </div>
          </div>
        </div>
      </div>
      <div class="company_con_anchor">
        <a-anchor @click="handleAnchorClick">
          <a-anchor-link
            style="font-size: 20px; color: #1c1c28; font-weight: bold"
            title="科创能力评价"
          />
          <a-anchor-link href="#Ability" title="科创能力分析" />
        </a-anchor>

        <div id="components-back-top-demo-custom">
          <a-back-top>
            <div class="ant-back-top-inner">
              <img src="@/static/common/icon-top.png" alt="" />
              回到顶部
            </div>
          </a-back-top>
          <!-- <div class="back">回到顶部</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import EchartsCon from "../../components/EchartsCon";
export default {
  name: "technology",
  components: {
    EchartsCon,
  },
  data() {
    return {
      option1: null,
      option2: null,
      hover: false,
      con: "",
    };
  },
  props: {
    technologyData: Object,
    company_name:String,
  },
  beforeMount() {
    this.init()
  },
  created() {
    this.technology = this.$props.technologyData;
    this.name =  this.$props.company_name
   console.log()
  },
  methods: {
    init(){
      this.getOption1();
      this.getOption2();
    },
    handleAnchorClick(e,link){
      e.preventDefault();
    },
    getOption1() {
      this.option1 = {
        tooltip: {
          trigger: "item",
        },
        legend:{
          show:true,
          bottom:10
        },
        radar: [
          {
            indicator: [
              { name: "公司竞争力", max: 30 },
              { name: "技术质量", max: 30 },
              { name: "技术体量", max: 40 },
            ],
            center: ["50%", "50%"],

            radius: 120,
            triggerEvent: true,
          },
          {
            indicator: [
              { name: "公司竞争力", max: 30 },
              { name: "技术质量", max: 30 },
              { name: "技术体量", max: 40 },
            ],
            center: ["50%", "50%"],

            radius: 120,
          },
        ],
        series: [
          {
            type: "radar",
            radarIndex: 0,
            symbol: 'none',
            data: [
              {
                // value: [20, 10, 25],
                value:[this.technology.company_competitiveness_avg,this.technology.technical_quality_avg,this.technology.technical_volume_avg],
                name: this.technology.company_industry+'行业企业均值',
                areaStyle: {
                  color: "#F7B46A",
                },
                lineStyle: {
                  color: "#F7B46A",
                },
                itemStyle: {
                  color: "#F7B46A",
                },
              },
            ],
          },
          {
            type: "radar",
            radarIndex: 0,
            symbol: 'none',
            data: [
              {
                // value: [10, 15, 15],
                value:[this.technology.company_competitiveness,this.technology.technical_quality,this.technology.technical_volume],
                name: this.name,

                areaStyle: {
                  color: "#5A95F7",
                },
                lineStyle: {
                  color: "#5A95F7",
                },
                itemStyle: {
                  color: "#5A95F7",
                },
              },
            ],
          },
        ],
      };
    },
    getOption2() {
      this.option2 = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}",
        },
        series: [
          {
            name: "总分",
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            radius: "160",
            center: ["50%", "60%"],
            type: "gauge",
            progress: {
              show: true,
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}",
            },
            data: [
              {
                // value:50,
                value: this.technology.total_score,
                name: "科创能力总分值",
              },
            ],
          },
        ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.company_con {
  clear: both;
  // min-height: 900px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: #f3f3f6;
  width: 1240px;
  .company_con_left {
    .company_con1 {
      // width: 1400px;
      // height: 175px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 40px 30px;
      margin: 15px 0;
      box-sizing: border-box;
      
      .title {
        color: #1c1c28;
        font-size: 18px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;

        line-height: 28px;
      }
      .echarts {
        // position: relative;
      }
      .hover {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 5%;
        color: #1890ff;
      }
    }
  }
  .company_con_anchor {
    margin-top: 15px;
    font-weight: bold;
    color: #1c1c28;
    :deep(.ant-anchor-link-title) {
      color: #1c1c28;
      margin-bottom: 15px;
    }
    :deep(.ant-anchor-link-active > .ant-anchor-link-title) {
      color: #1890ff;
    }
    :deep(.ant-anchor-ink::before) {
      width: 8px;
      background-color: #f5f5f5;
    }

    :deep(.ant-anchor-wrapper) {
      background-color: #fff;
      padding-left: 10px;
      color: #1c1c28;
      height: 350px;
      margin-left: 10px;
      padding: 30px 15px 0;
    }
    #components-back-top-demo-custom .ant-back-top {
      // bottom: 50%;
      // right: 18.5%;
      bottom: 55%;
      right: 12%;
    }
    #components-back-top-demo-custom .ant-back-top-inner {
      margin-top: 20px;
      width: 150px;
      height: 60px;
      background-color: #fff;
      text-align: center;
      line-height: 60px;
      border-radius: 8px;
    }
  }
  .back {
    display: none;
    margin-top: 20px;
    width: 150px;
    height: 60px;
    background-color: #fff;
    text-align: center;
    line-height: 60px;
  }
}
</style>
