<template>
  <div class="history">
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item><router-link :to="'/home'">首页</router-link></a-breadcrumb-item>
      <!-- <a-breadcrumb-item>历史记录</a-breadcrumb-item> -->
      <a-breadcrumb-item v-if="activeKey == 1">浏览记录</a-breadcrumb-item>
      <a-breadcrumb-item v-else>导出记录</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- 主体 -->
    <div class="history_con">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="浏览记录">
          <p style="text-align: left">系统会为您保留 30 天的浏览记录</p>
          <a-table :columns="columnshistory" :data-source="dataHistory" :pagination="false" :style="'width:90%;'">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex == 'f_type'">
                {{ text == "patent" ? "专利" : "公司" }}
              </template>
              <template v-if="column.dataIndex == 'name'">
                <span @click="rowClick(record)">{{ text }}</span>
              </template>
            </template>
          </a-table>
          <a-pagination
            v-model:current="currentPage"
            @change="onChange"
            :total="total0"
            :showSizeChanger="false"
            class="h-pagination"
          />
        </a-tab-pane>
        <a-tab-pane key="2" tab="导出记录">
          <p style="text-align: left">系统会为您保留 30 天的导出记录</p>
          <a-table :columns="columnout" :data-source="dataExport" :pagination="false" :style="'width:90%'">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex == 'action'">
                <a-button @click="exportPDF(record)" style="background-color: #fa7f03; color: #fff; margin-right: 10%">
                  导出
                </a-button>
                <a-button type="primary" @click="sendMail(record)">发送到邮箱</a-button>
              </template>
              <template v-if="column.dataIndex == 'f_type'">
                {{ text == "patent" ? "专利" : "公司" }}
              </template>
              <template v-if="column.dataIndex == 'name'">
                <span @click="rowClick(record)">{{ text }}</span>
              </template>
            </template>
          </a-table>
          <a-pagination
            class="h-pagination"
            @change="onChange"
            :total="total1"
            v-model:current="currentPage"
            :showSizeChanger="false"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- <a-modal
      v-model:visible="visible"
      title="发送邮箱"
      @ok="sendMail"
      cancelText="取消"
      okText="确定"
      @onCancel="closeModal"
    >
      <a-form :model="formState" :validate-messages="validateMessages">
        <a-form-item
          :name="'email'"
          label="发送给"
          :rules="[{ type: 'email' }]"
        >
          <a-input v-model:value="formState.email" />
        </a-form-item>
      </a-form>
    </a-modal> -->
  </div>
</template>
<script>
import { message } from "ant-design-vue";
import { userBrowse, ExportList, sendEmail } from "../../utils/userAPI";
export default {
  name: "history",
  data() {
    return {
      visible: false,
      currentPage: 1,
      validateMessages: {
        required: "请输入邮箱",
        types: {
          email: "请输入正确的邮箱地址",
        },
      },
      formState: {
        email: "",
      },

      activeKey: "1",
      columnshistory: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 80,
        },
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          width: 200,
        },
        {
          title: "类型",
          dataIndex: "f_type",
          key: "f_type",
          width: 100,
        },
        {
          title: "时间",
          dataIndex: "browse_date",
          key: "browse_date",
          width: 200,
        },
      ],
      columnout: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 100,
        },
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "类型",
          dataIndex: "f_type",
          key: "f_type",
          width: 100,
        },
        {
          title: "接受邮箱",
          dataIndex: "email",
          key: "email",
          width: 200,
        },
        {
          title: "时间",
          dataIndex: "export_time",
          key: "export_time",
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "address 3",
        },
      ],
      dataHistory: [],
      dataExport: [],
    };
  },
  components: {},
  methods: {
    //跳转到专利详情或者公司详情页面
    rowClick(record) {
      if (record.f_type == "patent") {
        this.$router.push({
          path: "/patentDetails",
          query: {
            id: record.ent_or_pat_id,
            // zlcode: record.gkh,
            zlcode: record.name,
          },
        });
      } else {
        this.$router.push({
          path: "/companyDetails",
          query: {
            id: record.ent_or_pat_id,
            name: record.name,
          },
        });
      }
    },
    //发送到邮箱
    async sendMail(record) {
      // this.visible = false;
      let res = await sendEmail({
        email: record.email,
        name: record.name,
        url: record.url,
      });
      if (res.code == 200) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    },
    //打开弹窗
    openModal() {
      this.visible = true;
    },
    //关闭弹窗
    closeModal() {
      this.visible = false;
    },
    //浏览记录翻页
    onChange() {
      this.init();
    },
    init() {
      userBrowse({
        page: this.currentPage,
        size: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.dataHistory = res.data.data;
          this.total0 = res.data.total;
          if (this.dataHistory.length > 0) {
            this.dataHistory.forEach((item, index) => {
              item.index = (this.currentPage - 1) * 10 + index + 1;
            });
          }
        } else {
          message.error(res.msg);
        }
      });
      ExportList({
        page: this.currentPage,
        size: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.dataExport = res.data.data;
          this.total1 = res.data.total;
          if (this.dataExport.length > 0) {
            this.dataExport.forEach((item, index) => {
              item.index = (this.currentPage - 1) * 10 + index + 1;
            });
          }
        } else {
          message.error(res.msg);
        }
      });
    },
    //导出pdf
    exportPDF(record) {
      window.open(record.url);
    },
  },
  created() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.history {
  padding: 0 30px;
  width: calc(100%);
  min-height: 900px;
  background-color: #ebebeb;
  position: relative;
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .history_con {
    background-color: #fff;
    padding: 20px;
    height: 92%;
    text-align: center;
    // height: 600px;
    .h-pagination {
      margin-top: 20px;
    }
  }
  :deep(.ant-table-tbody) {
    cursor: pointer;
  }
}
</style>
