<template>
  <div class="personal_con">
    <!-- 面包屑导航 -->
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item>用户设置</a-breadcrumb-item>
      <a-breadcrumb-item>个人信息</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="table">
      <a-button type="primary" size="large" @click="openModal">
        <img src="../../static/login/icon-add.png" alt="" class="img" />
        新增 </a-button
      >&nbsp;
      <a-button type="primary" size="large" danger @click="opendeleteModal">
        <img src="../../static/login/icon-delete.png" alt="" class="img" />
        删除</a-button
      >&nbsp;
      <a-button type="primary" size="large" @click="downLoad">
        <!-- <img src="../../static/login/icon-add.png" alt="" class="img" /> -->
        批量添加账号模板 </a-button
      >&nbsp;
      <a-upload
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        v-model:file-list="fileList"
        name="file"
        :show-upload-list="false"
        :customRequest="imageUpload"
      >
        <a-button type="primary" size="large"> 上传账号文件 </a-button>
      </a-upload>

      <br /><br />
      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="data"
        :pagination="false"
       
        :rowKey="
          (row) => {
            return row.id;
          }
        "
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'status'">
            <div v-if="text" class="status">
              <span class="marker"></span>
              已启用
            </div>
            <div v-else class="status">
              <span class="marker disabled"></span>
              已禁用
            </div>
          </template>
          <template v-if="column.dataIndex === 'action'">
            <!-- <a @click="change(record)" danger v-if="text">禁用</a>
            <a v-else>使用</a> -->
            <a-switch
              @click="change(record)"
              v-model:checked="record.status"
            ></a-switch>
          </template>
        </template>
      </a-table>
      <br />
      <a-pagination
        @change="changePage"
        :total="total"
        v-model:current="currentPage"
        :showSizeChanger="false"
      />
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    title="新增账号"
    @ok="add"
    centered
    cancelText="取消"
    okText="确定"
    @onCancel="closeModal"
  >
    <label for="username">账号名：</label>
    <a-input style="width: 60%" id="username" v-model:value="username" />
  </a-modal>
  <a-modal
    v-model:visible="visibleDelete"
    title="提示信息"
    centered
    @ok="deleteUser"
    cancelText="取消"
    okText="确定"
    @onCancel="closeModal"
  >
    <p>确定删除以下用户吗？</p>
  </a-modal>
</template>
<script>
// import { AES_Encrypt, AES_Decrypt } from "@/utils/aes_util.js";
import { message } from "ant-design-vue";
import {
  users,
  changeStatus,
  addusers,
  deleusers,
  batchAdd,
} from "@/utils/loginAPI";
export default {
  name: "userInfo",
  data() {
    return {
      visible: false,
      username: "",
      fileList: [],
      visibleDelete: false,
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          align: "center",
        },
        {
          title: "姓名",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "手机号",
          dataIndex: "phone",
          align: "center",
        },
        {
          title: "账号名",
          dataIndex: "account_number",
          align: "center",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          align: "center",
        },
        {
          title: "状态",
          dataIndex: "status",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "action",
          align: "center",
        },
      ],
      selectedRowKeys: [],
      data: [],
      checked: true,
      currentPage: 1,
    };
  },
  created() {
    this.init();
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        // 这里不能加括号
        onChange: this.selectedTableRows,
        // 内置的禁止选中
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.isInner,
          },
        }),
      };
    },
  },

  mounted() {},
  methods: {
    imageUpload(file) {
      let data = new FormData();
      data.append("file", file.file);
      batchAdd(data).then((res) => {
        if (res.code == 0) {
          message.success(res.msg);

          //  console.log( this.img)
        } else {
          message.error(res.msg);
        }
      });
    },
    //调用删除用户接口
    deleteUser() {
      this.visibleDelete = false;
    },
    //下载模板
    downLoad() {
      window.location.href =
        "https://zlcgzyzhzntjxt.industrychain.online/templates/批量添加账号.xlsx";
    },
    //上传文件
    uploadFile() {},
    //打开删除用户弹窗
    opendeleteModal() {
      this.visibleDelete = true;
    },
    //打开新增用户弹窗
    openModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
      this.visibleDelete = false;
    },
    add() {
      addusers({ account_number: this.username }).then((res) => {
        if (res.code == 0) {
          this.getList();
          this.visible = false;
          message.success(res.msg);
        } else {
          message.error(res.msg);
        }
      });
    },
    //用户列表初始化
    init() {
      this.getList();
    },
    //获取用户列表
    getList() {
      users({
        page: this.currentPage,
        size: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.data = res.data;
          this.data.forEach((item, index) => {
            item.index = (this.currentPage - 1) * 10 + index + 1;
          });
          this.total = res.total
        } else {
          message.error(res.msg);
        }
      });
    },
    changePage(){
      this.getList();
    },
    // 勾选表格复选框
    selectedTableRows(selectedRowKeys, selectedRows) {
      // 批量选中的key
      this.selectedRowKeys = selectedRowKeys;
    },
    //删除用户
    deleteUser() {
      let parma = {
        user_ids: [...this.selectedRowKeys],
      };
      deleusers(parma).then((res) => {
        // console.log(res)
        if (res.code == 0) {
          message.success(res.msg);
          this.visibleDelete = false;
          this.getList();
        } else {
          message.error(res.msg);
          this.visibleDelete = false;
        }
      });
    },

    //修改用户状态
    change(record) {
      let status = record.status;
      changeStatus({ id: record.id, status: status }).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          message.success(res.msg);
          this.getList();
        } else {
          message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.personal_con {
  background-color: #ebebeb;
  padding-left: 30px;
  width: calc(100% - 80px);
  // text-align: center;
  flex: 1;
  .table{
    height: 90%;
    background-color: #fff;
    padding: 15px 25px;
  }
  .img {
    width: 15px;
    height: 15px;
    padding-right: 3px;
  }
  .marker {
    width: 12px;
    height: 12px;
    background-color: #00b42a;
    border-radius: 50%;
    display: inline-block;
  }
  .disabled {
    background-color: #ccc;
  }

  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  :deep(.ant-switch-checked) {
    background-color: #00b42a;
  }
}
</style>
