<template>
  <!-- 头部搜索关键词字段 -->
  <div class="header_con">
    <a-input
      v-model:value="keyword"
      :bordered="false"
      placeholder="请输入企业名称、法定代表人等关键词"
      allowClear
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      @pressEnter="onsearch"
    >
      <template #addonBefore>
        <a-select v-model:value="type" class="type">
          <a-select-option value="企业">查企业</a-select-option>
          <a-select-option value="专利">查专利</a-select-option>
        </a-select>
      </template>
      <template #addonAfter>
        <div @click="onsearch">
          <img style="padding-right: 5px" src="@/static/home/index/icon-search.png" alt="" />搜索
        </div>
      </template>
    </a-input>
    <!-- 联想搜索 -->
    <div class="search_result" v-show="search_result_show">
      <div class="search_result_item" v-for="(item, index) in search_result" :key="item">
        <div style="position: relative">
          <div
            v-if="company_cate == 'company_name'"
            style="cursor: pointer"
            v-html="item.company_name"
            @click="lenovnoCompanyDetails(item)"
          ></div>
          <div v-else style="cursor: pointer" v-html="item.company_name" @click="lenovnoCompanyDetails(item)"></div>
          <a-tag class="cate" color="blue" v-if="company_cate == 'company_name'">公司名称</a-tag>
          <a-tag class="cate" color="blue" v-else>法人信息匹配</a-tag>
        </div>
      </div>
    </div>
  </div>
  <div class="company">
    <a-breadcrumb class="nav_bread">
      <a-breadcrumb-item><router-link :to="'/home'">首页</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>企业</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="company_con">
      <div class="company_con_left">
        <h3>筛选器</h3>
        <a-form :layout="'vertical'" :model="formState">
          <a-form-item label="区域">
            <a-cascader v-model:value="valueArea" multiple :options="optionsArea" placeholder="请选择"></a-cascader>
          </a-form-item>
          <a-form-item label="国民经济行业">
            <a-cascader
              v-model:value="industryvalue"
              multiple
              :options="optionsindustry"
              placeholder="请选择"
            ></a-cascader>
          </a-form-item>
          <a-form-item label="科技型企业">
            <a-select v-model:value="valueType" :options="optionsType" mode="multiple" placeholder="请选择"></a-select>
          </a-form-item>
          <a-form-item label="注册时间">
            <a-config-provider :locale="zh_CN">
              <a-range-picker
                v-model:value="formState['rangetime1']"
                :disabledDate="disabledDate"
                value-format="YYYY-MM-DD"
              />
            </a-config-provider>
          </a-form-item>
          <a-row><label for="">注册资本（人民币）</label></a-row>
          <a-row>
            <a-col :span="11">
              <a-form-item name="capital_nums_min">
                <a-input
                  v-model:value="formState.capital_nums_min"
                  style="width: 80%"
                  placeholder="请输入数值"
                ></a-input
                >&nbsp;万
              </a-form-item>
            </a-col>
            <a-col :span="1">-</a-col>
            <a-col :span="11">
              <a-form-item name="capital_nums_max">
                <a-input
                  v-model:value="formState.capital_nums_max"
                  style="width: 80%"
                  placeholder="请输入数值"
                ></a-input
                >&nbsp;万
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item label="企业创新排名">
            <a-select
              v-model:value="valueInnovation"
              placeholder="请选择"
              :options="optionsInnovation"
              allowClear
            ></a-select>
          </a-form-item>
        </a-form>
        <a-button type="default" size="large" style="width: 100px" @click="clearvalue">重置</a-button>&nbsp;
        <a-button type="primary" size="large" style="width: 100px" @click="Filter">筛选</a-button>
      </div>
      <!-- 公司列表结果 -->
      <div class="company_con_right">
        <h2>
          推荐<span class="result_count">{{ total ? total : 0 }}</span
          >条相关结果
          <!-- 筛选的标签展示 -->
          <div class="filter">
            <a-tag class="tags" color="blue" v-for="item in filter">
              {{ item }}
            </a-tag>
          </div>
        </h2>
        <div style="display: flex; justify-content: flex-start; flex-wrap: wrap">
          <div class="card" v-for="item in companylist" :key="item">
            <div class="card_title">
              <span @click="openCompany(item.mysql_id, item.company_name)"> {{ item.company_name }}</span>
              <!-- 关注 -->
              <div class="follow" @click="followFun(item.mysql_id, item.company_name)" v-if="!item.follow">
                <img src="@/static/company/icon-guanzhu.png" alt="" class="follow_img" />
                <span class="follow_str">关注</span>
              </div>
              <div class="follow" @click="cancelFun(item.mysql_id)" v-else>
                <img src="@/static/company/icon-yiguanzhu.png" alt="" class="follow_img" />
                <span class="follow_str">已关注</span>
              </div>
            </div>

            <a-tag color="#56CA95">{{ item.status }}</a-tag>
            <a-tag color="#6096E6" v-if="item.quoted_company == 1">{{ "上市企业" }}</a-tag>

            <a-tag color="#6096E6" v-if="item.high_new == 1">{{ "高新技术企业" }}</a-tag>
            <a-tag color="#6096E6" v-if="item.zjtg_sjxjr == 1">{{ "专精特新小巨人" }}</a-tag>
            <a-tag color="#6096E6" v-if="item.zjtg == 1">{{ "专精特新中小企业" }}</a-tag>
            <a-divider />
            <div class="card_desc">
              <a-descriptions layout="vertical">
                <a-descriptions-item label="法定代表人">{{ item.legal }}</a-descriptions-item>
                <a-descriptions-item label="注册时间">{{ item.build_date }}</a-descriptions-item>
                <a-descriptions-item label="统一社会信用代码">{{ item.social_code }}</a-descriptions-item>
                <a-descriptions-item label="国民经济行业">
                  {{ item.company_industry_parent ? item.company_industry_parent + ">" : ""
                  }}{{ item.company_industry }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="注册地址">{{ item.address }}</a-descriptions-item>
              </a-descriptions>
            </div>
          </div>
        </div>
        <div style="height: 50%" v-if="search_word">
          <a-empty style="margin-top: 15%" :description="'您的搜索词太宽泛，建议更换一下搜索词'" />
        </div>
        <div style="height: 50%" v-if="total == 0">
          <a-empty style="margin-top: 15%" :description="'暂无数据'" />
        </div>
        <br />
        <a-pagination
          v-if="!search_word"
          v-model:current="currentPage"
          @change="onChange"
          :total="total"
          :showSizeChanger="false"
        />
        <br />
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>
<script>
import dayjs from "dayjs";
import arealist from "../../utils/map.js";
import { userFollow, cancelFollow } from "../../utils/userAPI";
import { enterpriseList, industryType, enterpriseSelect, enterpriseLenovo } from "../../utils/indexApi.js";
import "dayjs/locale/zh-cn";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { message } from "ant-design-vue";
dayjs.locale("zh-cn");
export default {
  name: "company",
  data() {
    return {
      timer: null,
      search_result_show: false,
      zh_CN,
      search_result: [],
      total: 0,
      currentPage: 1,
      keyword: "",
      type: "",
      valueArea: [],
      optionsArea: [],
      valueInnovation: "",
      optionsInnovation: [
        { label: "企业科创能力", value: "企业科创能力" },
        { label: "企业技术合作", value: "企业技术合作" },
      ],
      optionsType: [
        { label: "上市挂牌企业", value: "上市挂牌企业" },
        { label: "高新技术企业", value: "高新技术企业" },
        { label: "专精特新小巨人", value: "专精特新小巨人" },
        { label: "专精特新中小企业", value: "专精特新中小企业" },
      ],
      optionsindustry: [],
      industryvalue: [],
      valueType: [],
      formState: {
        capital_nums_min: "",
        capital_nums_max: "",
        rangetime1: [],
      },
      filter: [],
      companylist: [],
      search_word: false,
    };
  },
  components: {},
  methods: {
    //取消关注
    async cancelFun(id) {
      let cancelRes = await cancelFollow({
        f_type: "enterprise",
        ent_or_pat_id: id,
      });
      if (cancelRes.code == 0) {
        this.onChange(this.currentPage);
        message.success(cancelRes.msg);
      } else {
        message.error(cancelRes.msg);
      }
    },
    //关注接口
    async followFun(id, company_name) {
      let Follow = await userFollow({
        f_type: "enterprise",
        ent_or_pat_id: id,
        name: company_name,
      });
      if (Follow.code == 0) {
        message.success(Follow.msg);

        this.onChange(this.currentPage);
      } else {
        message.error(Follow.msg);
      }
      // this.Filter()
    },
    disabledDate(current) {
      return current && current > dayjs().endOf("day");
    },
    //获取企业列表
    getCompanyList(keyword) {
      let params = {
        keyword: keyword || "",
        page: 1,
        size: 10,
      };
      enterpriseList({ ...params }).then((res) => {
        if (res.code == 0) {
          this.companylist = res.data.list;
          this.search_word = res.data.search_word;
          this.total = res.data.total;
        } else {
        }
      });
    },
    //获取国民经济行业
    getindustryType() {
      industryType({}).then((res) => {
        // console.log(res)
        this.optionsindustry = res.data;
        this.optionsindustry.forEach((item) => {
          item.value = item.name;
          item.label = item.name;
          if (item.children && item.children.length != 0) {
            item.children.forEach((it) => {
              it.value = it.name;
              it.label = it.name;
            });
          }
        });
      });
    },
    //改变页码
    onChange(page) {
      window.scrollTo(0, 0);
      //获取当前页码数
      this.currentPage = page;
      //修改省份的格式
      let area = [];
      if (this.valueArea.length != 0) {
        this.valueArea.forEach((item) => {
          if (item.length == 1) {
            area.push(item[0]);
          } else if (item.length == 2) {
            area.push(item[1]);
          }
        });
      }

      //修改国民经济行业的格式
      let company_industry = [];
      if (this.industryvalue.length != 0) {
        this.industryvalue.forEach((item) => {
          if (item.length == 1) {
            company_industry.push(item[0]);
          } else if (item.length == 2) {
            company_industry.push(item[1]);
          }
        });
      }
      this.companylist = [];
      let params = {
        page: page,
        size: 10,
        keyword: this.keyword ? this.keyword : null,
        area: area,
        company_industry: company_industry,
        qualification: this.valueType,
        start_date: this.formState.rangetime1 ? this.formState.rangetime1[0] : null,
        end_date: this.formState.rangetime1 ? this.formState.rangetime1[1] : null,
        capital_nums_min: Number(this.formState.capital_nums_min),
        capital_nums_max: Number(this.formState.capital_nums_max),
        innovate: this.valueInnovation,
      };

      //将筛选条件渲染到页面上//搜索结果标签
      let filter = [];
      this.filter = [];
      for (var key in params) {
        filter = [
          ...params.area,
          ...params.company_industry,
          ...params.qualification,
          // params.innovate,
        ];
        if (key == "keyword") {
          params.keyword ? filter.push(params.keyword) : "";
        }
        if (key == "innovate") {
          params.innovate ? filter.push(params.innovate) : "";
        }
      }
      this.filter = filter;
      //请求接口
      enterpriseSelect({ ...params }).then((res) => {
        if (res.code == 0) {
          this.companylist = res.data.list;
          this.search_word = res.data.search_word;
          this.total = res.data.total;
        }
      });
    },
    //重置筛选
    clearvalue() {
      this.valueArea = [];
      this.valueInnovation = "";
      this.valueType = [];
      this.formState = {
        capital_nums_min: "",
        capital_nums_max: "",
        rangetime1: [],
      };
      this.industryvalue = [];
      this.filter = [];
      this.currentPage = 1;
      this.keyword = "";
      this.Filter();
    },
    //点击筛选
    Filter() {
      if (this.formState.capital_nums_min == "" && this.formState.capital_nums_max != "") {
        message.error("请输入完整的注册资本范围");
        return false;
      } else if (this.formState.capital_nums_min != "" && this.formState.capital_nums_max == "") {
        message.error("请输入完整的注册资本范围");
        return false;
      } else if (this.formState.capital_nums_min && this.formState.capital_nums_max) {
        if (Number(this.formState.capital_nums_min) > Number(this.formState.capital_nums_max)) {
          console.log(this.formState.capital_nums_min, this.formState.capital_nums_max);
          message.error("注册资本范围无效");
          return false;
        }
      }
      //修改省份的格式
      let area = [];
      if (this.valueArea.length != 0) {
        this.valueArea.forEach((item) => {
          if (item.length == 1) {
            area.push(item[0]);
          } else if (item.length == 2) {
            area.push(item[1]);
          }
        });
      }

      //修改国民经济行业的格式
      let company_industry = [];
      if (this.industryvalue.length != 0) {
        this.industryvalue.forEach((item) => {
          if (item.length == 1) {
            company_industry.push(item[0]);
          } else if (item.length == 2) {
            company_industry.push(item[1]);
          }
        });
      }

      //企业结果
      this.companylist = [];
      this.currentPage = 1;
      let params = {
        page: 1,
        size: 10,
        keyword: this.keyword ? this.keyword : null,
        area: area,
        company_industry: company_industry,
        qualification: this.valueType,
        start_date: this.formState.rangetime1 ? this.formState.rangetime1[0] : null,
        end_date: this.formState.rangetime1 ? this.formState.rangetime1[1] : null,
        capital_nums_min: Number(this.formState.capital_nums_min),
        capital_nums_max: Number(this.formState.capital_nums_max),
        innovate: this.valueInnovation ? this.valueInnovation : null,
      };
      //将筛选条件渲染到页面上//搜索结果标签
      let filter = [];
      this.filter = [];
      for (var key in params) {
        filter = [
          ...params.area,
          ...params.company_industry,
          ...params.qualification,
          // params.innovate,
        ];
        if (key == "keyword") {
          params.keyword ? filter.push(params.keyword) : "";
        }
        if (key == "innovate") {
          params.innovate ? filter.push(params.innovate) : "";
        }
      }
      this.filter = filter;
      //请求接口
      enterpriseSelect({ ...params }).then((res) => {
        if (res.code == 0) {
          this.companylist = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    //打开企业详情
    openCompany(id, company_name) {
      //保留历史搜索记录
      let historysearch = {
        keyword: this.keyword,
        valueArea: this.valueArea,
        industryvalue: this.industryvalue,
        valueType: this.valueType,
        rangetime1: this.formState.rangetime1,
        capital_nums_min: this.formState.capital_nums_min,
        capital_nums_max: this.formState.capital_nums_max,
        valueInnovation: this.valueInnovation,
      };
      sessionStorage.setItem("currentpageqy", this.currentPage);
      sessionStorage.setItem("searchqy", JSON.stringify(historysearch));
      this.$router.push({
        path: "/companyDetails",
        query: {
          id: id,
          name: company_name,
        },
      });
    },
    onsearch() {
      this.currentPage = 1;
      this.search_result_show = false;
      this.keyword ? (this.filter = [this.keyword]) : (this.filter = []);
      this.getCompanyList(this.keyword);
    },
    //联想搜索
    onInput() {
      enterpriseLenovo({ keyword: this.keyword }).then((res) => {
        if (res.code == 0) {
          this.search_result = [];
          this.search_result_show = true;
          this.company_cate = res.data.name_or_legal;
          this.search_result = res.data.list;
          if (this.search_result.length == 0) {
            this.search_result_show = false;
          }
        } else {
          this.search_result_show = false;
        }
      });
    },
    onFocus() {
      if (this.keyword) {
        enterpriseLenovo({ keyword: this.keyword }).then((res) => {
          if (res.code == 0) {
            this.search_result = [];
            this.search_result_show = true;
            this.company_cate = res.data.name_or_legal;
            this.search_result = res.data.list;
            if (this.search_result.length == 0) {
              this.search_result_show = false;
            }
          } else {
            this.search_result_show = false;
          }
        });
      }
    },
    onBlur() {
      this.timer = setTimeout(() => {
        this.search_result_show = false;
      }, 150);
    },
    //从联想结果打开公司页面
    lenovnoCompanyDetails(item) {
      this.$router.push({
        path: "/companyDetails",
        query: {
          id: item.mysql_id,
        },
      });
    },
  },
  created() {
    this.getindustryType();
    //山西区域json数据
    this.optionsArea = arealist;
    this.keyword = this.$route.query.keyword;
    this.keyword ? (this.filter = [this.keyword]) : (this.filter = []);
    if (!sessionStorage.getItem("searchqy")) {
      if (this.$route.query.valueInnovation) {
        this.valueInnovation = this.$route.query.valueInnovation;
        this.Filter();
      } else {
        this.getCompanyList(this.keyword);
      }
    }
    this.type = "企业";
  },
  watch: {
    type: function (newV, oldV) {
      // console.log(newV)
      if (newV == "专利") {
        this.$router.push({
          path: "/patent",
        });
      }
    },
  },
  beforeMount() {
    this.currentPage = sessionStorage.getItem("currentpageqy");
    if (sessionStorage.getItem("searchqy")) {
      let searchValue = JSON.parse(sessionStorage.getItem("searchqy"));
      this.valueArea = searchValue.valueArea;
      this.industryvalue = searchValue.industryvalue;
      this.valueType = searchValue.valueType;
      this.formState.rangetime1 = searchValue.rangetime1;
      this.formState.capital_nums_min = searchValue.capital_nums_min;
      this.formState.capital_nums_max = searchValue.capital_nums_max;
      this.valueInnovation = searchValue.valueInnovation;
      this.keyword = searchValue.keyword;
      this.onChange(this.currentPage);
    }
  },
};
</script>
<style lang="less" scoped>
.home {
  height: auto !important;
}
.header_con {
  position: absolute;
  top: 0;
  left: 35%;
  //  padding: 0 10px 30px;
  width: 700px;
  height: 50px;
  margin: 10px;
  // padding: 10px;
  border: 2px solid #4079ff;
  // border-radius: 8px;
  .type {
    width: 120px;
    height: 40px;
    // margin-top: 10px;
    // line-height: 50px;
    padding: 0px 0;
    // background-color: #fff;
    border-color: transparent;
  }
  :deep(.ant-input-group .ant-input) {
    // height: 54px;
    font-size: 18px;
  }
  :deep(.ant-input) {
    border-color: transparent;
    padding-top: 4px;
  }
  :deep(.ant-input-group-addon:first-child) {
    border-color: transparent;
    padding-top: 5px;
    background-color: transparent;
  }
  :deep(.ant-input-group-addon:last-child) {
    height: 48px;
    width: 120px;
    background-color: #4079ff !important;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    border: 0;
  }
  .search_result {
    position: absolute;
    width: calc(100% - 120px);
    // height: 300px;
    top: 54px;
    left: 0;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.05);
    .search_result_item {
      // height: 45px;
      padding: 15px 5px 15px 25px;
      font-family: "SF Pro Text";
      font-size: 16px;
    }
    .search_result_item:hover {
      background-color: #f5fbff;
    }
  }
}
.company {
  padding: 0 30px;
  width: calc(100%);
  min-height: 960px;
  background-color: #ebebeb;
  position: relative;
  .nav_bread {
    height: 50px;
    padding: 20px 3px;
    background-color: #ebebeb;
  }
  .company_con {
    background-color: #f3f3f6;
    width: 100%;
    min-height: 960px;
    // height: 95%;
    display: flex;
    .company_con_left {
      width: 20%;
      // height: 90%;
      padding: 25px 20px;
      background-color: #fff;
    }
    .company_con_right {
      background-color: #fff;
      width: 78%;
      // height: 90%;
      padding: 25px 20px;
      .result_count {
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #4079ff;
      }
      .filter {
        font-size: 15px;
        display: inline-block;
        span {
          font-size: 15px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
        }
      }
      .card {
        background: #f6f7fb;
        border-radius: 10px 10px 10px 10px;
        opacity: 0.8;
        padding: 24px;
        width: 48%;
        margin-bottom: 15px;
        position: relative;
        margin-right: 1%;

        :deep(.ant-tag-has-color) {
          padding: 5px;
          border-radius: 6px;
          margin: 10px 5px;
        }

        .card_title {
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          color: #333333;
          font-size: 20px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          .follow {
            width: 112px;
            height: 32px;
            background: #b6b4b4;
            background: #b5bdc5;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            text-align: center;
            line-height: 32px;
            cursor: pointer;

            .follow_img {
              width: 20px;
              margin-top: -5px;
            }
            .follow_str {
              padding-left: 8px;
              color: #fff;
              // color: #f2c25e;
            }
          }
        }
        .card_desc {
          :deep(.ant-descriptions-item-label) {
            color: #999999;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
          }
          :deep(.ant-descriptions-item-content) {
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: bold;
            color: #1c1c28;
          }
          :deep(.ant-descriptions-row > th) {
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}
.cate {
  right: 2%;
  position: absolute;
  top: 10%;
}
:deep(b) {
  color: rgba(191, 36, 40, 1);
}
</style>
