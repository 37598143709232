<template>
  <!-- <v-scale-screen width="1920" height="960"> -->
  <div class="screen">
    <header>中北大学专利成果转移转化仪表盘</header>
    <div class="center">
      <div class="center_left">
        <patentType></patentType>
      </div>
      <div class="center_map">
        <patentMap></patentMap>
      </div>
      <div class="center_right">
        <patentPerson></patentPerson>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_echart">
        <div class="bottom_title">
          <span>专利发展趋势图</span>
          <div class="bottom_title_btn">
            <span :class="[activeTrend == 'apply_num' ? 'active' : '']" @click="changetrend('apply_num')">申请量</span>
            <span :class="[activeTrend == 'publish_num' ? 'active' : '']" @click="changetrend('publish_num')"
              >公布量</span
            >
            <span :class="[activeTrend == 'authorize_num' ? 'active' : '']" @click="changetrend('authorize_num')"
              >授权量</span
            >
            <a-select
              ref="select"
              v-model:value="trend_year"
              style="width: 120px"
              :options="yearOptions"
              @change="handleChange"
            >
            </a-select>
          </div>
        </div>
        <div class="echarts_container">
          <EchartsCon :id="'patent_trend'" :option="option5" v-if="option5"></EchartsCon>
        </div>
      </div>
      <div class="bottom_echart">
        <div class="bottom_title">
          <span>专利产业布局分布（山西十大产业）</span>
        </div>
        <div class="rank_con">
          <div class="rank_con_left">
            <div class="rank_progress" v-for="item in rankArr.slice(0, 5)">
              <span class="index1 index" v-if="item.index == '1'">{{ item.index }}</span>
              <span class="index2 index" v-else-if="item.index == '2'">{{ item.index }}</span>
              <span class="index3 index" v-else-if="item.index == '3'">{{ item.index }}</span>
              <span class="index4 index" v-else>{{ item.index }}</span>
              <span class="name1 name" :title="item.name" v-if="item.index == '1'">{{ item.name }}</span>
              <span class="name2 name" :title="item.name" v-else-if="item.index == '2'">{{ item.name }}</span>
              <span class="name3 name" :title="item.name" v-else-if="item.index == '3'">{{ item.name }}</span>
              <span class="name4 name" :title="item.name" v-else>{{ item.name }}</span>

              <a-progress
                v-if="item.index == '1'"
                class="progress"
                :percent="item.percent"
                :stroke-color="gradientColor1"
                :trail-color="'rgba(19, 40, 71, 1)'"
                :show-info="false"
                :stroke-linecap="'round'"
              >
              </a-progress>
              <a-progress
                v-else-if="item.index == '2'"
                class="progress"
                :percent="item.percent"
                :stroke-color="gradientColor2"
                :trail-color="'rgba(19, 40, 71, 1)'"
                :show-info="false"
                :stroke-linecap="'round'"
              >
              </a-progress>
              <a-progress
                v-else-if="item.index == '3'"
                class="progress"
                :percent="item.percent"
                :stroke-color="gradientColor3"
                :trail-color="'rgba(19, 40, 71, 1)'"
                :show-info="false"
                :stroke-linecap="'round'"
              >
              </a-progress>
              <a-progress
                v-else
                class="progress"
                :percent="item.percent"
                :stroke-color="gradientColor4"
                :trail-color="'rgba(19, 40, 71, 1)'"
                :show-info="false"
                :stroke-linecap="'round'"
              >
              </a-progress>
              <span class="num1 num" v-if="item.index == '1'">{{ item.value }}个</span>
              <span class="num2 num" v-else-if="item.index == '2'">{{ item.value }}个</span>
              <span class="num3 num" v-else-if="item.index == '3'">{{ item.value }}个</span>
              <span class="num4 num" v-else>{{ item.value }}个</span>
            </div>
          </div>
          <div class="rank_con_right">
            <div class="rank_progress" v-for="item in rankArr.slice(5, 10)">
              <span class="index4 index">{{ item.index }}</span>
              <span class="name4 name" :title="item.name">{{ item.name }}</span>
              <a-progress
                class="progress"
                :percent="item.percent"
                :stroke-color="gradientColor4"
                :trail-color="'rgba(19, 40, 71, 1)'"
                :show-info="false"
                :stroke-linecap="'round'"
              >
              </a-progress>
              <span class="num4 num">{{ item.value }}个</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </v-scale-screen> -->
</template>
<script>
import { patentDevelop, patentIndustryTopN } from "@/utils/screen.js";
import EchartsCon from "@/components/EchartsCon.vue";
import patentType from "./components/patentType.vue";
import patentMap from "./components/patentMap.vue";
import patentPerson from "./components/patentPerson.vue";
import VScaleScreen from "v-scale-screen";
export default {
  name: "screen",
  data() {
    return {
      option5: null,
      activeTrend: "apply_num",
      trend_year: 8,
      rankArr: [],
      yearOptions: [
        {
          value: 3,
          label: "近三年",
        },
        {
          value: 4,
          label: "近四年",
        },
        {
          value: 5,
          label: "近五年",
        },
        {
          value: 6,
          label: "近六年",
        },
        {
          value: 7,
          label: "近七年",
        },
        {
          value: 8,
          label: "近八年",
        },
        {
          value: 9,
          label: "近九年",
        },
        {
          value: 10,
          label: "近十年",
        },
      ],
    };
  },
  computed: {
    gradientColor1() {
      const startColor = "rgba(37, 54, 74, 1)"; // 渐变起始颜色
      const endColor = "rgba(255, 0, 0, 1)"; // 渐变结束颜色

      return `linear-gradient(to right, ${startColor}, ${endColor})`;
    },
    gradientColor2() {
      const startColor = "rgba(37, 54, 74, 1)"; // 渐变起始颜色
      const endColor = "rgba(255, 108, 0, 1)"; // 渐变结束颜色

      return `linear-gradient(to right, ${startColor}, ${endColor})`;
    },
    gradientColor3() {
      const startColor = "rgba(37, 54, 74, 1)"; // 渐变起始颜色
      const endColor = "rgba(221, 205, 66, 1)"; // 渐变结束颜色

      return `linear-gradient(to right, ${startColor}, ${endColor})`;
    },
    gradientColor4() {
      const startColor = "rgba(37, 54, 74, 1)"; // 渐变起始颜色
      const endColor = "rgba(0, 118, 214, 1)"; // 渐变结束颜色

      return `linear-gradient(to right, ${startColor}, ${endColor})`;
    },
  },
  components: {
    EchartsCon,
    patentType,
    patentMap,
    patentPerson,
    VScaleScreen,
  },
  methods: {
    init() {
      //获取专利发展趋势图
      this.getTrend();
      //获取产业排名
      this.getTopN();
    },
    //修改时间
    handleChange() {
      this.getTrend();
    },
    //获取产业排名
    getTopN() {
      patentIndustryTopN().then((res) => {
        this.rankArr = res.data;
        this.rankArr.forEach((item, index) => {
          item.index = index + 1;
          item.percent = item.percent.replace("%", "");
        });
      });
    },
    getTrend() {
      let data = {
        xData: [],
        yData1: [],
        yData2: [],
        yData3: [],
        yData4: [],
      };

      patentDevelop({ type: this.activeTrend, year: this.trend_year }).then((res) => {
        if (res.code == 0) {
          res.data.patent_all.forEach((item) => {
            data.xData.push(item.name);
            data.yData1.push(item.value);
          });
          res.data.invention_patent.forEach((item) => {
            data.yData2.push(item.value);
          });
          res.data.utility_model.forEach((item) => {
            data.yData3.push(item.value);
          });
          res.data.appearance_design.forEach((item) => {
            data.yData4.push(item.value);
          });
          this.getTrendData(data);
        }
      });
    },
    getTrendData(data) {
      this.option5 = {
        color: ["#E94C5B", "#1EFF2E", "#1EE2FF", "#FFA81E"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          borderColor: "rgba(147, 235, 248, .8)",
          textStyle: {
            color: "#FFF",
          },
        },
        grid: {
          left: "2%",
          right: "5%",
          bottom: "5%",
          top: "30px",
          containLabel: true,
        },

        legend: {
          show: true,
          icon: "circle",
          orient: "horizontal",
          left: "30",
          itemWidth: 12,
          itemHeight: 12,
          formatter: ["{a|{name}}"].join("\n"),
          textStyle: {
            fontSize: 12,
            color: "#6A93B9",
            height: 8,
            rich: {
              a: {
                verticalAlign: "bottom",
              },
            },
          },
        },
        xAxis: {
          type: "category",
          data: data.xData,
          axisLine: {
            lineStyle: {
              color: "#B5C5D4",
              fontSize: 12,
            },
          },
          axisLabel: {
            // interval:0,
            color: "#B5C5D4",
            fontSize: 12,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          minInterval: 1,
          nameTextStyle: {
            fontSize: 12,
            color: "#BDD8FB",
            align: "center",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.15)",
              type: "dashed", // dotted 虚线
            },
          },
          splitArea: { show: false },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Bebas",
            color: "#BDD8FB",
          },
        },
        series: [
          {
            type: "line",
            smooth: true, // 是否曲线
            symbol: "none", // 不显示连接点
            name: "专利总量", // 图例对应类别
            data: data.yData1, // 纵坐标数据
            areaStyle: {
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0.1,
                    color: "rgba(233, 76, 91, 0.5)",
                  },
                  {
                    offset: 1,
                    color: "#1057E500", // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            type: "line",
            smooth: true,
            symbol: "none", // 不显示连接点
            name: "发明专利",
            data: data.yData2,
            areaStyle: {
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0.1,
                    color: "rgba(30, 255, 46, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#86DCF300", // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            type: "line",
            smooth: true,
            symbol: "none", // 不显示连接点
            name: "实用新型",
            data: data.yData3,
            areaStyle: {
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0.1,
                    // 0% 处的颜色
                    color: "rgba(30, 226, 255, 0.5)",
                  },
                  {
                    offset: 1,
                    color: "#86DCF300", // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            type: "line",
            smooth: true,
            symbol: "none", // 不显示连接点
            name: "外观设计",
            data: data.yData4,
            areaStyle: {
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0.1,
                    color: "rgba(255, 168, 30, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#86DCF300", // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ],
      };
    },
    changetrend(tab) {
      this.activeTrend = tab;
      this.getTrend();
    },
  },

  mounted() {},
  created() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.screen {
  background-image: url("../../static/screen/bg-all.png");
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  overflow: auto;
  // height: 885px;
  header {
    background-image: url("../../static/screen/bg-top-logo.png");
    height: 97px;
    background-size: 100% 100%;
    font-family: "jiangcheng";
    font-weight: 400;
    color: #eff8fc;
    text-align: center;
    font-size: 34px;
    line-height: 80px;
    opacity: 0.89;
    text-shadow: 0px 4px 1px rgba(19, 80, 143, 0.66);
  }
  .center {
    height: 60%;
    display: flex;
    .center_left {
      width: 30%;
    }
    .center_map {
      width: 40%;
    }
    .center_right {
      width: 30%;
    }
  }
  .bottom {
    //  height: 120px;
    display: flex;
    height: 28%;
    justify-content: space-evenly;
    .bottom_echart {
      width: 48%;
      .bottom_title {
        background: url("../../static/screen/title-long.png") no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        height: 39px;
        width: 100%;
        font-family: "jiangcheng";
        font-weight: normal;
        font-style: italic;
        color: #f6f9fe;
        text-shadow: 0px 2px 8px rgba(5, 28, 55, 0.42);
        padding-left: 30px;
        padding-top: 6px;
        .bottom_title_btn {
          float: right;
          font-size: 14px;
          font-family: "jiangcheng";
          font-weight: normal;
          color: #bbcdef;
          opacity: 0.8;
          span {
            padding: 5px 15px;
            cursor: pointer;
          }
          .active {
            color: #fff;
            text-shadow: 0px 2px 8px #f6f9fe;
          }
          :deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
            background-color: #2d58b8;
            color: #99b1dd;
          }
          :deep(.ant-select-dropdown) {
            background-color: #1052df !important;
          }
        }
      }
      .rank_con {
        height: 160px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        .index {
          font-size: 12px;
          font-family: "jiangcheng";
          font-weight: 400;
          color: #f8fbff;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
        }
        .index1 {
          background: linear-gradient(to right, red, transparent);
        }
        .index2 {
          background: linear-gradient(to right, rgba(255, 108, 0, 1), transparent);
        }
        .index3 {
          background: linear-gradient(to right, rgba(221, 205, 66, 1), transparent);
        }
        .index4 {
          background: linear-gradient(to right, rgba(44, 61, 81, 1), transparent);
        }
        .name {
          font-size: 12px;
          font-family: "jiangcheng";
          font-weight: 400;
          color: #f8fbff;
          padding: 0 5px;
          height: 30px;
          text-align: left;
          line-height: 30px;
          width: 25%;
          overflow: hidden; /*超出部分隐藏*/
          text-overflow: ellipsis; /*超出部分省略号表示*/
          white-space: nowrap; /*强制单行显示*/
          word-break: keep-all;
        }
        .name1 {
          text-shadow: 0px 1px 1px rgba(255, 114, 114, 0.5);
        }
        .name2 {
          text-shadow: 0px 1px 1px rgba(255, 177, 120, 0.5);
        }
        .name3 {
          text-shadow: 0px 1px 1px rgba(246, 233, 129, 0.5);
        }
        .name4 {
          text-shadow: 0px 1px 1px rgba(132, 191, 251, 0.5);
        }
        .rank_progress {
          width: 100%;
          display: flex;
          height: 15%;
          margin: 10px 0;

          .progress-marker {
            position: absolute;
            top: -25px;
            left: 0;
            transform: translateX(-50%);
            background-color: #1890ff;
            color: #fff;
            padding: 4px 8px;
            border-radius: 4px;
          }
          .num {
            font-size: 14px;
            font-family: "jiangcheng";
            font-weight: normal;
            font-style: italic;
            // color: #ff4b4b
            width: 20%;
            padding: 0 5px;
          }
          .num1 {
            color: #ff4b4b;
          }
          .num2 {
            color: rgba(255, 108, 0, 1);
          }
          .num3 {
            color: rgba(235, 217, 74, 1);
          }
          .num4 {
            color: rgba(0, 159, 255, 1);
          }
          .progress {
            width: 280px;
          }
        }
      }
    }
  }
  // background-size:100% 100%;
}
.echarts_container {
  // height: 120px;
  margin-top: 20px;
  height: 70%;
}
:deep(.ant-select .ant-select-selector) {
  background-color: #2d58b8;
  color: #f6f9fe !important;
  font-family: Arial, sans-serif;
}
:deep(.ant-select-dropdown) {
  background-color: #1052df !important;
}
:deep(.ant-select) {
  width: 90px !important;
  float: right;
}
:deep(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
  padding-right: 10px;
}
</style>
