import { get, post, put, Delete } from './request'
/**
 * 示例 
 get请求
 export const changePassword = p => get('/api/home/changePassword',{});
oost请求
export const login = p => post('/api/common/login', p);
*/
export const login = p => post('/api/common/login', p);
export const changePassword = p => post('/api/common/changePassword', p);
export const sendSms = p => post('/api/common/sendSms', p);
export const users = p => get('/api/backup/users?page=' + p.page + '&size=' + p.size);
export const changeStatus = p => put('/api/backup/users/' + p.id, { status: p.status });
export const addusers = p => post('/api/backup/users', p);
export const deleusers = p => Delete('/api/backup/users', p);
//下载模板
export const downloads  = p => get('/api/backup/downloads/批量添加账号.xlsx');
//上传文件
export const batchAdd = p => post('/api/backup/users/batchAdd', p);









