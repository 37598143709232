<template>
  <div class="home" :style="[current == '专利' || current == '企业' ? 'height:auto' : 'height:100%']">
    <!-- 标题栏 -->
    <HeaderCon></HeaderCon>

    <div class="home_main">
      <div class="sider">
        <div
          :class="[current == item.name ? 'sider_item active' : 'sider_item ']"
          v-for="item in siderArr"
          :key="item.name"
          @click="changepage(item.name)"
        >
          <img :src="current == item.name ? item.img_select : item.img" alt="" class="sider_item_img" />
          <div :class="[current == item.name ? 'sider_item_name active ' : 'sider_item_name']">
            {{ item.name }}
          </div>
        </div>
      </div>

      <router-view />
    </div>
  </div>
</template>
<script>
import HeaderCon from "../../components/HeaderCon";
import SiderBar from "../../components/SiderBar";
export default {
  name: "home",
  data() {
    return {
      current: "首页",
      searchValue: "",
      type: "企业",
      siderArr: [
        {
          name: "首页",
          img: require("@/static/home/index/icon-nav01.png"),
          img_select: require("@/static/home/index/icon-nav01-select.png"),
        },
        {
          name: "专利",
          img: require("@/static/home/index/icon-nav02.png"),
          img_select: require("@/static/home/index/icon-nav02-select.png"),
        },
        {
          name: "企业",
          img: require("@/static/home/index/icon-nav03.png"),
          img_select: require("@/static/home/index/icon-nav03-select.png"),
        },
        {
          name: "历史记录",
          img: require("@/static/home/index/icon-nav04.png"),
          img_select: require("@/static/home/index/icon-nav04-select.png"),
        },
        {
          name: "我的关注",
          img: require("@/static/home/index/icon-nav05.png"),
          img_select: require("@/static/home/index/icon-nav05-select.png"),
        },
        {
          name: "仪表盘",
          img: require("@/static/home/index/icon-nav06.png"),
          img_select: require("@/static/home/index/icon-nav06--select.png"),
        },
      ],
      followList: [],
      innovateList: [],
    };
  },
  components: {
    HeaderCon,
    SiderBar,
  },
  methods: {
    changepage(name) {
      switch (name) {
        case "企业":
          this.$router.push({
            path: "/company",
          });

          break;
        case "首页":
          this.$router.push({
            path: "/home",
          });

          break;
        case "专利":
          this.$router.push({
            path: "/patent",
          });

          break;
        case "历史记录":
          this.$router.push({
            path: "/history",
          });
          break;
        case "我的关注":
          this.$router.push({
            path: "/follow",
          });
          break;
        case "仪表盘":
          let routeUrl = this.$router.resolve({
            path: "/screen",
          });
          window.open(routeUrl.href, "_blank");
          break;
      }
    },
  },
  watch: {
    $route() {
      let path = this.$route.path;
      switch (path) {
        case "/company":
          localStorage.setItem("current", "企业");
          this.current = localStorage.getItem("current");
          break;
        case "/companyDetails":
          localStorage.setItem("current", "企业");
          this.current = localStorage.getItem("current");
          break;
        case "/home":
          // this.current = "首页";
          localStorage.setItem("current", "首页");
          this.current = localStorage.getItem("current");
          break;
        case "/patent":
          // this.current = "专利";
          localStorage.setItem("current", "专利");
          this.current = localStorage.getItem("current");
          break;
        case "/patentDetails":
          // this.current = "专利";
          localStorage.setItem("current", "专利");
          this.current = localStorage.getItem("current");
          break;
        case "/history":
          this.current = "历史记录";
          localStorage.setItem("current", "历史记录");
          this.current = localStorage.getItem("current");
          break;
        case "/follow":
          localStorage.setItem("current", "我的关注");
          this.current = localStorage.getItem("current");
          break;
      }
    },
  },
  mounted() {
    this.current = localStorage.getItem("current");
  },
  created() {
    this.username = this.$store.state.username;
  },
};
</script>
<style lang="less" scoped>
.home {
  height: calc(100% - 65px);
  width: calc(100%);
  position: relative;

  .home_main {
    height: calc(100% - 65px);
    min-height: calc(100vh - 65px);
    width: 100%;
    background-color: #ebebeb;
    display: flex;
    .home_con {
      background-color: #ebebeb;
      padding-left: 30px;
      width: calc(100% - 80px);
      flex: 1;
      .nav_bread {
        height: 50px;
        padding: 20px 3px;
        background-color: #ebebeb;
      }
    }
  }
}
.sider {
  // height: calc(100% - 70px);
  background-color: #002d70;
  width: 80px;
  .sider_item {
    height: 100px;
    text-align: center;
    padding: 20px 10px 20px 0;
    .sider_item_img {
      width: 27px;
    }
    .sider_item_name {
      margin-top: 10px;
      color: #848bad;
      font-size: 14px;
      text-align: center;
    }
    .active {
      color: #fff;
    }
  }
  .active {
    background-color: #124795;
    color: #fff;
  }
}
</style>
