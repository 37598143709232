<template>
  <div class="loading">
    <div class="loading-inner"></div>
    <div class="loading-inner1">正在导出中~</div>
  </div>
</template>
<script>
export default{
  name:'load'
}
</script>
<style>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.loading-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  border: 4px solid #1e86fc;
  border-top-color: #c6cdd2;
  animation: loading 1s ease-in-out infinite;
}
.loading-inner1 {
  position: absolute;
  top: 55%;
  left: 48%;
  color: #fff;
  font-size: 16px;
 
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>