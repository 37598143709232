<template>
  <div class="login">
    <header>
      <div class="header_left">
        <img src="../../static/login/logo-tylgd.png" alt="" />
        <span>中北大学专利成果转移转化智能推荐系统</span>
      </div>
      <div class="header_right">
        <span class="email">邮 箱：zysfsyp_1001@163.com</span>
        <span class="tel">热 线： 151-0110-6969</span>
      </div>
    </header>
    <div class="con">
      <div class="con_left">
        <div class="title1">欢迎登录</div>
        <div class="title2">中北大学专利成果转移转化智能推荐系统</div>
        <div class="con_left_img">
          <img src="../../static/login/img-login.png" alt="" />
        </div>
      </div>
      <div class="con_right">
        <div class="login_title">
          <span>忘记密码</span>
          <span style="float: right" class="admin_login">
            <img src="../../static/login/icon-glydl.png" alt="" />&nbsp;&nbsp;
            <router-link to="/login">账户登录</router-link>
          </span>
        </div>
        <a-form
          v-if="!resetShow"
          :model="formState"
          name="normal_login"
          class="login-form"
          ref="form"
          :rules="formRules"
        >
          <a-form-item name="phone">
            <a-input v-model:value="formState.phone" placeholder="请输入手机号">
              <template #prefix>
                <img src="../../static/login/icon-user.png" alt="" />
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="code">
            <!-- :rules="[{ required: true, message: '请输入验证码！' }]" -->
            <a-input v-model:value="formState.code" placeholder="请输入验证码">
              <template #prefix>
                <img src="../../static/login/icon-yzm.png" alt="" />
              </template>
              <template #suffix>
                <span v-show="!isSend" class="code" @click="sendCode">获取验证码</span>
                <span v-show="isSend" class="code">
                  {{ RemainingTime }}
                  秒后重新获取
                </span>
              </template>
            </a-input>
          </a-form-item>
          <a-form-item name="password">
            <!--  :rules="[{ required: true, message: '请输入密码！' }]" -->
            <a-input-password v-model:value="formState.password" placeholder="请输入新密码">
              <template #prefix>
                <img src="../../static/login/icon-password.png" alt="" />
              </template>
            </a-input-password>
          </a-form-item>
          <a-form-item name="repassword">
            <a-input-password v-model:value="formState.repassword" placeholder="请再次输入新密码">
              <template #prefix>
                <img src="../../static/login/icon-password.png" alt="" />
              </template>
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" class="login-form-button" @click="reset"> 重置密码 </a-button>
          </a-form-item>
        </a-form>
        <div v-else class="success_reset">
          <img src="../../static/login/img-success.png" alt="" />
          <br /><br />
          <p>您已经成功设置密码，请使用新密码登录</p>
          <br /><br />
          <a-button type="primary" html-type="submit" class="login-form-button" @click="tologin"> 马上登录 </a-button>
        </div>
      </div>
    </div>
    <footer>
      <!-- <a style="color: #818a9f" href="http://zhongyanrenzhi.com/#/product2" target="_blank"
        >产品介绍&nbsp;&nbsp;&nbsp;&nbsp;</a
      >|
      <a style="color: #818a9f" target="_blank" href="http://zhongyanrenzhi.com/#/into/1"
        >&nbsp;&nbsp;&nbsp;&nbsp;关于中研认知</a
      > -->
      <span>主办：中北大学技术转移有限公司</span>

      <br />
      <span>技术支持：北京中研硕福科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;</span>丨<span
        >&nbsp;&nbsp;&nbsp;&nbsp;京ICP备19055798号-3
      </span>
    </footer>
  </div>
</template>
<script>
import { AES_Encrypt, AES_Decrypt } from "@/utils/aes_util.js";
import { message } from "ant-design-vue";
import { changePassword, sendSms } from "@/utils/loginAPI";
export default {
  name: "forget",
  data() {
    return {
      isSend: false,
      resetShow: false,
      timer: null,
      RemainingTime: 0,
      formState: {
        phone: "",
        code: "",
        password: "",
        repassword: "",
      },
      formRules: {
        phone: [
          { required: true, message: "请输入手机号！" },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机号格式不正确！",
            validateTrigger: "onBlur",
          },
        ],
        repassword: [
          { required: true, message: "请输入密码！" },
          {
            validator: this.validateConfirmPassword,
            message: "两次密码不匹配",
          },
        ],
        code: [{ required: true, message: "请输入验证码！" }],
        password: [{ required: true, message: "请输入密码！" }],
      },
    };
  },
  methods: {
    //验证两次密码
    validateConfirmPassword(rule, value) {
      return new Promise((resolve, reject) => {
        if (value && value !== this.formState.password) {
          reject(new Error());
        } else {
          resolve();
        }
      });
    },
    //发送验证码
    sendCode() {
      if (this.formState.phone) {
        sendSms({ phone: this.formState.phone }).then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.isSend = true;
            const TIME_COUNT = 60;
            message.success(res.msg);
            //获取验证码倒计时
            if (!this.timer) {
              this.RemainingTime = TIME_COUNT;
              this.isSend = true;
              this.timer = setInterval(() => {
                if (this.RemainingTime > 0 && this.RemainingTime <= TIME_COUNT) {
                  this.RemainingTime--;
                } else {
                  this.isSend = false;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            message.error(res.msg);
          }
        });
      } else {
        message.error("请先输入手机号");
      }
    },
    //reset
    reset() {
      this.$refs.form
        .validate()
        .then((values) => {
          let param = AES_Encrypt(
            JSON.stringify({
              phone: this.formState.phone,
              code: this.formState.code,
              password: this.formState.password,
              re_password: this.formState.repassword,
            })
          );
          changePassword({ param }).then((res) => {
            // console.log(res)
            if (res.code == 0) {
              this.resetShow = true;
            } else {
              message.error(res.msg);
            }
          });
        })
        .catch((err) => {
          // console.log(err);
          return false;
          // 处理验证失败的情况
        });
    },
    tologin() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-input-affix-wrapper {
  height: 50px;
}
/deep/.ant-form {
  margin-top: 30px;
  clear: both;
}
/deep/.login-form-button {
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 18px;
}
/deep/.ant-form-item-control-input {
  height: 60px;
}
.login {
  background-color: #fafbfe;
  height: 100%;
  background-image: url("../../static/login/img-bg.png");
  header {
    height: 65px;
    background-color: #fff;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    .header_left {
      padding: 10px 29px;
      span {
        color: #002d70;
        font-family: "jiangcheng";
        padding: 0 15px;
      }
    }
    .header_right {
      padding: 0 300px 30px;
      .email {
        padding: 0 15px;
        border-left: #5271e9 8px solid;
        color: #002d70;
        font-family: "jiangcheng";
        line-height: 65px;
      }
      .tel {
        padding: 0 15px;
        color: #002d70;
        font-family: "jiangcheng";
        line-height: 65px;
      }
    }
  }
  .con {
    height: calc(100vh - 160px);
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin: auto;
    width: 1560px;
    .con_left {
      // width: 40%;
      // margin-right: -20%;
      .title1 {
        color: #4079ff;
        font-family: "jiangcheng";
        font-size: 34px;
        margin-bottom: 10px;
      }
      .title2 {
        color: #1d2129;
        font-family: "jiangcheng";
        font-size: 32px;
        margin-bottom: 70px;
      }
      .con_left_img {
        img {
          width: 660px;
        }
      }
    }
    .con_right {
      width: 620px;
      height: 600px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
      padding: 50px 60px;
      .login_title {
        color: #4079ff;
        font-family: Microsoft YaHei UI-Bold;
        font-weight: bold;
        line-height: 60px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        span:nth-child(1) {
          font-size: 20px;
          padding-bottom: 20px;
          border-bottom: 3px solid rgba(82, 113, 233, 1);
        }
      }
      .code {
        color: #3e7bfa;
        cursor: pointer;
      }
    }
    .admin_login {
      img {
        width: 20px;
        vertical-align: middle;
      }
    }
  }
  footer {
    text-align: center;
    color: #818a9f;
  }
  .success_reset {
    text-align: center;
    height: 400px;
    padding: 30px 0;
    img {
      width: 169px;
    }
    p {
      font-size: 14px;
      font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
