const CryptoJS = require('./aes.js'); //引用AES源码js
const key = CryptoJS.enc.Utf8.parse("taiyuaniopasdfgh"); //十六位十六进制数作为秘钥
const iv = CryptoJS.enc.Utf8.parse('taiyuaniopasdfgh');//十六位十六进制数作为秘钥偏移量
//      /**
//       * aes 解密方法
//       */
//      function AesDecrypt(word) {
//        let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
//        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
//        let decrypt = CryptoJS.AES.decrypt(srcs, key, {
//          iv: iv,
//          mode: CryptoJS.mode.CBC,
//          padding: CryptoJS.pad.Pkcs7
//        });
//        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
//        return decryptedStr.toString();
//      }
//      /**
//       * aes 加密方法
//       */
//      function AesEncrypt(word) {
//        let srcs = CryptoJS.enc.Utf8.parse(word);
//        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
//          iv: iv,
//          mode: CryptoJS.mode.CBC,
//          padding: CryptoJS.pad.Pkcs7
//        });
//        return encrypted.ciphertext.toString().toUpperCase();
//      }
//      
//      /**
//       * base64 加密方法
//       */
//      function Base64Encode(val) {
//        let str = CryptoJS.enc.Utf8.parse(val);
//        let base64 = CryptoJS.enc.Base64.stringify(str);
//        return base64;
//      }
//      
//      /**
//       * base64 解密方法
//       */
//      function Base64Decode(val) {
//        let words = CryptoJS.enc.Base64.parse(val);
//        return words.toString(CryptoJS.enc.Utf8);
//      }

function AES_Encrypt(word) {
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  var hexStr = encrypted.ciphertext.toString().toUpperCase();
  // console.log('hexStr->' + hexStr);
  var oldHexStr = CryptoJS.enc.Hex.parse(hexStr);
  // 将密文转为Base64的字符串
  var base64Str = CryptoJS.enc.Base64.stringify(oldHexStr);
  // console.log('base64Str->' + base64Str);
  //这里根据需求返回 base64Str 或 hexStr(解密时有小小差别)
  return base64Str;
}
function AES_Decrypt(word) {
  // console.log('word->' + word);
  var srcs = word;
  var decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  var value = decryptedStr.toString();
  // console.log('value->' + value);
  return value;
}
//暴露接口
module.exports = {
  // AesEncrypt,
  // AesDecrypt,
  // Base64Encode,
  // Base64Decode,
  AES_Encrypt,
  AES_Decrypt
}
