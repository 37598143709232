<template>
  <div :id="id" :style="style"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "EchartsCon",
  data() {
    return {};
  },
  props: {
    id: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    option: {
      type: Object,
      default: {},
    },
  },
  computed: {
    style() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },

  watch: {
    option: {
      handler(newVal) {
        if (this.chart) {
          this.chart.setOption(newVal);
        } else {
          this.init();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (document.getElementById(this.id) == null) {
        return;
      }

      echarts.dispose(document.getElementById(this.id));
      let chart = echarts.init(document.getElementById(this.id));

      chart.setOption(this.option);
      // let that = this;
      window.addEventListener("resize", chart.resize);
    },
  },
};
</script>
<style lang="less" scoped></style>
